//Validation password and other forms

function LoginValidation(values){
    let error = {}

    if(values.username === ""){
        error.uesername = "El nombre de usuarioe stá vacío"
    }
    else{
        error.username= ""
    }

    if(values.password === ""){
        error.password = "La contraseña no puede estar vacía"
    }
    else{
        error.password= ""
    }
    return error
}

function LoginValidation2(values){
    let error = {}
    const email_pattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/


    if(values.username === ""){
        error.username = "El email no puede estar vacío"
    }
    else if(!email_pattern.test(values.username)){
        error.username = "El email es incorrecto"
    }
    else{
        error.username = ""
    }

    if(values.password === ""){
        error.password = "La contraseña está vacía"
    }
    else{
        error.password= ""
    }
    return error
}

function RegisterValidation(values){
    let error = {}
    const email_pattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/

    if(values.email === ""){
        error.email = "El email está vacío"
    }
    else if(!email_pattern.test(values.email)){
        error.email = "El email es incorrecto"
    }
    else{
        error.email= ""
    }

    if(values.username === ""){
        error.username = "El nombre de usuario está vacío"
    }
    else{
        error.username= ""
    }

    if(values.first_name === ""){
        error.first_name = "El nombre está vacío"
    }
    else{
        error.first_name= ""
    }

    if(values.last_name === ""){
        error.last_name = "El apellido está vacío"
    }
    else{
        error.last_name= ""
    }

    if(values.password === ""){
        error.password = "La contraseña está vacía"
    }
    if(values.password.length < 8){
        error.password = "La contraseña debe tener por lo menos 8 dígitos"
    }
    else if (!/[a-zA-Z]/.test(values.password)) {
        error.password = "La contraseña debe contener al menos una letra";
    }
    // Verificar al menos una letra minúscula
    else if (!/[a-z]/.test(values.password)) {
        error.password = 'La contraseña debe contener una minúscula';
    }

    // Verificar al menos una letra mayúscula
    else if (!/[A-Z]/.test(values.password)) {
        error.password = 'La contraseña debe contener una mayúscula';
    }

    else if(!/\d/.test(values.password)) {
        error.password = 'La contraseña debe contener un número.';
      }
    else{
        error.password= ""
    }
    
    return error
}

function RecoverValidation(values){
    let error = {}
    const email_pattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/

    if(values.email === ""){
        error.email = "El email no puede estar vacío"
    }
    else if(!email_pattern.test(values.email)){
        error.email = "El email es incorrecto"
    }
    else{
        error.email= ""
    }
    return error
}

function ChangePass(values){
    let error = {}

    if(values.current_pass === ""){
        error.current_pass = "La contraseña está vacía"
    }
    if(values.current_pass.length < 8){
        error.current_pass = "La contraseña debe tener por lo menos 8 dígitos"
    }
    else if (!/[a-zA-Z]/.test(values.current_pass)) {
        error.current_pass = "La contraseña debe contener al menos una letra";
    }
    // Verificar al menos una letra minúscula
    else if (!/[a-z]/.test(values.current_pass)) {
        error.current_pass = 'La contraseña debe contener una minúscula';
    }

    // Verificar al menos una letra mayúscula
    else if (!/[A-Z]/.test(values.current_pass)) {
        error.current_pass= 'La contraseña debe contener una mayúscula';
    }

    else if(!/\d/.test(values.current_pass)) {
        error.current_pass = 'La contraseña debe contener un número.';
      }
    else{
        error.current_pass= ""
    }

    if(values.new_pass === ""){
        error.new_pass = "La contraseña está vacía"
    }
    if(values.new_pass.length < 8){
        error.new_pass = "La contraseña debe tener por lo menos 8 dígitos"
    }
    else if (!/[a-zA-Z]/.test(values.new_pass)) {
        error.new_pass = "La contraseña debe contener al menos una letra";
    }
    // Verificar al menos una letra minúscula
    else if (!/[a-z]/.test(values.new_pass)) {
        error.new_pass = 'La contraseña debe contener una minúscula';
    }

    // Verificar al menos una letra mayúscula
    else if (!/[A-Z]/.test(values.new_pass)) {
        error.new_pass = 'La contraseña debe contener una mayúscula';
    }

    else if(!/\d/.test(values.new_pass)) {
        error.new_pass = 'La contraseña debe contener un número.';
      }
    else{
        error.new_pass= ""
    }

    return error
}

function ChangeEmail(values){
    let error = {}
    const password_pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[.]?).{8,}$/
    const email_pattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/

    if(values.current_pass === ""){
        error.current_pass = "La contraseña no puede estar vacía"
    }
    else if(!password_pattern.test(values.current_pass)){
        error.current_pass = "La contraseña debe incluir por lo menos: 8 dígitos, 1 letra, 1 número y 1 mayúscula"
    }
    else{
        error.current_pass= ""
    }

    if(values.new_email === ""){
        error.new_email = "El email no puede estar vacío"
    }
    else if(!email_pattern.test(values.new_email)){
        error.new_email = "El email es incorrecto"
    }
    else{
        error.new_email= ""
    }
    return error
}

function NewPass(values){
    let error = {}

    if(values.password === ""){
        error.password = "La contraseña está vacía"
    }
    if(values.password.length < 8){
        error.password = "La contraseña debe tener por lo menos 8 dígitos"
    }
    else if (!/[a-zA-Z]/.test(values.password)) {
        error.password = "La contraseña debe contener al menos una letra";
    }
    // Verificar al menos una letra minúscula
    else if (!/[a-z]/.test(values.password)) {
        error.password = 'La contraseña debe contener una minúscula';
    }

    // Verificar al menos una letra mayúscula
    else if (!/[A-Z]/.test(values.password)) {
        error.password = 'La contraseña debe contener una mayúscula';
    }

    else if(!/\d/.test(values.password)) {
        error.password = 'La contraseña debe contener un número.';
      }
    else{
        error.password= ""
    }

    return error
}



export {LoginValidation, LoginValidation2, RegisterValidation, RecoverValidation, ChangePass, ChangeEmail, NewPass};
