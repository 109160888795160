//Imports
import {React, useEffect, useState, useContext} from 'react';
import { Button } from '../../../components';
import { Header } from '../../../components';
import { Link } from 'react-router-dom';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { API_PORT } from '../../../API_PORT';
import { UserContext } from '../../../contexts/UserContext';
import deletesvg from "../../../assets/delete.svg"
import "../../../hook/Tables.css"
import MUIDataTable, {TableToolbar} from 'mui-datatables';
import { ThemeProvider } from '@mui/material/styles';
import getMuiTheme2 from '../../../hook/getMuiTheme2';

//Main function
const AccountNotifications = () => {
  const userContext = useContext(UserContext);
  const token = userContext.value.access_token;
  const link= API_PORT + "/notifications/get-notification-list/?token="+token
  const link2 = API_PORT + "/notifications/delete-notification/"
  const [notifications, setNotifications] = useState([])
  const options={
    rowsPerPage: 10,
    rowsPerPageOptions: [1,2,3,4,5,6,7,8,9,10,15],
    selectableRows: "none",
    tableBodyMaxHeight: "380px",
    print: "false",
    download: "false",
    filter: "false",
    viewColumns: "false",
    responsive: "vertical",
    textLabels: {
      toolbar: {
        search: "Buscar",  // Cambiar el texto del tooltip de "Search" a "Buscar fila"
      },
      pagination: {
        rowsPerPage: "Filas por página:",  // Cambiar el texto de "Rows per page" a "Filas por página"
        next: "Siguiente página",
        previous: "Página anterior",
        displayRows: "de",
      },
      body: {
        noMatch: "No hay notificaciones disponibles en tu cuenta", // Cambia este texto a lo que necesites
      },
    },
  }

  //Get notification endpoint
  useEffect(() => {
    fetch(link, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
      })
      .then(res => res.json())
      .then(data => {
        setNotifications(data);
      }).catch((error) => {
        console.log(error)
        alert("No se han podido obtener tus notificaciones, vuelve a intentarlo.")
      });
    }, [link]);

    //Make notification table
    var notification_list  = [];
    notifications?.forEach((element, index) => {
      notification_list.push([element.id, element.message, element.notification_date, 
          <div className='flex justify-center items-center'>
          <TooltipComponent content="Eliminar notificación"><button onClick={()=>{handleDelete(index,element.id)}}><img src={deletesvg} className='w-6 h-6' alt="Eliminar reporte"></img></button></TooltipComponent>
        </div>
        ])
    }
  )

  //Delete Notification function
  const DeleteNotification = (index) => {
    fetch(link2+index+"?token="+token, {
      method: 'DELETE',
      headers: {
        'Content-type': 'application/json'
      }
      })
      .then(res => res.json())
      .then(data => {
        alert(data.detail)
      }).catch((error) => {
        console.log(error)
      });
  }

  //Delete notification of list
  const handleDelete = (index,id) => {
    setNotifications(prevNotifications =>
      prevNotifications.filter((_, i) => i !== index)
    );
    DeleteNotification(id)
  };

  //Function to fix columns design
  function columns_convert(columns) { 
    return columns.map((column, index) => ({
      name: column,
      label: column,
      options: {
        // Estilos del encabezado con bordes y sticky aplicado a la primera columna
        customHeadRender: (columnMeta) => {
          return (
            <th
              key={columnMeta.index}
              className="custom-header custom-cell-spacing"
              style={{
                background: "#b0b0b0",  // Color de fondo gris oscuro para el encabezado
                color: "white",  // Texto en blanco para el encabezado
                fontWeight: "bold",  // Texto en negrita
                textAlign: 'center',  // Centrar el contenido en el encabezado
                position: 'sticky',  // Sticky para todas las celdas del encabezado
                top: 0,  // Fijar la fila del encabezado en la parte superior
                left: index === 0 ? 0 : 'auto',  // Fijar la primera columna
                zIndex: index === 0 ? 102 : 101,  // Asegura que el encabezado esté por encima del contenido
                height: '50px',  // Ajustar el alto del encabezado
                verticalAlign: 'middle',  // Alinear verticalmente al centro
              }}
            >
              {columnMeta.label}
            </th>
          );
        },
        // Estilos para las celdas del cuerpo de la tabla
        setCellProps: () => ({
          style: {
            textAlign: 'center',  // Centrar el contenido horizontalmente
            verticalAlign: 'middle',  // Centrar el contenido verticalmente
            borderRight: '1px solid #9c9c9c',  // Borde gris oscuro entre columnas
            borderBottom: '1px solid #9c9c9c',  // Borde gris oscuro debajo de las filas
            backgroundClip: 'padding-box', // Asegura que el fondo no se solape con el borde
            boxSizing: 'border-box',  // Asegura que los bordes y el padding se incluyan en el tamaño de la celda
          },
        }),
      }
    }));
  }

  //Main render
  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
      <div className='flex justify-between items-center mb-5'>
            <Link to="/dashboard/account" >
              <Button
                color="white"
                bgColor="gray"
                text="Volver a mi perfil"
                borderRadius="10px"
                size="md"
              />
            </Link>
      </div>  
      <Header title="Notificaciones"/>
      <p className='text-gray-500'>En esta sección podrás ver toda la lista de notificaciones de tu cuenta de EcoKinesis. Si pulsas el botón de borrar, esta se eliminará permanentemente de tu lista de notificaciones.</p>
      <div className='mt-2'>
          <div className="custom-table-container"><ThemeProvider theme={getMuiTheme2()}><MUIDataTable
            data={notification_list}
            columns={columns_convert(["ID Notificación","Notificación","Fecha notificación","Eliminar"])}
            options={options}
            components={{
              TableToolbar: (props) => (
                <div
                  style={{
                    backgroundColor: '#b0b0b0',
                  }}
                >
                  {/* Mantener los íconos del toolbar predeterminado, sin duplicar el título */}
                  <TableToolbar {...props} title={null} />
                </div>
              ),
            }}
            ></MUIDataTable></ThemeProvider>
          </div>
        </div>
    </div>
  )
}

export default AccountNotifications