//Imports
import {React, useState, useContext, useRef, useEffect} from 'react'
import { UserContext } from '../../../../contexts/UserContext';
import { Header } from '../../../../components';
import { useNavigate, useLocation } from 'react-router-dom';
import capitalize from '../../../../hook/capitalize';
import { Link } from 'react-router-dom';
import graphColors from '../../../../hook/graphColors';
import ModalLoader from '../../../../components/ModalLoader';
import LoadingScreen from '../../../../components/LoadingScreen';
import ecokinesis from "../../../../assets/ecokinesis.png"
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import HorizontalTables from '../../../../hook/HorizontalTables';
import { API_PORT } from '../../../../API_PORT';
import anid from "../../../../assets/anid_rojo_azul.png"
import utfsm from "../../../../assets/utfsm.png"
import { Bar, Line, Doughnut, Radar } from "react-chartjs-2";
import { Chart as ChartJS, Title, BarElement, CategoryScale, LinearScale, PointElement, RadialLinearScale, LineElement, Filler, Tooltip, Legend, ArcElement} from "chart.js";
import { useStateContext } from '../../../../contexts/ContextProvider';
import DynamicTable from '../../../../hook/DynamicTable';
ChartJS.register(Title, BarElement, CategoryScale, LinearScale, PointElement, RadialLinearScale, LineElement, ArcElement, Filler, Tooltip, Legend)

//Main function
const BasicScenaryPDF = () => {
  const {activeMenu} = useStateContext();
  const maxWidth = activeMenu ? '1080px' : '100%';
  const [progress, setProgress] = useState(0);
  const [msg, setMsg] = useState(null);
  const [msgporcentage, setMsgPorcentage] = useState(null);
  const [loaderscreen, setLoaderScreen] = useState({started: false});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loadingscreen, setLoadingScreen] = useState({started: true});
  const [open, setOpen] = useState(true);
  const [usersReports, setUsersReports] = useState([])
  const userContext = useContext(UserContext);
  const user_type= userContext.value.user_type;
  const token = userContext.value.access_token;
  const navigate = useNavigate();
  const location = useLocation();
  const today = new Date();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const date = today.getDate();

  //Define const of location
  const values = location.state.values
  const technologies = location.state.technologies
  const tablelike = location.state.tablelike
  const starplot = location.state.starplot
  const summary = location.state.summary
  const aggregate_value_columns = location.state.aggregate_value_columns
  const aggregate_value_data = location.state.aggregate_value_data
  const aggregate_value_columns_base = location.state.aggregate_value_columns_base
  const aggregate_value_data_base = location.state.aggregate_value_data_base
  const components = location.state.components
  const detailcomposition = location.state.detailcomposition
  const impactscontribution = location.state.impactscontribution
  const exportimports = location.state.exportimports
  const ratefosilrenovable = location.state.ratefosilrenovable
  const capitaldistribution = location.state.capitaldistribution
  const remunerationdistribution = location.state.remunerationdistribution
  const fiscalimpact = location.state.fiscalimpact
  const investment = location.state.investment
  const componentsmip = location.state.componentsmip
  const detailcompositionmip = location.state.detailcompositionmip
  const impactscontributionmip = location.state.impactscontributionmip
  const exportimportsmip = location.state.exportimportsmip
  const capitaldistributionmip = location.state.capitaldistributionmip
  const remunerationdistributionmip = location.state.remunerationdistributionmip
  const fiscalimpactmip = location.state.fiscalimpactmip
  const directemissions = location.state.directemissions
  const carbonfootprint = location.state.carbonfootprint
  const directemissionsmip = location.state.directemissionsmip
  const carbonfootprintmip= location.state.carbonfootprintmip
  const inducedemployment = location.state.inducedemployment
  const remunerationanalisis = location.state.remunerationanalisis
  const historicalemployees = location.state.historicalemployees
  const pibemployment= location.state.pibemployment
  const finaldemand = location.state.finaldemand
  const inducedemploymentmip = location.state.inducedemploymentmip
  const remunerationanalisismip = location.state.remunerationanalisismip
  const pibemploymentmip = location.state.pibemploymentmip
  const finaldemandmip = location.state.finaldemandmip
  const base = " (MIP Año Base)"

  var table_like_columns = location.state.table_like_columns
  var table_like_data = location.state.table_like_data
  var star_plot_points = location.state.star_plot_points
  var star_plot_datasets = location.state.star_plot_datasets

  var agregate_components_columns = location.state.agregate_components_columns
  var agregate_components_datasets = location.state.agregate_components_datasets
  var porcentage_participation_columns = location.state.porcentage_participation_columns
  var porcentage_participation_datasets = location.state.porcentage_participation_datasets
  var detail_compositions_columns = location.state.detail_compositions_columns
  var detail_compositions_data = location.state.detail_compositions_data
  var pib_total_columns = location.state.pib_total_columns
  var pib_total_data = location.state.pib_total_data
  var pib_total_columns_doughnut = location.state.pib_total_columns_doughnut
  var pib_total_data_doughnut = location.state.pib_total_data_doughnut
  var pib_total_columns_doughnutbase = location.state.pib_total_columns_doughnutbase
  var pib_total_data_doughnutbase = location.state.pib_total_data_doughnutbase
  var porcentual_direct_contribution_columns = location.state.porcentual_direct_contribution_columns
  var porcentual_direct_contribution_data = location.state.porcentual_direct_contribution_data
  var porcentual_indirect_contribution_columns = location.state.porcentual_indirect_contribution_columns
  var porcentual_indirect_contribution_data = location.state.porcentual_indirect_contribution_data
  var porcentual_total_contribution_columns = location.state.porcentual_total_contribution_columns
  var porcentual_total_contribution_data = location.state.porcentual_total_contribution_data
  var export_imports_columns = location.state.export_imports_columns
  var export_imports_datasets = location.state.export_imports_datasets
  var capital_distribution_columns = location.state.capital_distribution_columns
  var capital_distribution_data = location.state.capital_distribution_data
  var remuneration_distribution_columns = location.state.remuneration_distribution_columns
  var remuneration_distribution_data = location.state.remuneration_distribution_data
  var fiscal_impacts_columns = location.state.fiscal_impacts_columns
  var fiscal_impacts_data = location.state.fiscal_impacts_data
  var investment_columns = location.state.investment_columns
  var investment_data = location.state.investment_data

  var mip_porcentual_direct_contribution_columns = location.state.mip_porcentual_direct_contribution_columns
  var mip_porcentual_direct_contribution_data = location.state.mip_porcentual_direct_contribution_data
  var mip_porcentual_indirect_contribution_columns = location.state.mip_porcentual_indirect_contribution_columns
  var mip_porcentual_indirect_contribution_data = location.state.mip_porcentual_indirect_contribution_data
  var mip_porcentual_total_contribution_columns = location.state.mip_porcentual_total_contribution_columns
  var mip_porcentual_total_contribution_data = location.state.mip_porcentual_total_contribution_data

  var direct_emissions_columns = location.state.direct_emissions_columns
  var direct_emissions_data = location.state.direct_emissions_data
  var carbon_footprint_columns = location.state.carbon_footprint_columns
  var carbon_footprint_data = location.state.carbon_footprint_data

  var induced_employment_columns = location.state.induced_employment_columns
  var induced_employment_datasets = location.state.induced_employment_datasets
  var remuneration_analisis_columns = location.state.remuneration_analisis_columns
  var remuneration_analisis_data = location.state.remuneration_analisis_data
  var historical_employees_columns = location.state.historical_employees_columns
  var historical_employees_dataset = location.state.historical_employees_dataset
  var final_demand_columns = location.state.final_demand_columns
  var final_demand_data = location.state.final_demand_data
  const coins = location.state.coins

  //Data variables
  const [name, setName] = useState({
    "pdf_name": "Escenario personalizado " + capitalize(values.country) + " " + values.pelp_year
  }); 

  //Graph and tables options
  //Graph 2
  const ChartData1 = {
    labels: star_plot_points,
    datasets: star_plot_datasets
  };
  const graph_options1 = {
    plugins:{
      title: {
      display: true,
      text: starplot.title,
      align: "center",
      },
  }};

  //Graph 3
  const ChartData2 = {
    labels: agregate_components_columns,
    datasets: agregate_components_datasets
  };
  const graph_options2 = {
    plugins:{
      title: {
      display: true,
      text: components.coponentes_PIB_values.title,
      align: "center",
      },
  },
  indexAxis: 'y',
      scales: {
          x: {title: {display: "true", text: coins[values.country], color:"black"}, stacked: true},
          y: {stacked: true}
      }};

//Graph 4
/*const ChartData3 = {
    labels: porcentage_components_columns,
    datasets: porcentage_components_datasets
  };
  const graph_options3 = {
    plugins:{
      title: {
      display: true,
      text: components.componentes_PIB_por_sector.title,
      align: "center",
      },
      
  },
  indexAxis: 'y',
      scales: {
          x: {title: {display: "true", text: "Porcentaje de participación", color:"black"}, stacked: true, max:100},
          y: {stacked: true}
      }};
*/

//Graph 5
const ChartData4 = {
    labels: porcentage_participation_columns,
    datasets: porcentage_participation_datasets
  };
  const graph_options4 = {
    plugins:{
      title: {
      display: true,
      text: components.componentes_PIB_por_componentes_valor_agregado.title,
      align: "center",
      },
      
  },
  indexAxis: 'y',
      scales: {
          x: {title: {display: "true", text: "Porcentaje de participación", color:"black"}, stacked: true, max:100},
          y: {stacked: true}
      }};

      //Graph 7
  const ChartData5 = {
    labels: pib_total_columns,
    datasets: [
      {
      label: pib_total_data[0].name,
      data: pib_total_data[0].data,
      backgroundColor: graphColors([pib_total_data[0].name]),
      borderWidth: 0,
      },
      {
        label: pib_total_data[1].name,
        data: pib_total_data[1].data,
        backgroundColor: graphColors([pib_total_data[1].name]),
        borderWidth: 0,
        }
    ]
  };
  const graph_options5 = {
    plugins:{
      title: {
      display: true,
      text: impactscontribution.PIBsDirectosIndirectosTotales.title,
      align: "center",
      },
      
  },
  indexAxis: 'y',
      scales: {
          x: {title: {display: "true", text: coins[values.country], color:"black"}, stacked: true},
          y: {stacked: true}
      }};

//Graph 8
const ChartData6 = {
    labels: porcentual_direct_contribution_columns,
    datasets: [{
      label: "Contribución porcentual",
      data: porcentual_direct_contribution_data,
      backgroundColor: graphColors(porcentual_direct_contribution_columns),
      borderWidth: 1
    } ]
  }
  const graph_options6 = {
    plugins : {
      title: {
        display: true,
        text: impactscontribution.DirectContributionPercentage.title,
        align: "center",
      }}
  }
  const ChartData7 = {
    labels: mip_porcentual_direct_contribution_columns,
    datasets: [{
      label: "Contribución porcentual",
      data: mip_porcentual_direct_contribution_data,
      backgroundColor: graphColors(mip_porcentual_direct_contribution_columns),
      borderWidth: 1
    } ]
  }
  const graph_options7 = {
    plugins : {
      title: {
        display: true,
        text: impactscontributionmip.DirectContributionPercentage.title + base,
        align: "center",
      }}
  }

  //Graph 9
  const ChartData8 = {
    labels: porcentual_indirect_contribution_columns,
    datasets: [{
      label: "Contribución porcentual",
      data: porcentual_indirect_contribution_data,
      backgroundColor: graphColors(porcentual_indirect_contribution_columns),
      borderWidth: 1
    } ]
  }
  const graph_options8 = {
    plugins : {
      title: {
        display: true,
        text: impactscontribution.InducedContributionPercentage.title,
        align: "center",
      }}
  }
  const ChartData9 = {
    labels: mip_porcentual_indirect_contribution_columns,
    datasets: [{
      label: "Contribución porcentual",
      data: mip_porcentual_indirect_contribution_data,
      backgroundColor: graphColors(mip_porcentual_indirect_contribution_columns),
      borderWidth: 1
    } ]
  }
  const graph_options9= {
    plugins : {
      title: {
        display: true,
        text: impactscontributionmip.InducedContributionPercentage.title + base,
        align: "center",
      }}
  }

  //Graph 10
  const ChartData10 = {
    labels: porcentual_total_contribution_columns,
    datasets: [{
      label: "Contribución porcentual",
      data: porcentual_total_contribution_data,
      backgroundColor: graphColors(porcentual_total_contribution_columns),
      borderWidth: 1
    } ]
  }
  const graph_options10 = {
    plugins : {
      title: {
        display: true,
        text: impactscontribution.TotalContributionPercentage.title,
        align: "center",
      }}
  }
  const ChartData11 = {
    labels: mip_porcentual_total_contribution_columns,
    datasets: [{
      label: "Contribución porcentual",
      data: mip_porcentual_total_contribution_data,
      backgroundColor: graphColors(mip_porcentual_total_contribution_columns),
      borderWidth: 1
    } ]
  }
  const graph_options11 = {
    plugins : {
      title: {
        display: true,
        text: impactscontributionmip.TotalContributionPercentage.title + base,
        align: "center",
      }}
  }

  //Graph 12
  const ChartData12 = {
    labels: export_imports_columns,
    datasets: export_imports_datasets}
  const graph_options12 = {
    scales: {
      x: {title:{display: true, text: "Tecnologías"}},
      y: {title:{display: true, text: "Exportaciones e importaciones ["+coins[values.country]+"]"}}
    },
    plugins : {
      title: {
        display: true,
        text: exportimports.title,
        align: "center",
      }
    }
  }

  //Graph 13
  const ChartData13 = {
    labels: capital_distribution_columns,
    datasets: [{
      label: capitaldistribution.data[0].x_index,
      data: capital_distribution_data,
      backgroundColor: graphColors(capital_distribution_columns),
      borderWidth: 0,
      }]
  }
  const graph_options13 = {
    scales: {
      x: {title:{display: true, text: "Tecnologías", color: "black"}},
      y: {title:{display: true, text: capitaldistribution.data[0].x_index, color: "black"}}
    },
    plugins : {
      legend: {display: false},
      title: {
        display: true,
        text: location.state.capitaldistribution.title,
        align: "center",
      }
    }
  }

  //Graph 14
  const ChartData14 = {
    labels: remuneration_distribution_columns,
    datasets: [{
      label: remunerationdistribution.data[0].x_index,
      data: remuneration_distribution_data,
      backgroundColor: graphColors(remuneration_distribution_columns),
      borderWidth: 0,
      }]
  }
  const graph_options14= {
    scales: {
      x: {title:{display: true, text: "Tecnologías", color: "black"}},
      y: {title:{display: true, text: remunerationdistribution.data[0].x_index, color: "black"}}
    },
    plugins : {
      legend: {display: false},
      title: {
        display: true,
        text: remunerationdistribution.title,
        align: "center",
      }
    }
  }

  //Graph 17
  const ChartData15 = {
    labels: direct_emissions_columns,
    datasets: [
      {
      label: direct_emissions_data[0].name,
      data: direct_emissions_data[0].data,
      backgroundColor: graphColors([direct_emissions_data[0].name]),
      borderWidth: 0,
      categoryPercentage: 0.7
      },
      {
        label: direct_emissions_data[1].name,
        data: direct_emissions_data[1].data,
        backgroundColor: graphColors([direct_emissions_data[1].name]),
        borderWidth: 0,
        categoryPercentage: 0.7
        }
    ]
  }
  const graph_options15= {
    scales: {
      x: {title:{display: true, text: "Actividades Económicas"}},
      y: {title:{display: true, text: "Emisiones Directas y Huella de Carbono [Mt CO2 eq]"}}
    },
    plugins : {
      title: {
        display: true,
        text: directemissions.title,
        align: "center",
      }
    }
  }

   //Graph 18
   const ChartData16 = {
    labels: carbon_footprint_columns,
    datasets: [{
      label: "[Kt CO2 eq/"+coins[values.country]+"]",
      data: carbon_footprint_data,
      backgroundColor: graphColors(carbon_footprint_columns),
      borderWidth: 0,
      }]
  }
  const graph_options16 = {
    scales: {
      x: {title:{display: true, text: "Actividades Económicas"}},
      y: {title:{display: true, text: "Huella de carbono sobre valor agregado "+ "[Kt CO2 eq/"+coins[values.country]+"]"}}
    },
    plugins : {
      legend: {display: false},
      title: {
        display: true,
        text: carbonfootprint.title,
        align: "center",
      }
    }
  }

  //Graph 19
  const ChartData17 = {
    labels: induced_employment_columns,
    datasets: induced_employment_datasets
  };
  const graph_options17 = {
    plugins:{
      title: {
      display: true,
      text: inducedemployment.title,
      align: "center",
      }},
  indexAxis: 'x',
      scales: {
          x: {title: {display: "true", text: "Tecnologías", color:"black"}, stacked: true},
          y: {title: {display: "true", text: "Empleos", color:"black"}, stacked: true}
      }};

//Graph 21
 const ChartData18 = {
    labels: historical_employees_columns,
    datasets: historical_employees_dataset
  };
  const graph_options18 = {
    responsive: true,
    plugins:{
      title: {
      display: true,
      text: historicalemployees.title,
      align: "center",
      }},
      scales: {
          x: {title: {display: "true", text: "Años", color:"black", stacked: true}},
          y: {title: {display: "true", text: "Empleos directos", color:"black", stacked: true}}
      }};

//Graph 22
/*const ChartData19 = {
    labels: pib_employment_columns,
    datasets: [{
      label: "PIB / Empleados ["+coins[values.country]+"]",
      data: pib_employment_data,
      backgroundColor: graphColors(pib_employment_columns),
      borderWidth: 0,
      }]
  }
  const graph_options19 = {
    scales: {
      x: {title:{display: true, text: "Tecnologías"}},
      y: {title:{display: true, text: "PIB / Empleados ["+coins[values.country]+"]"}}
    },
    plugins : {
      legend: {display: false},
      title: {
        display: true,
        text: pibemployment.title,
        align: "center",
      }
    }
  }
*/

  //Graph 23
  const ChartData20 = {
    labels: final_demand_columns,
    datasets: [
      {label: final_demand_data[0].name,
      data: final_demand_data[0].data,
      backgroundColor: graphColors([final_demand_data[0].name]),
      borderWidth: 0,
      categoryPercentage: 0.8},
      {label: final_demand_data[1].name,
        data: final_demand_data[1].data,
        backgroundColor: graphColors([final_demand_data[1].name]),
        borderWidth: 0,
        categoryPercentage: 0.8},
    ]}
  const graph_options20 = {
    scales: {
      x: {title:{display: true, text: "Tecnologías"}},
      y: {title:{display: true, text: "Empleos/Demanda final[-/"+coins[values.country]+"]"}}
    },
    plugins : {
      title: {
        display: true,
        text: finaldemand.title,
        align: "center",
      }
    }
  }

  //Doughnut Info
  const totalValue = aggregate_value_data
        .map(value => parseFloat(value))  // Convierte cada valor de string a float
        .reduce((acc, current) => acc + current, 0);  // Suma los valores convertidos

      const ChartDataD1 = {
        labels: aggregate_value_columns,
        datasets: [{
          label: `Valor agregado escenario personalizado [${coins[values.country]}]`,
          data: aggregate_value_data.map(value => parseFloat(value)), // Asegurarse de que los datos sean floats
          backgroundColor: graphColors(aggregate_value_columns),
          borderWidth: 1,
        }]
      };

      const optionsD1 = {
        plugins: {
          title: {
            display: true,
            text: `Valor agregado escenario personalizado [${coins[values.country]}]`,
            align: "center",
          },
          // Personalización para mostrar el total en el centro del gráfico de dona
          centerText: {
            display: true,
            title: 'Valor agregado',  // Primera línea (título)
            value: `${totalValue.toFixed(2)} [${coins[values.country]}]`  // Segunda línea (valor total)
          },
          tooltip: {
            callbacks: {
              label: function(tooltipItem) {
                const dataset = tooltipItem.dataset;
                const currentValue = dataset.data[tooltipItem.dataIndex];
                const percentage = ((currentValue / totalValue) * 100).toFixed(2);  // Calcula el porcentaje
                return `Valor agregado escenario personalizado: ${currentValue} [${coins[values.country]}] (${percentage}%)`;  // Muestra el valor y el porcentaje
              }
            }
          }
        }
      };

      // Convertir los valores a float y calcular el valor total
        const totalValue2 = aggregate_value_data_base
        .map(value => parseFloat(value))  // Convierte cada valor de string a float
        .reduce((acc, current) => acc + current, 0);  // Suma los valores convertidos

      const ChartDataD2 = {
        labels: aggregate_value_columns_base,
        datasets: [{
          label: `Valor agregado [${coins[values.country]}]`,
          data: aggregate_value_data_base.map(value => parseFloat(value)), // Asegurarse de que los datos sean floats
          backgroundColor: graphColors(aggregate_value_columns_base),
          borderWidth: 1,
        }]
      };

      const optionsD2 = {
        plugins: {
          title: {
            display: true,
            text: `Valor agregado [${coins[values.country]}] ${base}`,
            align: "center",
          },
          // Personalización para mostrar el total en el centro del gráfico de dona
          centerText: {
            display: true,
            title: 'Valor agregado',  // Primera línea (título)
            value: `${totalValue2.toFixed(2)} [${coins[values.country]}]`  // Segunda línea (valor total)
          },
          tooltip: {
            callbacks: {
              label: function(tooltipItem) {
                const dataset = tooltipItem.dataset;
                const currentValue = dataset.data[tooltipItem.dataIndex];
                const percentage = ((currentValue / totalValue2) * 100).toFixed(2);  // Calcula el porcentaje
                return `Valor agregado${base}: ${currentValue} [${coins[values.country]}] (${percentage}%)`;  // Muestra el valor y el porcentaje
              }
            }
          }
        }
      };

      const roundedArray = pib_total_data_doughnut.map(num => parseFloat(num.toFixed(2)));
      const totalValue3 = roundedArray
      .map(value => parseFloat(value))  // Convierte cada valor de string a float
      .reduce((acc, current) => acc + current, 0);  // Suma los valores convertidos

      // Convertir los valores a float y calcular el valor total
      const roundedArray2 = pib_total_data_doughnutbase.map(num => parseFloat(num.toFixed(2)));
      const totalValue4 = roundedArray2
      .map(value => parseFloat(value))  // Convierte cada valor de string a float
      .reduce((acc, current) => acc + current, 0);  // Suma los valores convertidos

    const ChartDataD3 = {
      labels: pib_total_columns_doughnut,
      datasets: [{
        label: `Impacto PIB total [${coins[values.country]}]`,
        data: roundedArray.map(value => parseFloat(value)), // Asegurarse de que los datos sean floats
        backgroundColor: graphColors(pib_total_columns_doughnut),
        borderWidth: 1,
      }]
    };

    const optionsD3 = {
      plugins: {
        title: {
          display: true,
          text: `Impacto total en el PIB escenario personalizadp en ${coins[values.country]}`,
          align: "center",
        },
        // Personalización para mostrar el total en el centro del gráfico de dona
        centerText: {
          display: true,
          title: 'Impacto PIB total',  // Primera línea (título)
          value: `${totalValue3.toFixed(2)} [${coins[values.country]}]`  // Segunda línea (valor total)
        },
        tooltip: {
          callbacks: {
            label: function(tooltipItem) {
              const dataset = tooltipItem.dataset;
              const currentValue = dataset.data[tooltipItem.dataIndex];
              const percentage = ((currentValue / totalValue3) * 100).toFixed(2);  // Calcula el porcentaje
              return `Impacto PIB total escenario personalizado: ${currentValue} [${coins[values.country]}] (${percentage}%)`;  // Muestra el valor y el porcentaje
            }
          }
        }
      }
    };

    const ChartDataD4 = {
      labels: pib_total_columns_doughnutbase,
      datasets: [{
        label: `Impacto PIB total [${coins[values.country]}]`,
        data: roundedArray2.map(value => parseFloat(value)), // Asegurarse de que los datos sean floats
        backgroundColor: graphColors(pib_total_columns_doughnutbase),
        borderWidth: 1,
      }]
    };

    const optionsD4 = {
      plugins: {
        title: {
          display: true,
          text: `Impacto total en el PIB de los sectores generación, almacenamiento y H2 en ${coins[values.country]} (MIP Año Base)`,
          align: "center",
        },
        // Personalización para mostrar el total en el centro del gráfico de dona
        centerText: {
          display: true,
          title: 'Impacto PIB total',  // Primera línea (título)
          value: `${totalValue4.toFixed(2)} [${coins[values.country]}]`  // Segunda línea (valor total)
        },
        tooltip: {
          callbacks: {
            label: function(tooltipItem) {
              const dataset = tooltipItem.dataset;
              const currentValue = dataset.data[tooltipItem.dataIndex];
              const percentage = ((currentValue / totalValue4) * 100).toFixed(2);  // Calcula el porcentaje
              return `Impacto PIB total MIP Año Base: ${currentValue} [${coins[values.country]}] (${percentage}%)`;  // Muestra el valor y el porcentaje
            }
          }
        }
      }
    };




  const handleInput = (event) =>{
    setName(prev => ({...prev, [event.target.name]:event.target.value}))
  };


  const chartRefs = useRef([]);
  const pages = 23 //Change for total of graph and tables

  //Response management function
  const responsereport = (response) => {
    if (response === true) {
        setMsg("¡Reporte guardado exitosamente!")
        alert("Se ha guardado tu reporte exitosamente. Revisa la sección de reportes de la barra izquierda para poder visualizarlo.")
      }
    else {
      alert("No se ha podido guardar el reporte. Recarga tu sesión o inténtalo nuevamente más tarde.")
      setMsg("No se ha podido guardar tu reporte")
    }
    setMsgPorcentage(null);
    setLoaderScreen(prevState => {
      return {...prevState, started: false}
    })
  }

  //Obtain reports datra
  useEffect(() => {
    fetch(API_PORT+'/admin/reports/user/get_reports_list/?token=' + token, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
      })
      .then(res => res.json()).then(data => {
        setUsersReports(data);
        setOpen(false);
        setLoadingScreen(prevState => {return {...prevState, started: false}});
      });
    }, [token]);

    //Save Report function
    const SaveReport = async () =>{
      // Verifica si el nombre del reporte ya existe
      const reportExists = usersReports.some(report => report.report_name === name.pdf_name);
      if (reportExists) {
          alert("El nombre del reporte ya existe. Cambia el nombre de este por otro que no esté guardado en tu cuenta.");
          return; // Detiene la ejecución si el nombre ya existe
      }

      const pdf2 = new jsPDF();
      setIsModalOpen(true)
      setProgress(0); // Inicia el progreso en 0%
      setMsg("Generando el reporte. No cierres la ventana hasta completar el proceso.")
      setLoaderScreen(prevState => {
        return {...prevState, started: true}
      })
      for (let i = 0; i < pages; i++) {
        const chartRef = chartRefs.current[i];
        const canvas = await html2canvas(chartRef);
        const imageData = canvas.toDataURL('image/png');
        const pdfWidth = pdf2.internal.pageSize.getWidth()
        const pdfHeight = pdf2.internal.pageSize.getHeight()
        const imgWidth = canvas.width
        const imgHeight = canvas.height
        const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight)
        pdf2.addImage(imageData, 'PNG', 16, 16, imgWidth*ratio*0.85, imgHeight*ratio*0.85);
        if (i < pages - 1) {
          pdf2.addPage();
        }
        setProgress(((i + 1) / pages) * 100);
        setMsgPorcentage((((i + 1) / pages) * 100).toFixed(0) + " %");
        
      }
      const reportData = new FormData();
      reportData.append('report_name', name.pdf_name);
      reportData.append('report_type', 'Escenario Personalizado');
      reportData.append('report_description', 'Reporte Escenario Personalizado');
      reportData.append('file', new Blob([pdf2.output('blob')]), name.pdf_name+'.pdf');
      setMsgPorcentage(null);
      setMsg("Guardando el reporte en tu cuenta. No cierres la ventana hasta completar el proceso.")

      try {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', `${API_PORT}/admin/reports/user/save_report/?token=${token}`, true);

        xhr.upload.onprogress = (event) => {
            if (event.lengthComputable) {
                const percentComplete = (event.loaded / event.total) * 100;
                setProgress(percentComplete);
                setMsgPorcentage(`${percentComplete.toFixed(0)} %`);
            }
        };

        xhr.onload = () => {
            if (xhr.status >= 200 && xhr.status < 300) {
                console.log('Archivo subido con éxito');
                responsereport(true);
            } else {
                throw new Error('Error al guardar el reporte');
            }
        };

        xhr.onerror = () => {
            console.error('Ocurrió un error al intentar guardar el reporte');
            responsereport(false);
        };

        xhr.send(reportData);
    } catch (error) {
        console.error('Ocurrió un error al intentar guardar el reporte:', error);
        setMsgPorcentage(null);
        responsereport(false);
    } 
  }

  //Generate PDF function
  const generatePDF = async () => {
    const pdf = new jsPDF();
    setIsModalOpen(true)
    setProgress(0); // Inicia el progreso en 0%
    setMsg("Generando el reporte para ser descargado. No cierres la ventana hasta completar el proceso.")
    setLoaderScreen(prevState => {
      return {...prevState, started: true}
    })
    for (let i = 0; i < pages; i++) {
      const chartRef = chartRefs.current[i];
      const canvas = await html2canvas(chartRef);
      const imageData = canvas.toDataURL('image/png');
      const pdfWidth = pdf.internal.pageSize.getWidth()
      const pdfHeight = pdf.internal.pageSize.getHeight()
      const imgWidth = canvas.width
      const imgHeight = canvas.height
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight)
      pdf.addImage(imageData, 'PNG', 16, 16, imgWidth*ratio*0.85, imgHeight*ratio*0.85);
      if (i < pages - 1) {
        pdf.addPage();
      }
      // Actualiza el progreso
      setProgress(((i + 1) / pages) * 100);
      setMsgPorcentage((((i + 1) / pages) * 100).toFixed(0) + " %");
    }
    setMsg("¡Reporte generado!")
    setMsgPorcentage(null);
    setLoaderScreen(prevState => {
      return {...prevState, started: false}
    })
    pdf.save(name.pdf_name);
  };

  //Render graph and tables
  function Render(user){
    if(user === "premium" || user === "admin"){
        return(
            <div>
                <div className='mt-10'><Header title="Coloca el nombre de tu reporte"/></div>
                    <div className='grid grid-cols-3 gap-3'>
                        <div className='columns-1'>
                            <h1 className='mb-3'><strong>Nombre del reporte</strong></h1>
                            <input type='string' placeholder={name.pdf_name} name='pdf_name' onChange={handleInput} className='form-control rounded-0 w-96 h-10 p-1' style={{border:"1px solid" ,borderRadius: "8px", color: "gray", backgroundColor:"white"}}></input>
                        </div>
                    </div>
                    <div className='flex gap-5 mt-16'>
                        <button style={{color:'white', backgroundColor:"#425df9", borderRadius: "10px"}} className="text-lg p-3" onClick={SaveReport}>Guardar Reporte</button>
                        <button style={{color:'white', backgroundColor:"#425df9", borderRadius: "10px"}} className="text-lg p-3" onClick={generatePDF}>Descargar Reporte</button>
                    </div>
                    <div key={0} ref={(ref) => (chartRefs.current[0] = ref)} style={{border: "1px solid", width: "100%", padding: "30px", marginTop: "120px"}}>
                        <div className='flex justify-center items-center'>
                          <img style={{maxWidth:"320px", marginBottom:"30px"}} src={ecokinesis} alt=''/>
                        </div>
                        <h1 className='flex justify-center items-center'><strong>Resultados Reporte Escenario Personalizado</strong></h1>
                        <h1 className='flex justify-center items-center'>País Ingresado: {capitalize(values.country)}</h1>
                        <h1 className='flex justify-center items-center'>Año MIP Base Ingresado: {values.year}</h1>
                        <h1 className='flex justify-center items-center'>Año a simular Ingresado: {values.pelp_year}</h1>
                        <h1 className='flex justify-center items-center'>Fecha de creación del reporte: {date}/{month}/{year}</h1>

                        <h1 className='flex justify-center items-center mt-16'><strong>Valores Ingresados</strong></h1>
                        <h1 className='flex justify-center items-center mt-4'><strong>Tecnologías de Generación (GWh)</strong></h1>
                        <h1 className='flex justify-center items-center'>Fósil: {technologies.Fosil}</h1>
                        <h1 className='flex justify-center items-center'>Hidráulica: {technologies.Hidraulica}</h1>
                        <h1 className='flex justify-center items-center'>Biomasa y Biogas: {technologies.BiomasaBiogas}</h1>
                        <h1 className='flex justify-center items-center'>Solar: {technologies.Solar}</h1>
                        <h1 className='flex justify-center items-center'>Eólica: {technologies.Eolica}</h1>
                        <h1 className='flex justify-center items-center'>Geotermia: {technologies.Geotermia}</h1>
                        <h1 className='flex justify-center items-center'>Almacenamiento Largo Plazo: {technologies.AlmacenamientoLargo}</h1>
                        <h1 className='flex justify-center items-center'>Almacenamiento Corto Plazo: {technologies.AlmacenamientoCorto}</h1>
                        <h1 className='flex justify-center items-center'>H2: {technologies.H2}</h1>
                        <h1 className='flex justify-center items-center mt-4'><strong>Capacidad instalada por tecnología (MW)</strong></h1>
                        <h1 className='flex justify-center items-center'>Fósil: {technologies.FosilCapacity}</h1>
                        <h1 className='flex justify-center items-center'>Hidráulica: {technologies.HidraulicaCapacity}</h1>
                        <h1 className='flex justify-center items-center'>Biomasa y Biogas: {technologies.BiomasaBiogasCapacity}</h1>
                        <h1 className='flex justify-center items-center'>Solar: {technologies.SolarCapacity}</h1>
                        <h1 className='flex justify-center items-center'>Eólica: {technologies.EolicaCapacity}</h1>
                        <h1 className='flex justify-center items-center'>Geotermia: {technologies.GeotermiaCapacity}</h1>
                        <h1 className='flex justify-center items-center'>Almacenamiento Largo Plazo: {technologies.AlmacenamientoLargoCapacity}</h1>
                        <h1 className='flex justify-center items-center'>Almacenamiento Corto Plazo: {technologies.AlmacenamientoCortoCapacity}</h1>

                        <h1 className='flex justify-center items-center mt-16'>EcoKinesis Proyecto Fondef Idea ID22I10341 es propiedad de la Universidad Técnica Federico Santa María y ANID</h1>
                        <div className='flex justify-center items-center gap-10 mt-6'>
                          <img style={{maxWidth:"100px"}} src={anid} alt=''/>
                          <img style={{maxWidth:"150px"}} src={utfsm} alt=''/>
                        </div>
                        <h1 className='flex justify-center items-center mt-12'>Disclaimer: Este reporte ha sido generado automáticamente en base a los datos ingresados del usuario en la plataforma de EcoKinesis. EcoKinesis no se hace responsable del uso o vericidad de los datos en este reporte.</h1>
                    </div>
                    <div className="mt-10" key={1} ref={(ref) => (chartRefs.current[1] = ref)}>
                        <h1 className='mb-6'><strong>Resumen de indicadores:</strong> La tabla muestra el resumen de indicadores del año y país ingresado simulado, así como también incluye el resumen de indicadores del año de la MIP base seleccionado. Esta se agrupa tecnologías para las columnas y para las filas se utilizan diferentes indicadores, cada uno con diferentes unidades de medida.</h1>
                        <div className='flex justify-center items-center'>
                          <div style={{ maxWidth }}>
                            <DynamicTable title={tablelike.title} columns={table_like_columns} rows={table_like_data} cellSize="small"/> 
                          </div>
                        </div>
                    </div>
                    <div className="mt-10" key={2} ref={(ref) => (chartRefs.current[2] = ref)}>
                        <h1 className='mb-6'><strong>Valor agregado:</strong> En este gráfico se observa el total de valor agregado y sus porcentajes de los sectores de generación y almacenamiento del escenario personalizado ingresado y del año base.</h1>
                        <div className='flex items-center justify-center gap-6'>
                          <div style={{width: '440px'}}>
                            <Doughnut data={ChartDataD1} options={optionsD1}></Doughnut>
                          </div>
                          <div style={{width: '440px'}}>
                            <Doughnut data={ChartDataD2} options={optionsD2}></Doughnut>
                          </div>
                        </div>
                    </div>
                    <div className="mt-10" key={3} ref={(ref) => (chartRefs.current[3] = ref)}>
                      <h1 className='mb-6'><strong>Comparación de escenarios:</strong> El gráfico muestra una comparación normalizada (respecto al valor máximo de cada item) del escenario personalizado con respecto a los escenarios PELP..</h1>
                      <div style={{maxHeight: "480px", display:"flex", alignItems: "center", justifyContent: "center"}}>
                        <Radar data={ChartData1} options={graph_options1}/>
                      </div>
                    </div>
                    <div className="mt-10" key={4} ref={(ref) => (chartRefs.current[4] = ref)}>
                      <h1 className='mb-6'><strong>Componentes valor agregado:</strong> En el gráfico pueden verse las relaciones del valor agregado, específicamente, el aporte al PIB de diferentes tecnologías para los componentes de remuneraciones de asalariados, excedente bruto de importación e impuestos netos sobre la producción, tanto para el año base de la MIP como para el año de la simulación realizada.</h1>
                      <div className='flex justify-center items-center'>
                        <div style={{width: "59vw"}}>
                        {HorizontalTables(ChartData2, graph_options2)}
                        </div>
                      </div>
                    </div>
                    <div className="mt-10" key={5} ref={(ref) => (chartRefs.current[5] = ref)}>
                      <h1 className='mb-6'><strong>Porcentaje participación sectores:</strong> En el gráfico pueden verse las relacionados del valor agregado, específicamente los porcentajes de participación de los componentes del valor agregado (remuneraciones de aslariados, excedente bruto de explotación, impuestos netos sobre la producción y valor agregado) para diferentes sectores del año y país seleccionado y del año de la MIP base.</h1>
                      <div className='flex justify-center items-center'>
                        <div style={{width: "59vw"}}>
                        {HorizontalTables(ChartData4, graph_options4)}
                        </div>
                      </div>
                    </div>
                    <div className="mt-10" key={6} ref={(ref) => (chartRefs.current[6] = ref)}>
                        <h1 className='mb-6'><strong>Detalle valor agregado:</strong> En esta tabla pueden observarse los detalles de demanda y valor agregado del sector de generación eléctrica, tanto para el año base de la MIP como para el año de la simulación realizada. Las columnas de esta tabla corresponden a diferentes tipos de tecnologías, mientras que sus filas corresponden a los indicadores medidos en {coins[values.country]}.</h1>
                        <div className='flex justify-center items-center'>
                          <div style={{ maxWidth }}>
                            <DynamicTable title={detailcomposition.title} columns={detail_compositions_columns} rows={detail_compositions_data} cellSize="small"/> 
                          </div>
                        </div>
                    </div>
                    <div className="mt-10" key={7} ref={(ref) => (chartRefs.current[7] = ref)}>
                      <h1 className='mb-6'><strong>Impacto PIB directo e indirecto:</strong> En este gráfico se muestra el eimpacto directo, indirecto y total en el PIB. Las filas representan diferentes tecnologías de los sectores de generación, almacenamiento y H2, mientras que sus valores corresponden a los impactos medidos en {coins[values.country]}. Los datos se muestran tanto para el año base de la MIP como para el año de la simulación realizada.</h1>
                      <div className='flex justify-center items-center'>
                        <div style={{width: "59vw"}}>
                        {HorizontalTables(ChartData5, graph_options5)}
                        </div>
                      </div>
                    </div>
                    <div className="mt-10" key={8} ref={(ref) => (chartRefs.current[8] = ref)}>
                      <h1 className='mb-6'><strong>Impacto PIB total:</strong> En este gráfico se muestra el impacto total en el PIB y sus porcentajes medidos en {coins[values.country]}  para las tecnologías de los sectores de generación, almacenamiento y H2. Se incluyen los datos tanto para el escenario generado como para el año de la MIP base.</h1>
                      <div className='flex items-center justify-center gap-6'>
                        <div style={{width: '440px'}}>
                          <Doughnut data={ChartDataD3} options={optionsD3}></Doughnut>
                        </div>
                        <div style={{width: '440px'}}>
                          <Doughnut data={ChartDataD4} options={optionsD4}></Doughnut>
                        </div>
                      </div>
                    </div>
                    <div className="mt-10" key={9} ref={(ref) => (chartRefs.current[9] = ref)}>
                      <h1 className='mb-6'><strong>Contribución porcentual PIB directo:</strong> En este gráfico se observa la contribución porcentual de diferentes tecnologías de los sectores de generación, almacenamiento y H2 respecto al PIB directo para el año y país ingresado, así como para el año de la MIP base.</h1>
                      <div className='flex items-center justify-center gap-6'>
                        <div style={{width: '440px'}}>
                          <Doughnut data={ChartData6} options={graph_options6}></Doughnut>
                        </div>
                        <div style={{width: '440px'}}>
                          <Doughnut data={ChartData7} options={graph_options7}></Doughnut>
                        </div>
                      </div>
                    </div>
                    <div className="mt-10" key={10} ref={(ref) => (chartRefs.current[10] = ref)}>
                      <h1 className='mb-6'><strong>Contribución porcentual PIB indirecto:</strong> En este gráfico se observa la contribución porcentual de diferentes tecnologías de los sectores de generación, almacenamiento y H2 respecto al PIB indirecto para el año y país ingresado, así como para el año de la MIP base.</h1>
                      <div className='flex items-center justify-center gap-6'>
                        <div style={{width: '440px'}}>
                          <Doughnut data={ChartData8} options={graph_options8}></Doughnut>
                        </div>
                        <div style={{width: '440px'}}>
                          <Doughnut data={ChartData9} options={graph_options9}></Doughnut>
                        </div>
                      </div>
                    </div>
                    <div className="mt-10" key={11} ref={(ref) => (chartRefs.current[11] = ref)}>
                      <h1 className='mb-6'><strong>Contribución porcentual PIB total:</strong> En este gráfico se observa la contribución porcentual de diferentes tecnologías de los sectores de generación, almacenamiento y H2 respecto al PIB total (sumando el directo e indirecto) para el año y país ingresado, así como para el año de la MIP base.</h1>
                      <div className='flex items-center justify-center gap-6'>
                        <div style={{width: '440px'}}>
                          <Doughnut data={ChartData10} options={graph_options10}></Doughnut>
                        </div>
                        <div style={{width: '440px'}}>
                          <Doughnut data={ChartData11} options={graph_options11}></Doughnut>
                        </div>
                      </div>
                    </div>
                    <div className="mt-10" key={12} ref={(ref) => (chartRefs.current[12] = ref)}>
                      <h1 className='mb-6'><strong>Exportaciones e importaciones:</strong> En este gráfico se muestran las exportaciones, importaciones y exportaciones netas de bienes y servicios para diferentes tecnologías del año y país ingresado y del año MIP base medidas en {coins[values.country]}.</h1>
                      <div className='flex justify-center items-center'>
                        <div style={{width: "59vw"}}>
                          <Bar data={ChartData12} options={graph_options12}/>
                        </div>
                      </div>
                    </div>
                    <div className="mt-10" key={13} ref={(ref) => (chartRefs.current[13] = ref)}>
                      <h1 className='mb-6'><strong>Distribución capital: </strong>En este gráfico se muestra el excedente bruto de capital medido en {coins[values.country]} de diferentes tecnologías para el año y país ingresado y para el año de la MIP base.</h1>
                      <div className='flex justify-center items-center'>
                        <div style={{width: "59vw"}}>
                          <Bar data={ChartData13} options={graph_options13}/>
                        </div>
                      </div>
                    </div>
                    <div className="mt-10" key={14} ref={(ref) => (chartRefs.current[14] = ref)}>
                      <h1 className='mb-6'><strong>Distribución remuneraciones:</strong> En este gráfico se muestra el total de remuneraciones medido en millones de dólares de diferentes tecnologías para el año y país ingresado y para el año de la MIP base.</h1>
                      <div className='flex justify-center items-center'>
                        <div style={{width: "59vw"}}>
                          <Bar data={ChartData14} options={graph_options14}/>
                        </div>
                      </div>
                    </div>
                    <div className="mt-10" key={15} ref={(ref) => (chartRefs.current[15] = ref)}>
                        <h1 className='mb-6'><strong>Impacto fiscal:</strong> En esta tabla se muestra un resumen del impacto fiscal para el año y país seleccionados y para el año de la MIP base. Sus columnas corresponden a diferentes tecnologías, mientras que sus filas son diversos indicadores de impactos fiscales, cada uno con diferentes unidades de medidas.</h1>
                        <div className='flex justify-center items-center'>
                          <div style={{ maxWidth }}>
                            <DynamicTable title={fiscalimpact.title} columns={fiscal_impacts_columns} rows={fiscal_impacts_data} cellSize="small"/> 
                          </div>
                        </div>
                    </div>
                    <div className="mt-10" key={16} ref={(ref) => (chartRefs.current[16] = ref)}>
                        <h1 className='mb-6'><strong>Requerimientos de inversión:</strong> En esta tabla se muestra los requerimientos de inversión de acuerdo con la capacidad instalada para el año y país seleccionados. Sus columnas corresponden a diferentes tecnologías, mientras que sus filas son diversos indicadores de inversión, cada uno con diferentes unidades de medidas.</h1>
                        <div className='flex justify-center items-center'>
                          <div style={{ maxWidth }}>
                            <DynamicTable title={investment.title} columns={investment_columns} rows={investment_data} cellSize=""/> 
                          </div>
                        </div>
                    </div>
                    <div className="mt-10" key={17} ref={(ref) => (chartRefs.current[17] = ref)}>
                      <h1 className='mb-6'><strong>Emisiones directas y huella de carbono:</strong> El gráfico muestra las emisiones directas y la huella de carbono medidas en mega toneladas de CO2 equivalente para diferentes actividades económicas del año y país ingresados y para el año de la MIP base. También se incluye la meta u objetivo para 2030.</h1>
                      <div className='flex justify-center items-center'>
                        <div style={{width: "59vw"}}>
                          <Bar data={ChartData15} options={graph_options15}/>
                        </div>
                      </div>
                    </div>
                    <div className="mt-10" key={18} ref={(ref) => (chartRefs.current[18] = ref)}>
                      <h1 className='mb-6'><strong>Huella de carbono sobre valor agregado:</strong> El gráfico muestra el ratio de huella de carbon sobre el valor agregado para diferentes tecnologías, es decir, la huella de carbono de cada sector por cada mil de millones de pesos de aporte al PIB. Esta se mide en kilo toneladas de CO2 equivalente dividido en {coins[values.country]}. Se muestran los datos tanto del año y país de la simulación como del año de la MIP base.</h1>
                      <div className='flex justify-center items-center'>
                        <div style={{width: "59vw"}}>
                          <Bar data={ChartData16} options={graph_options16}/>
                        </div>
                      </div>
                    </div>
                    <div className="mt-10" key={19} ref={(ref) => (chartRefs.current[19] = ref)}>
                      <h1 className='mb-6'><strong>Empleo directo e indirecto:</strong> En el gráfico se observa la cantidad total de empleos directos e indirectos generados para diferentes tecnologías del año y país seleccionados y del año  base de la MIP.</h1>
                      <div className='flex justify-center items-center'>
                        <div style={{width: "59vw"}}>
                          <Bar data={ChartData17} options={graph_options17}/>
                        </div>
                      </div>
                    </div>
                    <div className="mt-10" key={20} ref={(ref) => (chartRefs.current[20] = ref)}>
                      <h1 className='mb-6'><strong>Análisis de remuneraciones:</strong> En la tabla se muestra un análisis de remuneraciones del año y país ingresado y del año de la MIP base. Las columnas de esta tabla corresponden a diversos tipos de tecnologías y las filas corresponden a los indicadores, cada una con diferentes unidades de medida.</h1>
                      <div className='flex justify-center items-center'>
                          <div style={{ maxWidth }}>
                            <DynamicTable title={remunerationanalisis.title} columns={remuneration_analisis_columns} rows={remuneration_analisis_data} cellSize="small"/> 
                          </div>
                        </div>
                    </div>
                    <div className="mt-10" key={21} ref={(ref) => (chartRefs.current[21] = ref)}>
                      <h1 className='mb-6'><strong>Empleo histórico:</strong> El gráfico muestra el avance histórico de los empleos directos generados para diferentes tipos de tecnologías.</h1>
                      <div className='flex justify-center items-center'>
                      <div style={{width: "59vw"}}>
                        <Line data={ChartData18} options={graph_options18}/>
                      </div>
                    </div>
                    </div>
                    <div className="mt-10" key={22} ref={(ref) => (chartRefs.current[22] = ref)}>
                      <h1 className='mb-6'><strong>Demanda final por tecnología:</strong> El gráfico muestra el ratio de número de empleos totales y directos generados divididos por la demanda final para diferentes tipos de tecnologías del año y país seleccionados y del año de la MIP base.</h1>
                      <div className='mt-3 flex justify-center items-center'>
                        <div style={{width: "59vw"}}>
                          <Bar data={ChartData20} options={graph_options20}/>
                        </div>
                      </div>
                    </div>
                  </div>
        )
    }
    else{
        return(
          <div className='mt-16'>
          <h1 className='flex justify-center mb-3'><strong>Contrata la suscripción	premium de EcoKinesis para poder guardar y descargar reportes</strong></h1>
          <div className='flex justify-center'>
              <div className='mb-8' style={{backgroundColor:"#E0E2E2", borderRadius: "20px", maxWidth:"540px", maxHeight:"380px"}}>
                <div className='justify-center align-items-center text-center'>
                  <div className="text-3xl" style={{color: "black"}}><strong>Suscripción Premium</strong></div>
                  <div className="text-sm mt-3" style={{color:"gray"}}><strong>Contrata la suscripción premium de EcoKinesis para poder usar esta sección</strong></div>
                  <button className="text-lg" style={{color:"white",backgroundColor:"#425df9",borderRadius:"20px", minWidth: "120px", maxWidth:"260px", height:"54px", marginTop:"10px", marginBottom: "10px"}} onClick={()=>{navigate("/dashboard/premium")}}>Contratar</button>
                </div>
              </div>
          </div>
      </div>
        )
    }
  }

  //Main page render
  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
        <div className='flex justify-between items-center mb-6'>
            <button onClick={() => {navigate("/dashboard/simulations/main/basic_scenary_results", {state: {values,technologies,tablelike,starplot,summary, components, detailcomposition,impactscontribution, exportimports, ratefosilrenovable, capitaldistribution, remunerationdistribution, fiscalimpact, componentsmip, detailcompositionmip,impactscontributionmip, exportimportsmip, capitaldistributionmip, remunerationdistributionmip, fiscalimpactmip, investment,directemissions, carbonfootprint,directemissionsmip, carbonfootprintmip,inducedemployment, remunerationanalisis, historicalemployees, pibemployment, finaldemand, inducedemploymentmip, remunerationanalisismip, pibemploymentmip, finaldemandmip, coins}})}} style={{color:"white",backgroundColor:"gray",borderRadius:"10px", width:"200px", height: "60px"}}>Volver a mi simulación</button>
      </div>  
      <Header title="Generar reporte simulación escenario personalizado"/>
      <p className='text-gray-500'>En esta sección podrás guardar y descargar los resultados de tu simulación de escenario personalizado. Ingresa el nombre de tu reporte. Si pulsas el botón de descarga, se descargará un PDF con tus resultados. Si pulsas la opción de guardar reporte, este se guardará en la zona de reportes. Un reporte no puede ser guardado si es que ya existe uno en tu cuenta con el mismo nombre. En la zona inferior verás la lista de tablas y gráficos de tu simulación que se almacenarán en tu reporte. Estas opciones sólo están disponible para usuarios que cuenten con la <Link to= "/dashboard/premium" className='text-blue-500'>suscripción premium de EcoKinesis</Link>.</p>
      {Render(user_type)}
      <ModalLoader msg={msg} msgporcentage={msgporcentage} progress={progress} loaderscreen={loaderscreen} isOpen={isModalOpen} closeModal={() => setIsModalOpen(false)}/>
      <LoadingScreen loaderscreen={loadingscreen} isOpen={open} closeModal={() => setOpen(false)}/>
    </div>
  )
}

export default BasicScenaryPDF
