//Import
import { React, useState, useContext, useEffect} from 'react'
import { Header } from '../../../components';
import { Link } from 'react-router-dom';
import { UserContext } from '../../../contexts/UserContext';
import {API_PORT} from '../../../API_PORT'
import Modal from '../../../components/Modal';
import capitalize from '../../../hook/capitalize';
import {Button} from '../../../components';

//Main function
const ResetBD = () => {
const [progress] = useState({started: false, pc: 0});
const [loaderscreen, setLoaderScreen] = useState({started: false});
  const [msg, setMsg] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [values, setValues] = useState({
    "country": "",
    "year": "",
  });
  const [mips, setMips] = useState([])
  const userContext = useContext(UserContext);
  const token = userContext.value.access_token;
  const user_type = userContext.value.user_type;
  const link= API_PORT + "/admin/calibration/RestartDB/"

  //Management Response
const responsenavigate = (response) =>{
  setLoaderScreen(prevState => {
    return {...prevState, started: false}
  })
  alert(response.detail)
}

const handleInput = (event) =>{
    setValues(prev => ({...prev, [event.target.name]:event.target.value}))
};

//Get Mip data endpoint
useEffect(() => {
    fetch(API_PORT+"/admin/database_information/get-avaible-MIPs?token="+token, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
      })
      .then(res => res.json())
      .then(data => {
        setMips(data);
      })
    }, [token]);

var mip_values = mips.MIPbase
var year_data= [{value:"",label:"Selecciona un año"}];
var country_data= [{value:"",label:"Selecciona un país"}];
mip_values?.forEach((element) =>
   {year_data.push({value: element[0], label: element[0]});
   country_data.push({value: element[1], label: capitalize(element[1])})}
)

//Reset DB Endpoint
const handleSubmit = (event) =>{
    event.preventDefault();
    if(values.country !== "" && values.year !== ""){
      if (user_type === "admin"){
        setIsModalOpen(true)
        setMsg("Reseteando la base de datos, espera unos minutos...")
        setLoaderScreen(prevState => {
          return {...prevState, started: false}
        })
        fetch(link + values.country + "/" + values.year + "?token=" + token,  {
            method: 'DELETE',
            headers: {
              'Content-type': 'application/json'
            }
            })
            .then(res => res.json())
            .catch((error) => console.log(error))
            .then((response) => responsenavigate(response))
      }
      else {
        alert("No tienes permiso de administrador para hacer esto")
      }
  }
  else{
    alert("No has seleccionado un año o país.")
  }
}

//Main Render
return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
      <div className='flex justify-between items-center mb-6'>
            <Link to="/dashboard/adminbd" >
              <Button
                color="white"
                bgColor="gray"
                text="Volver"
                borderRadius="10px"
                size="md"
              />
            </Link>
        </div>  
      <Header title="Resetear Base de Datos"/>
      <p className='text-gray-500'>Selecciona un año y país para borrar toda la información de la base de datos de esta.</p>
      <div className='grid grid-cols-2 gap-2 mt-10'>
        <div className='columns-1'>
          <h1 className='mb-3'><strong>Año</strong></h1>
            <label htmlFor='year'></label>
            <select type='string' name='year'
              onChange={handleInput} className='form-select rounded-0 w-60 h-10' style={{color: "grey", border: "1px solid", borderRadius: "8px"}}>
              {year_data.map(option =>(
                <option value={option.value} style={{border:"1px solid", color: "gray", backgroundColor: "white"}}>{option.label}</option>
                ))}
            </select>
        </div>
        <div className='columns-1'>
          <h1 className='mb-3'><strong>País</strong></h1>
          <label htmlFor='country'></label>
            <select type='string' name='country'
              onChange={handleInput} className='form-select rounded-0 w-60 h-10' style={{color: "grey", border: "1px solid", borderRadius: "8px"}}>
              {country_data.map(option =>(
                <option value={option.value} style={{border:"1px solid", color: "gray", backgroundColor: "white"}}>{option.label}</option>
                ))}
            </select>
        </div>
      </div>
      <div className='flex justify-center items-center mt-20'>
        <button style={{color:'white', backgroundColor:"red", borderRadius: "10px"}} className="text-lg p-3" onClick={handleSubmit}>Resetear Base de Datos</button>
      </div>
      <Modal msg={msg} progress={progress} loaderscreen={loaderscreen} isOpen={isModalOpen} closeModal={() => setIsModalOpen(false)}/>
    </div>
  )
}
export default ResetBD