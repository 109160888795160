//Imports
import { React, useState, useContext} from 'react'
import { Button } from '../../../components';
import { Header } from '../../../components';
import { Link, useNavigate } from 'react-router-dom';
import user_icon from '../../../assets/person.png'
import { API_PORT } from '../../../API_PORT';
import { useLocation } from 'react-router-dom';
import { UserContext } from '../../../contexts/UserContext';
import LoadingScreen from '../../../components/LoadingScreen';

//Main Function
const ActivateUser = () => {
  const navigate = useNavigate();
  const location = useLocation()
  const [loadingscreen, setLoadingScreen] = useState({started: false});
  const [open, setOpen] = useState(false);
  const userContext = useContext(UserContext);
  const token = userContext.value.access_token;
  const [values, setValues] = useState({
    "IdentifierType": "id",
    "Identifier": "ID de usuario",
    "is_active_request": true,
  });

//Response Management
const responsenavigate = (response) =>{
  if(response.detail === "Usuario modificado exitosamente"){
    if(values.is_active_request === true){
      alert("Se ha habilitado al usuario exitosamente")
      navigate("/dashboard/adminuser")
    }
    else{
      alert("Se ha deshabilitado al usuario exitosamente")
      navigate("/dashboard/adminuser")
    }
  }
  else{
    console.log(values)
    alert(response.detail)
  }
  setOpen(false);
  setLoadingScreen(prevState => {return {...prevState, started: false}});
}

const handleInput = (event) =>{
    setValues(prev => ({...prev, [event.target.name]:event.target.value}))
};

//Activate User Endpoint
const handleSubmit = (event) =>{
    event.preventDefault();
    if(values.Identifier !== "ID de usuario" && values.Identifier !== ""){
      setOpen(true);
      setLoadingScreen(prevState => {return {...prevState, started: true}});
        fetch(API_PORT + '/admin/users_accounts/admin/activate_user/?token='+token, {
            method: 'PATCH',
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify(values)
            }).then((res => res.json()))
            .catch((error) => console.log(error))
            .then((response) => responsenavigate(response)
            );
    }
    else{
      alert("No has ingresado una ID")
    }
}

//Update user
if(location.state?.user){
  values.Identifier= location.state.user.id
}

//Main Render
  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
      <div className='flex justify-between items-center mb-10'>
            <Link to="/dashboard/adminuser" >
              <Button
                color="white"
                bgColor="gray"
                text="Volver"
                borderRadius="10px"
                size="md"
              />
            </Link>
        </div>  
      <Header title="Habilitar/Deshabilitar usuario"/>
      <h1>Ingresa una ID de usuario valida y luego selecciona el nuevo estado para habilitar o deshabilitar al usuario. Si un usuario se encuentra deshabilitado, no podrá acceder a la plataforma. Sólo un usuario administrador habilitado puede habilitar o deshabilitar a otros usuarios.</h1>
      <form action = "" onSubmit={handleSubmit}>
                <div className='inputs'>
                    <div className='input'>
                        <img src={user_icon} alt='' />
                        <label htmlFor='Identifier'></label>
                        <input type='string' placeholder={values.Identifier} name='Identifier'
                        onChange={handleInput} className='form-control rounded-0'/>
                    </div>
                    <div className='input'>
                        <img src={user_icon} alt='' />
                        <label htmlFor='is_active_request'></label>
                        <select type='bool' placeholder='Estado' name='is_active_request'
                        onChange={handleInput} className='form-select rounded-0' style={{backgroundColor: "transparent", borderColor: "rgba(0, 0, 0, 0.1)"}}>
                          <option value={true}>Habilitar usuario</option>
                          <option value={false}>Deshabilitar usuario</option>
                        </select>
                    </div>
                </div>
                <div className='flex justify-center items-center gap-20 flex-wrap'>
                  <button className="submit bg-blue" type="submit" style={{width: "200px"}}>Actualizar estado del usuario</button>
                </div>     
        </form>
        <LoadingScreen loaderscreen={loadingscreen} isOpen={open} closeModal={() => setOpen(false)}/>
    </div>
  )
}

export default ActivateUser