//Imports
import {React, useEffect, useState, useContext} from 'react'
import { UserContext } from '../../../contexts/UserContext';
import { Header } from '../../../components';
import { Button } from '../../../components';
import MUIDataTable, {TableToolbar} from "mui-datatables";
import { Link } from 'react-router-dom';
import { API_PORT } from '../../../API_PORT';
import edit from "../../../assets/edit_user.svg"
import deletebutton from "../../../assets/delete.svg"
import star from "../../../assets/star.svg"
import block from "../../../assets/block.svg"
import {useNavigate } from 'react-router-dom';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import capitalize from '../../../hook/capitalize';
import ProfesionCodeText from './ProfesionCodeText';
import ProfesionLevelText from './ProfesionLevelText';
import LoadingScreen from '../../../components/LoadingScreen';
import { useStateContext } from '../../../contexts/ContextProvider';
import "../../../hook/Tables.css"
import { ThemeProvider } from '@mui/material/styles';
import getMuiTheme2 from '../../../hook/getMuiTheme2';

//Main Function
const AdminUser = () => {
  const {activeMenu} = useStateContext();
  const maxWidth = activeMenu ? '1080px' : '100%';
  const navigate = useNavigate()
  const [users, setUsers] = useState([])
  const userContext = useContext(UserContext);
  const token = userContext.value.access_token;
  const [loadingscreen, setLoadingScreen] = useState({started: true});
  const [open, setOpen] = useState(true);
  const users_columns = ["Nombre","Apellido","Nombre de usuario","Email","País","Institución","Área ocupacional","Profesión","Tipo de usuario", "ID","Estado","Acciones"]
  var users_data = []
  const options={
    rowsPerPage: 10,
    rowsPerPageOptions: [1,2,3,4,5,6,7,8,9,10,15],
    selectableRows: "none",
    tableBodyMaxHeight: "354px",
    print: "false",
    download: "false",
    filter: "false",
    viewColumns: "false",
    responsive: "vertical",
    textLabels: {
      toolbar: {
        search: "Buscar usuario",  // Cambiar el texto del tooltip de "Search" a "Buscar fila"
      },
      pagination: {
        rowsPerPage: "Filas por página:",  // Cambiar el texto de "Rows per page" a "Filas por página"
        next: "Siguiente página",
        previous: "Página anterior",
        displayRows: "de",
      },
      body: {
        noMatch: "No hay usuarios disponibles", // Cambia este texto a lo que necesites
      },
    },
  }

  //Activate Text
  function activeUser(active){
    if(active){
      return(<div style={{backgroundColor: "green",color:"white", borderRadius:"4px", textAlign: 'center', padding: "3px"}}>Habilitado</div>)
    }
    else{
      return(<div style={{backgroundColor: "red",color:"white", borderRadius:"4px", textAlign: 'center', padding: "3px"}}>Deshabilitado</div>)
    }
  }

  //Role color text
  function userRole(role){
    if(role === "basic"){
      return(<div style={{backgroundColor: "gray",color:"white", borderRadius:"4px", textAlign: 'center', padding: "3px"}}>Básico</div>)
    }
    else if (role === "premium"){
      return(<div style={{backgroundColor: "orange",color:"white", borderRadius:"4px", textAlign: 'center', padding: "3px"}}>Premium</div>)
    }
    else if (role === "admin"){
      return(<div style={{backgroundColor: "#dcdc01",color:"white", borderRadius:"4px", textAlign: 'center', padding: "3px"}}>Admin</div>)
    }
    else{
      return(role)
    }
  }

  //Use Actions Render
  function usersActions(user){
    return(
    <div className='flex gap-2 w-32'>
      <TooltipComponent content="Editar usuario"><button onClick={() => {navigate("/dashboard/adminuser/modifyuser",{state: {user}})}}><img src={edit} className='w-6 h-6' alt="Editar usuario"></img></button></TooltipComponent>
      <TooltipComponent content="Habilitar/Deshabilitar usuario"><button onClick={() => {navigate("/dashboard/adminuser/activateuser",{state: {user}})}}><img src={block} className='w-6 h-6' alt="Habilitar/Deshabilitar usuario"></img></button></TooltipComponent>
      <TooltipComponent content="Cambiar rol usuario"><button onClick={() => {navigate("/dashboard/adminuser/change_user_status",{state: {user}})}}><img src={star} className='w-6 h-6' alt="Cambiar rol usuario"></img></button></TooltipComponent>
      <TooltipComponent content="Eliminar usuario"><button onClick={() => {navigate("/dashboard/adminuser/deleteuser",{state: {user}})}}><img src={deletebutton} className='w-6 h-6' alt="Eliminar usuario"></img></button></TooltipComponent>
    </div>)
  }

  //Obtainer User Data
  useEffect(() => {
    fetch(API_PORT+'/admin/users_accounts/get_user_database?token='+token, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json'
      },
      body: {}
      })
      .then(res => res.json())
      .then(data => {
        setUsers(data);
        setOpen(false);
        setLoadingScreen(prevState => {return {...prevState, started: false}});
      }).catch(error => {
        console.error("Error fetching data:", error); // Para ver qué sucede
        alert("No se han podido obtener los datos. Recarga la página o inténtalo más tarde.")
        setOpen(false);
        setLoadingScreen(prevState => ({ ...prevState, started: false }));
      });
    }, [token]);

  users?.forEach((user) => {
    users_data.push([user.first_name, user.last_name, user.username, user.email, capitalize(user.country), user.institution_name, ProfesionLevelText(user.profesion_level), ProfesionCodeText(user.profesion_code), userRole(user.user_type), user.id, activeUser(user.is_active), usersActions(user)])
  })

  //Function to fix columns design
  function columns_convert(columns) { 
    return columns.map((column, index) => ({
      name: column,
      label: column,
      options: {
        // Estilos del encabezado con bordes y sticky aplicado a la primera columna
        customHeadRender: (columnMeta) => {
          return (
            <th
              key={columnMeta.index}
              className="custom-header custom-cell-spacing"
              style={{
                background: "#b0b0b0",  // Color de fondo gris oscuro para el encabezado
                color: "white",  // Texto en blanco para el encabezado
                fontWeight: "bold",  // Texto en negrita
                textAlign: 'center',  // Centrar el contenido en el encabezado
                position: 'sticky',  // Sticky para todas las celdas del encabezado
                top: 0,  // Fijar la fila del encabezado en la parte superior
                zIndex: index === 0 ? 102 : 101,  // Asegura que el encabezado esté por encima del contenido
                height: '50px',  // Ajustar el alto del encabezado
                verticalAlign: 'middle',  // Alinear verticalmente al centro
              }}
            >
              {columnMeta.label}
            </th>
          );
        },
        // Estilos para las celdas del cuerpo de la tabla
        setCellProps: () => ({
          style: {
            textAlign: 'center',  // Centrar el contenido horizontalmente
            verticalAlign: 'middle',  // Centrar el contenido verticalmente
            borderRight: '1px solid #9c9c9c',  // Borde gris oscuro entre columnas
            borderBottom: '1px solid #9c9c9c',  // Borde gris oscuro debajo de las filas
            backgroundClip: 'padding-box', // Asegura que el fondo no se solape con el borde
            boxSizing: 'border-box',  // Asegura que los bordes y el padding se incluyan en el tamaño de la celda
          },
        }),
      }
    }));
  }
  
  //Main Render
  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
      <Header title="Gestión de usuarios"/>
      <div className='flex justify-center items-center mt-6'>
          <p className='font-bold text-gray-400 mb-3'>Opciones</p>
      </div>
      <div className='flex justify-center items-center gap-3 mb-10'>
          <div className='max-w-48'>
              <Link to="/dashboard/adminuser/createuser">
                <Button className
                  color="white"
                  bgColor="#425df9"
                  text="Crear usuario"
                  borderRadius="10px"
                  size="md"
                />
              </Link>
            </div>
          <div className='max-w-48'>
            <Link to="/dashboard/adminuser/modifyuser">
              <Button className
                color="white"
                bgColor="#425df9"
                text="Modificar usuario"
                borderRadius="10px"
                size="md"
              />
            </Link>
          </div>
          <div className='max-w-60'>
            <Link to="/dashboard/adminuser/activateuser">
              <Button className
                color="white"
                bgColor="#425df9"
                text="Habilitar/Deshabilitar usuario"
                borderRadius="10px"
                size="md"
              />
            </Link>
          </div>
          <div className='max-w-48'>
            <Link to="/dashboard/adminuser/change_user_status">
              <Button className
                color="white"
                bgColor="#425df9"
                text="Cambiar rol usuario"
                borderRadius="10px"
                size="md"
              />
            </Link>
          </div>
          <div className='max-w-48'>
            <Link to="/dashboard/adminuser/deleteuser">
              <Button className
                color="white"
                bgColor="red"
                text="Eliminar usuario"
                borderRadius="10px"
                size="md"
              />
            </Link>
          </div>
          <div className='max-w-48'>
            <Link to="/dashboard/adminuser/admin_reports">
              <Button className
                color="white"
                bgColor="#425df9"
                text="Lista de reportes"
                borderRadius="10px"
                size="md"
              />
            </Link>
          </div>
        </div>
      <div className='flex justify-center items-center mt-10'>
          <p className='font-bold text-gray-400 mb-3'>Lista de usuarios</p>
      </div>
      <div className='flex justify-center items-center'>
        <div style={{ maxWidth }} className='custom-table-container'><ThemeProvider theme={getMuiTheme2()}><MUIDataTable
        data={users_data.sort((a, b) => a[9] - b[9])}
        columns={columns_convert(users_columns)}
        options={options}
        components={{
          TableToolbar: (props) => (
            <div
              style={{
                backgroundColor: '#b0b0b0',
              }}
            >
              {/* Mantener los íconos del toolbar predeterminado, sin duplicar el título */}
              <TableToolbar {...props} title={null} />
            </div>
          ),
        }}
        ></MUIDataTable></ThemeProvider></div>
      </div>
      <LoadingScreen loaderscreen={loadingscreen} isOpen={open} closeModal={() => setOpen(false)}/>
    </div>
  )
}

export default AdminUser