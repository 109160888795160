//Imports
import React, {useState, useContext} from 'react'
import './LoginSignup.css'
import email_icon from '../../assets/email.png'
import password_icon from '../../assets/password.png'
import logo from '../../assets/white_logo.png'
import {API_PORT} from "../../API_PORT"
import { Link, useNavigate } from 'react-router-dom'
import { LoginValidation2 } from "./Validation"
import LoadingScreen from '../../components/LoadingScreen'
import { UserContext } from '../../contexts/UserContext'
import { IoEye, IoEyeOff } from 'react-icons/io5'; // Importa los íconos

//LoginPage Code
const LoginPage = () => {
    //Loading Screen items
    const [loadingscreen, setLoadingScreen] = useState({started: false});
    const [open, setOpen] = useState(false);

    //Login structure values
    const [values, setValues] = useState({
        username: '',
        password: '',
        grant_type: "",
        scope: "",
        client_id: "",
        client_secret: "",
    })

    //Elements
    const navigate = useNavigate();
    const [errors, setErrors] = useState({})
    const userContext = useContext(UserContext);
    const [showPassword, setShowPassword] = useState(false); // Estado para visibilidad de contraseña

    //Password Visibility
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    //Change values function
    const handleInput = (event) =>{
        setValues(prev => ({...prev, [event.target.name]: event.target.value}))
    }

    //Login endpoint convert
    function towww(values){
        return("gran_type=" + values.grant_type + "&username=" + values.username + "&password=" + values.password + "&scope=" + values.scope + "&client_id=" + values.client_id + "&client_secret=" + values.client_secret)
    }

    //Login function
    const handleSubmit =(event) =>{
        setErrors(LoginValidation2(values));
        event.preventDefault();
        if(errors.username === "" && errors.password === "" && values.password !== ""){
                setOpen(true);
                setLoadingScreen(prevState => {return {...prevState, started: true}});
                fetch(API_PORT + '/admin/users_accounts/token-and-photo', {
                method: 'POST',
                headers: {
                    'Content-type': 'application/x-www-form-urlencoded'
                },
                body: towww(values)
                }).then((res => res.json()))
                .catch(error => {
                    // Manejo del error de conexión u otros errores
                    console.error('Error al realizar la solicitud:', error);
                    alert("No se pudo iniciar sesión debido a un error de conexión. Por favor, intente de nuevo más tarde.");
                    setOpen(false);
                    setLoadingScreen(prevState => ({ ...prevState, started: false }));
                })
                .then((response) => {
                    if(response.token_type === "bearer"){
                        if (response.is_active === false){
                            //Caso de usuario deshabilitado
                            alert("Su usuario se encuentra deshabilitado. Solicite que su cuenta sea habilitada a un administrador para poder acceder a EcoKinesis.")
                            setOpen(false);
                            setLoadingScreen(prevState => {return {...prevState, started: false}});
                        }
                        else{
                            //Caso de usuario de ingreso correcto
                            userContext.setValue({username: response.username, logged: true, access_token: response.access_token, user_type: response.user_type, user_photo: response.photo_URL});
                            alert("Has iniciado sesión correctamente a EcoKinesis")
                            navigate("/dashboard/home");
                        }
                    }
                    else{
                        if(response.detail === "No se pudo validar el usuario"){
                            //Caso de usuario con datos no validos
                            alert("Sus datos ingresados son incorrectos")
                        }
                        else{
                            alert(response.detail);
                        }
                        setOpen(false);
                        setLoadingScreen(prevState => {return {...prevState, started: false}});
                    }
                });
        }
    }

//Main Render
  return (
    <div className=''>
        <div className='container2 text-white'>
            <h1 style={{position:"absolute", left:"73%", transform: "translateX(-50%);"}} title='Volver a ecokinesis.cl'><Link to="https://ecokinesis.cl/"><strong>Volver al inicio</strong></Link></h1>
        </div>
        <div className='container bg-login'>
            <div className='header'>
                <img src={logo} className="logo"alt=''></img>
                <div className='text'>Bienvenid@ nuevamente</div>
                <div className='subtext white'>Ingresa tus datos para iniciar sesión en EcoKinesis</div>
            </div>
            <div className='container3'>
                <form action = "" onSubmit={handleSubmit}>
                    <div className='inputs'>
                    <div className='input'>
                        <img src={email_icon} alt='' />
                        <label htmlFor='username'></label>
                        <input type='email' placeholder='Email' name='username'
                        onChange={handleInput} className='form-control rounded-0'></input>
                        {errors.username && <span className='text-danger' style={{fontSize: "10px"}}>{errors.username}</span>}
                    </div>
                    <div className='input'>
                        <img src={password_icon} alt='' />
                        <label htmlFor='password'></label>
                        <input type={showPassword ? 'text' : 'password'} 
                        placeholder='Contraseña' name='password'
                        onChange={handleInput} className='form-control rounded-0'/>
                        {errors.password && <span className='text-danger' style={{fontSize: "8px", marginRight: "4px"}}>{errors.password}</span>}
                        <button 
                            type='button' 
                            onClick={togglePasswordVisibility}
                            title={showPassword ? 'Ocultar contraseña' : 'Mostrar contraseña'}
                            style={{ background: 'none', border: 'none', cursor: 'pointer' }} 
                        >
                            {showPassword ? <IoEyeOff size={20} /> : <IoEye size={20} />}
                        </button>
                    </div>
                    </div>
                    <div className='container4'>
                        <button className="submit" type="submit" title='Pulse doble click para iniciar sesión en EcoKinesis'>Iniciar Sesión</button> 
                        <button className="submit gray" onClick={() => navigate("/register")}>Registrarse</button> 
                    </div>
                </form> 
                <div className="forgot-password">¿No recuerdas tu contraseña? <Link to= "/recover"><span>Click aquí</span></Link></div>
                <div className='underline'></div>
            </div>
        </div>
        <LoadingScreen loaderscreen={loadingscreen} isOpen={open} closeModal={() => setOpen(false)}/>
    </div>
  )
}

export default LoginPage;