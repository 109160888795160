//Imports
import { API_PORT } from '../API_PORT';

//Function to Update the actual Token. Need email and password
const UpdateToken = async (username, password) => {
    const values = {
        grant_type: "",
        scope: "",
        client_id: "",
        client_secret: "",
    };

    //Convert to endpoint format
    const towww = (values, username, password) => {
        return `grant_type=${values.grant_type}&username=${username}&password=${password}&scope=${values.scope}&client_id=${values.client_id}&client_secret=${values.client_secret}`;
    };

    try {
        const response = await fetch(`${API_PORT}/admin/users_accounts/token-and-photo`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: towww(values, username, password),
        });

        if (!response.ok) {
            throw new Error('Error al obtener el token');
        }

        const data = await response.json();
        return data; //Return the endpoint data

    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export default UpdateToken;
