//Imports
import { React, useState, useContext} from 'react'
import { Button } from '../../../components';
import { Header } from '../../../components';
import { Link} from 'react-router-dom';
import user_icon from '../../../assets/person.png'
import { API_PORT } from '../../../API_PORT';
import { useLocation } from 'react-router-dom';
import { UserContext } from '../../../contexts/UserContext';
import LoadingScreen from '../../../components/LoadingScreen';
import { useNavigate } from 'react-router-dom';

//Main Function
const ChangeUserStatus = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [loadingscreen, setLoadingScreen] = useState({started: false});
    const [open, setOpen] = useState(false);
    const userContext = useContext(UserContext);
    const token = userContext.value.access_token;
    const [values, setValues] = useState({
      "IdentifierType": "id",
      "Identifier": "ID de usuario",
      "new_user_type": "basic",
    });
  
  //Response management
  const responsenavigate = (response) =>{
    if(response.detail === "Usuario modificado exitosamente"){
        alert("Se ha cambiado el rol del usuario exitosamente")
        navigate("/dashboard/adminuser")
    }
    else{
      console.log(values)
      alert(response.detail)
    }
    setOpen(false);
    setLoadingScreen(prevState => {return {...prevState, started: false}});
  }
  
  const handleInput = (event) =>{
      setValues(prev => ({...prev, [event.target.name]:event.target.value}))
  };
  
  //Change Status Function
  const handleSubmit = (event) =>{
      event.preventDefault();
      if(values.Identifier !== "ID de usuario" && values.Identifier !== ""){
        setOpen(true);
        setLoadingScreen(prevState => {return {...prevState, started: true}});
          fetch(API_PORT + '/admin/users_accounts/admin/upgrade_downgrade_user/?token='+token, {
              method: 'PATCH',
              headers: {
                  'Content-type': 'application/json'
              },
              body: JSON.stringify(values)
              }).then((res => res.json()))
              .catch((error) => console.log(error))
              .then((response) => responsenavigate(response)
              );
      }
      else{
        alert("No has ingresado una ID")
      }
  }
  
  //Update values
  if(location.state?.user){
    values.Identifier= location.state.user.id
  }
  
  //Main Render
    return (
      <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
          <div className='flex justify-between items-center mb-10'>
              <Link to="/dashboard/adminuser" >
                <Button
                  color="white"
                  bgColor="gray"
                  text="Volver"
                  borderRadius="10px"
                  size="md"
                />
              </Link>
          </div>  
        <Header title="Cambiar rol de usuario"/>
        <h1>Ingresa una ID de usuario valida y luego selecciona un nuevo rol en la plataforma para este. Un usuario básico tiene acceso a las funciones principales de la plataforma. Un usuario premium tiene acceso a elementos extras como las simulaciones Leontieff y de operación simulada, acceso a reportes, entre otras opciones. Un usuario administrador tiene acceso a la gestión de usuarios y de base de datos. Sólo un usuario administrador habilitado puede cambiar los roles otros usuarios.</h1>
        <form action = "" onSubmit={handleSubmit}>
                  <div className='inputs'>
                      <div className='input'>
                          <img src={user_icon} alt='' />
                          <label htmlFor='Identifier'></label>
                          <input type='string' placeholder={values.Identifier} name='Identifier'
                          onChange={handleInput} className='form-control rounded-0'/>
                      </div>
                      <div className='input'>
                          <img src={user_icon} alt='' />
                          <label htmlFor='new_user_type'></label>
                          <select type='bool' placeholder='Estado' name='new_user_type'
                          onChange={handleInput} className='form-select rounded-0' style={{backgroundColor: "transparent", borderColor: "rgba(0, 0, 0, 0.1)"}}>
                            <option value="basic">Usuario básico</option>
                            <option value="premium">Usuario premium</option>
                            <option value="admin">Administrador</option>
                          </select>
                      </div>
                  </div>
                  <div className='flex justify-center items-center flex-wrap'>
                    <button className="submit bg-blue" type="submit" style={{width: "200px"}}>Actualizar rol del usuario</button>
                  </div>     
          </form>
          <LoadingScreen loaderscreen={loadingscreen} isOpen={open} closeModal={() => setOpen(false)}/>
      </div>
    )
}

export default ChangeUserStatus