//Imports
import {React, useState, useContext} from 'react'
import { UserContext } from '../../../contexts/UserContext';
import { useLocation, useNavigate } from 'react-router-dom';
import MUIDataTable, {TableToolbar} from "mui-datatables";
import { Bar, Line, Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, Title, BarElement, CategoryScale, LinearScale, PointElement, LineElement, Filler, Tooltip, Legend, ArcElement} from "chart.js";
import capitalize from '../../../hook/capitalize'
import HorizontalTables from '../../../hook/HorizontalTables';
import graphColors from '../../../hook/graphColors';
import FullScreen from '../../../components/FullScreen';
import filedownload from "../../../assets/file.svg"
import download from "../../../assets/download_black.svg"
import expand from "../../../assets/expand.svg"
import edit from "../../../assets/edit.svg"
import save from "../../../assets/save_black.svg"
import { CSVLink } from 'react-csv';
import { useStateContext } from '../../../contexts/ContextProvider';
import '../../../hook/Tables.css'
import { ThemeProvider } from '@mui/material/styles';
import getMuiTheme from '../../../hook/getMuiTheme';
ChartJS.register(Title, BarElement, CategoryScale, LinearScale, PointElement, LineElement, ArcElement, Filler, Tooltip, Legend)

// Registrar el plugin personalizado para dibujar múltiples líneas en el centro del gráfico
ChartJS.register({
  id: 'centerText',
  beforeDraw(chart) {
    const { width, height } = chart;
    const ctx = chart.ctx;

    // Verificar si las opciones de centerText están configuradas correctamente
    const centerTextPluginOptions = chart.config.options.plugins.centerText;
    if (centerTextPluginOptions && centerTextPluginOptions.display) {
      const title = centerTextPluginOptions.title || '';  // Default empty string if undefined
      const value = centerTextPluginOptions.value || '';  // Default empty string if undefined

      // Calcular las posiciones del centro
      const centerX = width / 2;
      const centerY = height / 2;

      // Dibujar la primera línea (título)
      ctx.save();
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.font = '18px Arial';  // Ajusta el tamaño y fuente del título
      ctx.fillStyle = 'black';  // Ajusta el color del texto
      if (title) {
        ctx.fillText(title, centerX, centerY + 55);  // Primera línea en el centro, ligeramente arriba
      }

      // Dibujar la segunda línea (valor)
      ctx.font = '14px Arial';  // Ajusta el tamaño de la fuente del valor
      if (value) {
        ctx.fillText(value, centerX, centerY + 85);  // Segunda línea, ligeramente abajo
      }

      ctx.restore();
    }
  }
});

//Main function
const ExpandedMIPResults = () => {
  const {activeMenu} = useStateContext();
  const maxWidth = activeMenu ? '1080px' : '100%';
  const location = useLocation();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const userContext = useContext(UserContext);
  const user_type= userContext.value.user_type;

  //CSV button render
  function CSVRender(user){
    if (user === "premium" || user === "admin"){
      return(<div className='justify-center items-center text-center' style={{fontSize: "10px"}}><button title='Pulsa para descargar la tabla o gráfico actual en formato CSV. Esta opción sólo está disponible para usuarios premium'><CSVLink data={CSVData()} filename={CSVTitle()}><img src={filedownload}  className='w-10 h-10' alt=''/>Convertir a CSV</CSVLink></button></div>)
    }
    else{
      return(<div className='justify-center items-center text-center ' style={{fontSize: "10px"}}><button title='Pulsa para descargar la tabla o gráfico actual en formato CSV. Esta opción sólo está disponible para usuarios premium' onClick={()=>{alert("La opción de descargar CSV sólo está disponible para usuarios premium. Adquiere la suscripción premium de EcoKinesis para poder usar esta función")}}><img src={filedownload} className='w-10 h-10' alt=''/>Convertir a CSV</button></div>)
    }
  }

  //Graph options for users
  const options1= {
    rowsPerPage: 10,
    rowsPerPageOptions: [1,2,3,4,5,6,7,8,9,10,15,20,25,30],
    selectableRows: "none",
    tableBodyMaxHeight: "330px",
    print: "false",
    download: "false",
    filter: "false",
    responsive: "standard",
    fixedHeader: true,
    fixedSelectColumn: true,
    rowHover: false,
    textLabels: {
      toolbar: {
        search: "Buscar",  // Cambiar el texto del tooltip de "Search" a "Buscar fila"
        viewColumns: "Mostrar/Ocultar columnas",
        showColumns: "A"
      },
      pagination: {
        rowsPerPage: "Filas por página:",  // Cambiar el texto de "Rows per page" a "Filas por página"
        next: "Siguiente página",
        previous: "Página anterior",
        displayRows: "de",
      },
      body: {
        noMatch: "No hay datos disponibles", // Cambia este texto a lo que necesites
      },
      viewColumns: {
        title: "Mostrar/Ocultar columnas",  // Cambiar el texto de "Show Columns"
        titleAria: "Mostrar/Ocultar columnas",  // Cambiar el texto accesible de "Show/Hide Columns"
      },
    }
  }

  const options2= {
    rowsPerPage: 11,
    rowsPerPageOptions: [1,2,3,4,5,6,7,8,9,10,11],
    selectableRows: "none",
    tableBodyMaxHeight: "262px",
    print: "false",
    download: "false",
    filter: "false",
    responsive: "standard",
    fixedHeader: true,
    fixedSelectColumn: true,
    rowHover: false,
    textLabels: {
      toolbar: {
        search: "Buscar",  // Cambiar el texto del tooltip de "Search" a "Buscar fila"
        viewColumns: "Mostrar/Ocultar columnas",
        showColumns: "A"
      },
      pagination: {
        rowsPerPage: "Filas por página:",  // Cambiar el texto de "Rows per page" a "Filas por página"
        next: "Siguiente página",
        previous: "Página anterior",
        displayRows: "de",
      },
      body: {
        noMatch: "No hay datos disponibles", // Cambia este texto a lo que necesites
      },
      viewColumns: {
        title: "Mostrar/Ocultar columnas",  // Cambiar el texto de "Show Columns"
        titleAria: "Mostrar/Ocultar columnas",  // Cambiar el texto accesible de "Show/Hide Columns"
      },
    }
  }

  const options3= {
    rowsPerPage: 1,
      rowsPerPageOptions: [1],
      selectableRows: "none",
      tableBodyMaxHeight: "400px",
    print: "false",
    download: "false",
    filter: "false",
    responsive: "standard",
    fixedHeader: true,
    fixedSelectColumn: true,
    rowHover: false,
    textLabels: {
      toolbar: {
        search: "Buscar",  // Cambiar el texto del tooltip de "Search" a "Buscar fila"
        viewColumns: "Mostrar/Ocultar columnas",
        showColumns: "A"
      },
      pagination: {
        rowsPerPage: "Filas por página:",  // Cambiar el texto de "Rows per page" a "Filas por página"
        next: "Siguiente página",
        previous: "Página anterior",
        displayRows: "de",
      },
      body: {
        noMatch: "No hay datos disponibles", // Cambia este texto a lo que necesites
      },
      viewColumns: {
        title: "Mostrar/Ocultar columnas",  // Cambiar el texto de "Show Columns"
        titleAria: "Mostrar/Ocultar columnas",  // Cambiar el texto accesible de "Show/Hide Columns"
      },
    }
  }

  const options4= {
    rowsPerPage: 6,
        rowsPerPageOptions: [1,2,3,4,5,6],
        selectableRows: "none",
        tableBodyMaxHeight: "278px",
    print: "false",
    download: "false",
    filter: "false",
    responsive: "standard",
    fixedHeader: true,
    fixedSelectColumn: true,
    rowHover: false,
    textLabels: {
      toolbar: {
        search: "Buscar",  // Cambiar el texto del tooltip de "Search" a "Buscar fila"
        viewColumns: "Mostrar/Ocultar columnas",
        showColumns: "A"
      },
      pagination: {
        rowsPerPage: "Filas por página:",  // Cambiar el texto de "Rows per page" a "Filas por página"
        next: "Siguiente página",
        previous: "Página anterior",
        displayRows: "de",
      },
      body: {
        noMatch: "No hay datos disponibles", // Cambia este texto a lo que necesites
      },
      viewColumns: {
        title: "Mostrar/Ocultar columnas",  // Cambiar el texto de "Show Columns"
        titleAria: "Mostrar/Ocultar columnas",  // Cambiar el texto accesible de "Show/Hide Columns"
      },
    }
  }

  const options5= {
    rowsPerPage: 10,
    rowsPerPageOptions: [1,2,3,4,5,6,7,8,9,10,15,20,25,30],
    selectableRows: "none",
    tableBodyMaxHeight: "310px",
    print: "false",
    download: "false",
    filter: "false",
    responsive: "standard",
    fixedHeader: true,
    fixedSelectColumn: true,
    rowHover: false,
    textLabels: {
      toolbar: {
        search: "Buscar",  // Cambiar el texto del tooltip de "Search" a "Buscar fila"
        viewColumns: "Mostrar/Ocultar columnas",
        showColumns: "A"
      },
      pagination: {
        rowsPerPage: "Filas por página:",  // Cambiar el texto de "Rows per page" a "Filas por página"
        next: "Siguiente página",
        previous: "Página anterior",
        displayRows: "de",
      },
      body: {
        noMatch: "No hay datos disponibles", // Cambia este texto a lo que necesites
      },
      viewColumns: {
        title: "Mostrar/Ocultar columnas",  // Cambiar el texto de "Show Columns"
        titleAria: "Mostrar/Ocultar columnas",  // Cambiar el texto accesible de "Show/Hide Columns"
      },
    }
  }
  

  //Indicators and graphic-table options
  const [appState, changeState] = useState({
    activeObject: null,
    activeGraphic: null,
    objects: [1,2,3,4],
    graphicObjects: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]
  })

  //Detect buttons of results functions
  function toggleActive(index,graphicindex){
    if (index === 0 || index === 1 || index === 2 || index===3){
      changeState({...appState, activeObject: appState.objects[index]})
    }
    if (graphicindex === 0 || graphicindex === 1 || graphicindex === 2 || graphicindex === 3 || graphicindex === 4 || graphicindex === 5 || graphicindex === 6 || graphicindex === 7 || graphicindex === 8 || graphicindex === 9 || graphicindex === 10 || graphicindex === 11 || graphicindex === 12 || graphicindex === 13 || graphicindex === 14 || graphicindex === 15 || graphicindex === 16 || graphicindex === 17 || graphicindex === 18 || graphicindex === 19){
      changeState({...appState, activeGraphic: appState.graphicObjects[graphicindex]})
    }
    
  }
  function toggleActiveStyles(index){
    if(appState.objects[index] === appState.activeObject){
      return "box-active"
    }
    else {
      return "box-inactive"
    }
  }

  function toggleActiveStylesGraph(graphicindex){
    if(appState.graphicObjects[graphicindex] === appState.activeGraphic){
      return "box-active"
    }
    else {
      return "box-inactive"
    }
  }

  //Define const of location
  const values = location.state.values
  const calibratedmip = location.state.calibratedmip
  const inverted = location.state.inverted
  const calibratedmipcomplete = location.state.calibratedmipcomplete
  const summary = location.state.summary
  const components = location.state.components
  const detailcomposition = location.state.detailcomposition
  const impactscontribution = location.state.impactscontribution
  const exportimports = location.state.exportimports
  const capitaldistribution = location.state.capitaldistribution
  const remunerationdistribution = location.state.remunerationdistribution
  const fiscalimpact = location.state.fiscalimpact
  const productionmultipliers = location.state.productionmultipliers
  const gwhcontribution = location.state.gwhcontribution
  const pibmultipliers = location.state.pibmultipliers
  const rentmultipliers = location.state.rentmultipliers
  const inversionmultipliers = location.state.inversionmultipliers
  const techimportance = location.state.techimportance
  const directemissions = location.state.directemissions
  const carbonfootprint = location.state.carbonfootprint
  const carbonsource = location.state.carbonsource
  const carbonessh2 = location.state.carbonessh2
  const carbonfinaldemand = location.state.carbonfinaldemand
  const carbonfinaldemandessh2 = location.state.carbonfinaldemandessh2
  const inducedemployment= location.state.inducedemployment
  const remunerationanalisis = location.state.remunerationanalisis
  const historicalemployees = location.state.historicalemployees
  const pibemployment= location.state.pibemployment
  const finaldemand = location.state.finaldemand
  const employmentgwh= location.state.employmentgwh
  const labormultipliers = location.state.labormultipliers
  const ratefosilrenovable = location.state.ratefosilrenovable
  const coins = location.state.coins
  var array_data;
  var column_array;

  //Colums and data
  var resumed_mip_columns = [""]
  var resumed_mip_data = []
  var inverted_mip_columns = [""]
  var inverted_mip_data = []
  var complete_mip_columns = [""]
  var complete_mip_data = []
  var summary_columns = [""]
  var summary_data = []
  var aggregate_value_columns = []
  var aggregate_value_data = []
  var pib_columns = []
  var pib_data = []

  var direct_emissions_columns = []
  var direct_emissions_data = []
  var carbon_footprint_columns = []
  var carbon_footprint_data = []
  var carbon_source_columns = [""]
  var carbon_source_data = []
  var carbon_essh2_columns = []
  var carbon_essh2_data = []
  var carbon_essh2_datasets = []
  var carbon_final_demand_columns = [""]
  var carbon_final_demand_data = []
  var carbon_final_demand_essh2_columns = []
  var carbon_final_demand_essh2_data = []
  var carbon_final_demand_essh2_datasets = []

  var agregate_components_columns = []
  var agregate_components_data = []
  var agregate_components_datasets = []
  var porcentage_components_columns = []
  var porcentage_components_data = []
  var porcentage_components_datasets = []
  var porcentage_participation_columns = []
  var porcentage_participation_data = []
  var porcentage_participation_datasets = []
  var detail_compositions_columns = [""]
  var detail_compositions_data = []
  var pib_total_columns = []
  var pib_total_data = []
  var pib_total_columns_doughnut = []
  var pib_total_data_doughnut = []
  var porcentual_direct_contribution_columns = []
  var porcentual_direct_contribution_data = []
  var porcentual_indirect_contribution_columns = []
  var porcentual_indirect_contribution_data = []
  var porcentual_total_contribution_columns = []
  var porcentual_total_contribution_data = []
  var export_imports_columns = []
  var export_imports_data = []
  var export_imports_datasets = []
  var rate_fosil_columns = []
  var rate_fosil_data = []
  var capital_distribution_columns = []
  var capital_distribution_data = []
  var remuneration_distribution_columns = []
  var remuneration_distribution_data = []
  var fiscal_impacts_columns = [""]
  var fiscal_impacts_data = []
  var production_multipliers_columns = []
  var production_multipliers_data = []
  var gwh_contribution_columns = []
  var gwh_contribution_data = []
  var pib_multipliers_columns = []
  var pib_multipliers_data = []
  var pib_multipliers_datasets = []
  var rent_multipliers_columns = []
  var rent_multipliers_data = []
  var rent_multipliers_datasets = []
  var inversion_multipliers_columns = []
  var inversion_multipliers_data = []
  var tech_importance_columns = []
  var tech_importance_data = [[]]

  var induced_employment_columns = []
  var induced_employment_data = []
  var induced_employment_datasets = []
  var remuneration_analisis_columns = [""]
  var remuneration_analisis_data = []
  var historical_employees_columns = []
  var historical_employees_data = []
  var historical_employees_datasets = []
  var pib_employment_columns = []
  var pib_employment_data = []
  var final_demand_columns = []
  var final_demand_data = []
  var employment_gwh_columns = []
  var employment_gwh_data = []
  var labor_multipliers_columns = []
  var labor_multipliers_data = []

   //Function to fix columns design
   function columns_convert(columns) { 
    return columns.map((column, index) => ({
      name: column,
      label: column,
      options: {
        viewColumns: index === 0 ? false : true, // La primera columna no aparecerá en el menú 'ViewColumns'
        
        // Estilos del encabezado con bordes y sticky aplicado a la primera columna
        customHeadRender: (columnMeta) => {
          return (
            <th
              key={columnMeta.index}
              className="custom-header custom-cell-spacing"
              style={{
                background: "#b0b0b0",  // Color de fondo gris oscuro para el encabezado
                color: "white",  // Texto en blanco para el encabezado
                fontWeight: "bold",  // Texto en negrita
                textAlign: 'center',  // Centrar el contenido en el encabezado
                position: 'sticky',  // Sticky para todas las celdas del encabezado
                top: 0,  // Fijar la fila del encabezado en la parte superior
                left: index === 0 ? 0 : 'auto',  // Fijar la primera columna
                zIndex: index === 0 ? 102 : 101,  // Asegura que el encabezado esté por encima del contenido
                height: '50px',  // Ajustar el alto del encabezado
                verticalAlign: 'middle',  // Alinear verticalmente al centro
              }}
            >
              {columnMeta.label}
            </th>
          );
        },
      }
    }));
  }
  

  //Set data 
  //MIP
  location.state.calibratedmip.col_order?.forEach((element) => {
    resumed_mip_columns.push(capitalize(element));
  })
  location.state.calibratedmip.row_order?.forEach((element) => {
    var new_array= [capitalize(element)].concat(Array(resumed_mip_columns.length-1).fill(null))
    resumed_mip_data.push(new_array);
  })
  location.state.calibratedmip.data?.forEach((element) => {
    var x = capitalize(element.x_index)
    var v= element.value
    var y= capitalize(element.y_index)
    resumed_mip_data.forEach((element2,index2) =>{
      if (x === element2[0]){
        resumed_mip_data[index2][resumed_mip_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })

  location.state.inverted.col_order?.forEach((element) => {
    inverted_mip_columns.push(capitalize(element));
  })
  location.state.inverted.row_order?.forEach((element) => {
    var new_array= [capitalize(element)].concat(Array(inverted_mip_columns.length-1).fill(0))
    inverted_mip_data.push(new_array);
  })
  location.state.inverted.data?.forEach((element) => {
    var x = capitalize(element.x_index)
    var v= element.value
    var y= capitalize(element.y_index)
    inverted_mip_data.forEach((element2,index2) =>{
      if (x === element2[0]){
        inverted_mip_data[index2][inverted_mip_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })

  location.state.calibratedmipcomplete.col_order?.forEach((element) => {
    complete_mip_columns.push(capitalize(element));
  })
  location.state.calibratedmipcomplete.row_order?.forEach((element) => {
    var new_array= Array(complete_mip_columns.length).fill(0)
    new_array[0] = capitalize(element)
    complete_mip_data.push(new_array);
  })
  location.state.calibratedmipcomplete.data?.forEach((element) => {
    var x = capitalize(element.x_index)
    var v= element.value
    var y= capitalize(element.y_index)
    complete_mip_data.forEach((element2,index2) =>{
      if (x === element2[0]){
        complete_mip_data[index2][complete_mip_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })

  location.state.summary.data?.forEach((element) => {
    var y = capitalize(element.y_index)
    if(!summary_columns.includes(y) && y!=="H2"){
      summary_columns.push(y)
    }
  })
  location.state.summary.data?.forEach((element) => {
    var y = capitalize(element.y_index)
    if(!summary_columns.includes(y)){
      summary_columns.push(y)
    }
  })
  location.state.summary.data?.forEach((element) => {
    var x = element.x_index
    var new_array= Array(summary_columns.length).fill(null)
    var flag = true
    summary_data.forEach((element2) => {
      if(element2.includes(x)){
        flag = false
      }
    })
    if(flag && x!== ("PIB ["+coins[values.country]+"]")){
      new_array[0] = x
      summary_data.push(new_array)
    }
  })
  location.state.summary.data?.forEach((element) => {
    var x = element.x_index
    var v= element.value
    var y= capitalize(element.y_index)
    summary_data.forEach((element2,index2) =>{
      if (x === element2[0]){
        summary_data[index2][summary_columns.indexOf(y)] = v.toFixed(2)
        if(x === ("Valor agregado["+coins[values.country]+"]")){
          summary_data[index2][9] = summary_data[index2].slice(1, 9)  // Toma desde la posición 2 hasta la 8 (índices 1 a 7)
          .map(num => parseFloat(num))  // Convierte los strings a float
          .reduce((acc, current) => acc + current, 0).toFixed(2);
        }
      }
    })
  })

  location.state.summary.data?.forEach((element) => {
    var x = element.x_index
    var v= element.value
    var y= capitalize(element.y_index)
    if(x === ("Valor agregado["+coins[values.country]+"]") && y !== "Total sector gen+ESS" && y!=="H2"){
      aggregate_value_columns.push(y)
      aggregate_value_data.push(v.toFixed(2))
    }
  })

  location.state.calibratedmip.data?.forEach((element) => {
    var x = capitalize(element.x_index)
    var v= element.value
    var y= capitalize(element.y_index)
    if(x === "Valor agregado" && y !== "Consumo final" && y!=="Producción precios básicos" && y!=="Total demandas"){
      pib_columns.push(y)
      pib_data.push(v.toFixed(2))
    }
  })
  
  //Economics
  location.state.components.coponentes_PIB_values.data?.forEach((element) => {
    var y = capitalize(element.y_index)
    if(!agregate_components_columns.includes(y)){
      agregate_components_columns.push(y)
    }
  })
  location.state.components.coponentes_PIB_values.data?.forEach((element) => {
    var x = capitalize(element.x_index)
    var flag= true
    var new_array= Array(agregate_components_columns.length).fill(0)
    agregate_components_data.forEach((element2) => {
      if (element2.name === x){
        flag = false
      }
    })
    if (flag){
      agregate_components_data.push({name:x, data: new_array})
    }
  })
  location.state.components.coponentes_PIB_values.data?.forEach((element) => {
    var x = capitalize(element.x_index)
    var v= element.value
    var y= capitalize(element.y_index)
    agregate_components_data.forEach((element2) => {
      if(x === element2.name){
        element2.data[agregate_components_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })
  agregate_components_data?.forEach((element) => {
    agregate_components_datasets.push({label: element.name, data: element.data, backgroundColor: graphColors([element.name])})
  })

  location.state.components.componentes_PIB_por_sector.data?.forEach((element) => {
    var y = capitalize(element.y_index)
    if(!porcentage_components_columns.includes(y)){
      porcentage_components_columns.push(y)
    }
  })
  location.state.components.componentes_PIB_por_sector.data?.forEach((element) => {
    var x = capitalize(element.x_index)
    var flag= true
    var new_array= Array(porcentage_components_columns.length).fill(0)
    porcentage_components_data.forEach((element2) => {
      if (element2.name === x){
        flag = false
      }
    })
    if (flag){
      porcentage_components_data.push({name:x, data: new_array})
    }
  })
  location.state.components.componentes_PIB_por_sector.data?.forEach((element) => {
    var x = capitalize(element.x_index)
    var v= element.value
    var y= capitalize(element.y_index)
    porcentage_components_data.forEach((element2) => {
      if(x === element2.name){
        element2.data[porcentage_components_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })
  porcentage_components_data?.forEach((element) => {
    porcentage_components_datasets.push({label: element.name, data: element.data, backgroundColor: graphColors([element.name])})
  })

  location.state.components.componentes_PIB_por_componentes_valor_agregado.data?.forEach((element) => {
    var y = capitalize(element.y_index)
    if(!porcentage_participation_columns.includes(y)){
      porcentage_participation_columns.push(y)
    }
  })
  location.state.components.componentes_PIB_por_componentes_valor_agregado.data?.forEach((element) => {
    var x = capitalize(element.x_index)
    var flag= true
    var new_array= Array(porcentage_participation_columns.length).fill(0)
    porcentage_participation_data.forEach((element2) => {
      if (element2.name === x){
        flag = false
      }
    })
    if (flag){
      porcentage_participation_data.push({name:x, data: new_array})
    }
  })
  location.state.components.componentes_PIB_por_componentes_valor_agregado.data?.forEach((element) => {
    var x = capitalize(element.x_index)
    var v= element.value
    var y= capitalize(element.y_index)
    porcentage_participation_data.forEach((element2) => {
      if(x === element2.name){
        element2.data[porcentage_participation_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })
  porcentage_participation_data?.forEach((element) => {
    porcentage_participation_datasets.push({label: element.name, data: element.data, backgroundColor: graphColors([element.name])})
  })

  location.state.detailcomposition.data?.forEach((element) => {
    var y = capitalize(element.y_index)
    if(!detail_compositions_columns.includes(y)){
      detail_compositions_columns.push(y)
    }
  })
  location.state.detailcomposition.data?.forEach((element) => {
    var x= capitalize(element.x_index)
    var new_array= [x].concat(Array(detail_compositions_columns.length-1).fill(0))
    var flag= true
    detail_compositions_data.forEach((element2) => {
      if(x=== element2[0]){
        flag = false
      }
    })
    if(flag)
    detail_compositions_data.push(new_array);
  })
  location.state.detailcomposition.data?.forEach((element) => {
    var x = capitalize(element.x_index)
    var v= element.value
    var y= capitalize(element.y_index)
    detail_compositions_data.forEach((element2) =>{
      if (x === element2[0]){
        element2[detail_compositions_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })
  
  location.state.impactscontribution.PIBsDirectosIndirectosTotales.data?.forEach((element) => {
    var y = capitalize(element.y_index)
    if(!pib_total_columns.includes(y)){
      pib_total_columns.push(y)
    }
  })
  location.state.impactscontribution.PIBsDirectosIndirectosTotales.data?.forEach((element) => {
    var x = element.x_index
    var flag= true
    var new_array= Array(pib_total_columns.length).fill(0)
    pib_total_data.forEach((element2) => {
      if (element2.name === x){
        flag = false
      }
    })
    if (flag){
      pib_total_data.push({name:x, data: new_array})
    }
  })
  location.state.impactscontribution.PIBsDirectosIndirectosTotales.data?.forEach((element) => {
    var x = element.x_index
    var v= element.value
    var y= capitalize(element.y_index)
    pib_total_data.forEach((element2) => {
      if(x === element2.name){
        element2.data[pib_total_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })

  location.state.impactscontribution.PIBsDirectosIndirectosTotales.data?.forEach((element) => {
    var y = capitalize(element.y_index)
    if(!pib_total_columns_doughnut.includes(y)){
      pib_total_columns_doughnut.push(y)
      pib_total_data_doughnut.push(0.00)
    }
  })
  location.state.impactscontribution.PIBsDirectosIndirectosTotales.data?.forEach((element) => {
    var y = capitalize(element.y_index)
    var v = element.value
    var position = pib_total_columns_doughnut.indexOf(y)
    pib_total_data_doughnut[position]+=v
  })

  location.state.impactscontribution.DirectContributionPercentage.data?.forEach((element) => {
    porcentual_direct_contribution_columns.push(capitalize(element.x_index))
    porcentual_direct_contribution_data.push(element.value.toFixed(2))
  })

  location.state.impactscontribution.InducedContributionPercentage.data?.forEach((element) => {
    porcentual_indirect_contribution_columns.push(capitalize(element.x_index))
    porcentual_indirect_contribution_data.push(element.value.toFixed(2))
  })

  location.state.impactscontribution.TotalContributionPercentage.data?.forEach((element) => {
    porcentual_total_contribution_columns.push(capitalize(element.x_index))
    porcentual_total_contribution_data.push(element.value.toFixed(2))
  })

  location.state.exportimports.data?.forEach((element) => {
    var y = capitalize(element.y_index)
    if(!export_imports_columns.includes(y)){
      export_imports_columns.push(y)
    }
  })
  location.state.exportimports.data?.forEach((element) => {
    var x = element.x_index
    var flag= true
    var new_array= Array(export_imports_columns.length).fill(0)
    export_imports_data.forEach((element2) => {
      if (element2.name === x){
        flag = false
      }
    })
    if (flag){
      export_imports_data.push({name:x, data: new_array})
    }
  })
  location.state.exportimports.data?.forEach((element) => {
    var x = element.x_index
    var v= element.value
    var y= capitalize(element.y_index)
    export_imports_data.forEach((element2) => {
      if(x === element2.name){
        element2.data[export_imports_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })
  export_imports_data?.forEach((element) => {
    export_imports_datasets.push({label: element.name, data: element.data, backgroundColor: graphColors([element.name]), borderWidth: 0, categoryPercentage: 0.8})
  })

  location.state.ratefosilrenovable.data?.forEach((element) => {
    rate_fosil_columns.push(capitalize(element.x_index))
    rate_fosil_data.push(element.value.toFixed(5))
  })

  location.state.capitaldistribution.data?.forEach((element) => {
    var y = capitalize(element.y_index)
    if (!capital_distribution_columns.includes(y)){
      capital_distribution_columns.push(y)
      capital_distribution_data.push(element.value.toFixed(2))
    }
  })

  location.state.remunerationdistribution.data?.forEach((element) => {
    var y = capitalize(element.y_index)
    if (!remuneration_distribution_columns.includes(y)){
      remuneration_distribution_columns.push(y)
      remuneration_distribution_data.push(element.value.toFixed(2))
    }
  })

  location.state.fiscalimpact.data?.forEach((element) => {
    var y = capitalize(element.y_index)
    if (!fiscal_impacts_columns.includes(y)){
      fiscal_impacts_columns.push(y)
    }
  })
  location.state.fiscalimpact.data?.forEach((element) => {
    var x = element.x_index
    var new_array= [x].concat(Array(fiscal_impacts_columns.length-1).fill(0))
    var flag= true
    fiscal_impacts_data.forEach((element2) => {
      if (element2[0] === x){
        flag = false
      }
    })
    if (flag){
      fiscal_impacts_data.push(new_array)
    }
  })
  location.state.fiscalimpact.data?.forEach((element) => {
    var x = element.x_index
    var v= element.value
    var y= capitalize(element.y_index)
    fiscal_impacts_data.forEach((element2,index2) =>{
      if (x === element2[0]){
        fiscal_impacts_data[index2][fiscal_impacts_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })

  location.state.productionmultipliers.data?.forEach((element) => {
    var x = capitalize(element.x_index)
    if (!production_multipliers_columns.includes(x)){
      production_multipliers_columns.push(x)
      production_multipliers_data.push(element.value.toFixed(2))
    }
  })

  location.state.gwhcontribution.data?.forEach((element) => {
    var y = capitalize(element.y_index)
    if (!gwh_contribution_columns.includes(y)){
      gwh_contribution_columns.push(y)
      gwh_contribution_data.push(element.value.toFixed(2))
    }
  })

  location.state.pibmultipliers.data?.forEach((element) => {
    var y = capitalize(element.y_index)
    if(!pib_multipliers_columns.includes(y)){
      pib_multipliers_columns.push(y)
    }
  })
  location.state.pibmultipliers.data?.forEach((element) => {
    var x = element.x_index
    var flag= true
    var new_array= Array(pib_multipliers_columns.length).fill(0)
    pib_multipliers_data.forEach((element2) => {
      if (element2.name === x){
        flag = false
      }
    })
    if (flag){
      pib_multipliers_data.push({name:x, data: new_array})
    }
  })
  location.state.pibmultipliers.data?.forEach((element) => {
    var x = element.x_index
    var v= element.value
    var y= capitalize(element.y_index)
    pib_multipliers_data.forEach((element2) => {
      if(x === element2.name){
        element2.data[pib_multipliers_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })
  pib_multipliers_data?.forEach((element) => {
    pib_multipliers_datasets.push({label: element.name, data: element.data, backgroundColor: graphColors([element.name])})
  })

  location.state.rentmultipliers.data?.forEach((element) => {
    var y = capitalize(element.y_index)
    if(!rent_multipliers_columns.includes(y)){
      rent_multipliers_columns.push(y)
    }
  })
  location.state.rentmultipliers.data?.forEach((element) => {
    var x = element.x_index
    var flag= true
    var new_array= Array(rent_multipliers_columns.length).fill(0)
    rent_multipliers_data.forEach((element2) => {
      if (element2.name === x){
        flag = false
      }
    })
    if (flag){
      rent_multipliers_data.push({name:x, data: new_array})
    }
  })
  location.state.rentmultipliers.data?.forEach((element) => {
    var x = element.x_index
    var v= element.value
    var y= capitalize(element.y_index)
    rent_multipliers_data.forEach((element2) => {
      if(x === element2.name){
        element2.data[rent_multipliers_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })
  rent_multipliers_data?.forEach((element) => {
    rent_multipliers_datasets.push({label: element.name, data: element.data, backgroundColor: graphColors([element.name])})
  })

  location.state.inversionmultipliers.data?.forEach((element) => {
    var y = capitalize(element.y_index)
    if(!inversion_multipliers_columns.includes(y)){
      inversion_multipliers_columns.push(y)
      inversion_multipliers_data.push(element.value.toFixed(2))
    }
  })

  location.state.techimportance.data?.forEach((element) => {
    var x = capitalize(element.x_index)
    if (!tech_importance_columns.includes(x)){
      tech_importance_columns.push(x)
      tech_importance_data[0].push(element.value)
    }
  })


  //Emissions
  location.state.directemissions.data?.forEach((element) => {
    var y = capitalize(element.y_index)
    if(!direct_emissions_columns.includes(y)){
      direct_emissions_columns.push(y)
    }
  })
  location.state.directemissions.data?.forEach((element) => {
    var x = element.x_index
    var flag= true
    var new_array= Array(direct_emissions_columns.length).fill(0)
    direct_emissions_data.forEach((element2) => {
      if (element2.name === x){
        flag = false
      }
    })
    if (flag){
      direct_emissions_data.push({name:x, data: new_array})
    }
  })
  location.state.directemissions.data?.forEach((element) => {
    var x = element.x_index
    var v= element.value
    var y= capitalize(element.y_index)
    direct_emissions_data.forEach((element2) => {
      if(x === element2.name){
        element2.data[direct_emissions_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })

  location.state.carbonfootprint.data?.forEach((element) => {
    carbon_footprint_columns.push(capitalize(element.x_index))
    carbon_footprint_data.push(element.value.toFixed(2))
  })

  location.state.carbonsource.data?.forEach((element) => {
    var y = capitalize(element.y_index)
    if(!carbon_source_columns.includes(y)){
      carbon_source_columns.push(y)
    }
  })
  location.state.carbonsource.data?.forEach((element) => {
    var x = capitalize(element.x_index)
    var flag= true
    var new_array= Array(carbon_source_columns.length).fill(0)
    carbon_source_data.forEach((element2) => {
      if (element2.includes(x)){
        flag = false
      }
    })
    if (flag){
      new_array[0]=x
      carbon_source_data.push(new_array)
    }
  })
  location.state.carbonsource.data?.forEach((element) => {
    var x = capitalize(element.x_index)
    var v= element.value
    var y= capitalize(element.y_index)
    carbon_source_data.forEach((element2) => {
      if(element2.includes(x)){
        element2[carbon_source_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })

  location.state.carbonessh2.data?.forEach((element) => {
    var y= capitalize(element.y_index)
    if(!carbon_essh2_columns.includes(y)){
      carbon_essh2_columns.push(y)
    } 
  })
  location.state.carbonessh2.data?.forEach((element) => {
    var x = capitalize(element.x_index)
    var flag= true
    var new_array= Array(carbon_essh2_columns.length).fill(0)
    carbon_essh2_data.forEach((element2) => {
      if (element2.name === x){
        flag = false
      }
    })
    if (flag){
      carbon_essh2_data.push({name:x, data: new_array})
    }
  })
  location.state.carbonessh2.data?.forEach((element) => {
    var x = capitalize(element.x_index)
    var v= element.value
    var y= capitalize(element.y_index)
    carbon_essh2_data.forEach((element2) => {
      if(x === element2.name){
        element2.data[carbon_essh2_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })
  carbon_essh2_data?.forEach((element) => {
    carbon_essh2_datasets.push({label: element.name, data: element.data, backgroundColor: graphColors([element.name])})
  })

  location.state.carbonfinaldemand.data?.forEach((element) => {
    var y = capitalize(element.y_index)
    if(!carbon_final_demand_columns.includes(y)){
      carbon_final_demand_columns.push(y)
    }
  })
  location.state.carbonfinaldemand.data?.forEach((element) => {
    var x = capitalize(element.x_index)
    var flag= true
    var new_array= Array(carbon_final_demand_columns.length).fill(0)
    carbon_final_demand_data.forEach((element2) => {
      if (element2.includes(x)){
        flag = false
      }
    })
    if (flag){
      new_array[0]=x
      carbon_final_demand_data.push(new_array)
    }
  })
  location.state.carbonfinaldemand.data?.forEach((element) => {
    var x = capitalize(element.x_index)
    var v= element.value
    var y= capitalize(element.y_index)
    carbon_final_demand_data.forEach((element2) => {
      if(element2.includes(x)){
        element2[carbon_final_demand_columns.indexOf(y)] = v.toFixed(4)
      }
    })
  })

  location.state.carbonfinaldemandessh2.data?.forEach((element) => {
    var y= capitalize(element.y_index)
    if(!carbon_final_demand_essh2_columns.includes(y)){
      carbon_final_demand_essh2_columns.push(y)
    } 
  })
  location.state.carbonfinaldemandessh2.data?.forEach((element) => {
    var x = capitalize(element.x_index)
    var flag= true
    var new_array= Array(carbon_final_demand_essh2_columns.length).fill(0)
    carbon_final_demand_essh2_data.forEach((element2) => {
      if (element2.name === x){
        flag = false
      }
    })
    if (flag){
      carbon_final_demand_essh2_data.push({name:x, data: new_array})
    }
  })
  location.state.carbonfinaldemandessh2.data?.forEach((element) => {
    var x = capitalize(element.x_index)
    var v= element.value
    var y= capitalize(element.y_index)
    carbon_final_demand_essh2_data.forEach((element2) => {
      if(x === element2.name){
        element2.data[carbon_final_demand_columns.indexOf(y)] = v.toFixed(5)
      }
    })
  })
  carbon_final_demand_essh2_data?.forEach((element) => {
    carbon_final_demand_essh2_datasets.push({label: element.name, data: element.data, backgroundColor: graphColors([element.name])})
  })

  //Employment
  location.state.inducedemployment.data?.forEach((element) => {
    var y = capitalize(element.y_index)
    if (!induced_employment_columns.includes(y)){
      induced_employment_columns.push(y)
    }
  })
  location.state.inducedemployment.data?.forEach((element) => {
    var x = element.x_index
    var flag= true
    var new_array= Array(induced_employment_columns.length).fill(0)
    induced_employment_data.forEach((element2) => {
      if (element2.name === x){
        flag = false
      }
    })
    if (flag){
      induced_employment_data.push({name:x, data: new_array})
    }
  })
  location.state.inducedemployment.data?.forEach((element) => {
    var x = element.x_index
    var v= element.value
    var y= capitalize(element.y_index)
    induced_employment_data.forEach((element2) => {
      if(x === element2.name){
        element2.data[induced_employment_columns.indexOf(y)] = Math.floor(v)
      }
    })
  })
  induced_employment_data?.forEach((element) => {
    induced_employment_datasets.push({label: element.name, data: element.data, backgroundColor: graphColors([element.name])})
  })

  location.state.remunerationanalisis.data?.forEach((element) => {
    var y = capitalize(element.y_index)
    if (!remuneration_analisis_columns.includes(y)){
      remuneration_analisis_columns.push(y)
    }
  })
  location.state.remunerationanalisis.data?.forEach((element) => {
    var x = element.x_index
    var new_array= [x].concat(Array(remuneration_analisis_columns.length-1).fill(0))
    var flag= true
    remuneration_analisis_data.forEach((element2) => {
      if (element2[0] === x){
        flag = false
      }
    })
    if (flag){
      remuneration_analisis_data.push(new_array)
    }
  })
  location.state.remunerationanalisis.data?.forEach((element) => {
    var x = element.x_index
    var v= element.value
    var y= capitalize(element.y_index)
    remuneration_analisis_data.forEach((element2,index2) =>{
      if (x === element2[0]){
        remuneration_analisis_data[index2][remuneration_analisis_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })

  location.state.historicalemployees.data?.forEach((element) => {
    var x= element.x_index
    if(!historical_employees_columns.includes(x)){
      historical_employees_columns.push(x)
    } 
  })
  location.state.historicalemployees.data?.forEach((element) => {
    var y = capitalize(element.y_index)
    var flag= true
    var new_array= Array(historical_employees_columns.length).fill(0)
    historical_employees_data.forEach((element2) => {
      if (element2.name === y){
        flag = false
      }
    })
    if (flag){
      historical_employees_data.push({name:y, data: new_array})
    }
  })
  location.state.historicalemployees.data?.forEach((element) => {
    var x = element.x_index
    var v= element.value
    var y= capitalize(element.y_index)
    historical_employees_data.forEach((element2) => {
      if(y === element2.name){
        element2.data[historical_employees_columns.indexOf(x)] = Math.floor(v)
      }
    })
  })
  historical_employees_data?.forEach((element) => {
    historical_employees_datasets.push({fill: true, label: element.name, data: element.data, backgroundColor: graphColors([element.name])})
  })

  location.state.pibemployment.data?.forEach((element) => {
    pib_employment_columns.push(capitalize(element.y_index))
    pib_employment_data.push(element.value.toFixed(2))
  })

  location.state.finaldemand.data?.forEach((element) => {
    var y = capitalize(element.y_index)
    if(!final_demand_columns.includes(y)){
      final_demand_columns.push(y)
    }
  })
  location.state.finaldemand.data?.forEach((element) => {
    var x = element.x_index
    var flag= true
    var new_array= Array(final_demand_columns.length).fill(0)
    final_demand_data.forEach((element2) => {
      if (element2.name === x){
        flag = false
      }
    })
    if (flag){
      final_demand_data.push({name:x, data: new_array})
    }
  })
  location.state.finaldemand.data?.forEach((element) => {
    var x = element.x_index
    var v= element.value
    var y= capitalize(element.y_index)
    final_demand_data.forEach((element2) => {
      if(x === element2.name){
        element2.data[final_demand_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })

  location.state.employmentgwh.data?.forEach((element) => {
    var y = capitalize(element.y_index)
    if(!employment_gwh_columns.includes(y)){
      employment_gwh_columns.push(y)
      employment_gwh_data.push(0)
    }
  })
  location.state.employmentgwh.data?.forEach((element) => {
    var x= element.x_index
    var v= element.value
    var y = capitalize(element.y_index)
    if(x === "Empleo por GWh generado"){
      employment_gwh_data[employment_gwh_columns.indexOf(y)] = v.toFixed(3)
    }
  })

  location.state.labormultipliers.data?.forEach((element) => {
    var y = capitalize(element.y_index)
    if(!labor_multipliers_columns.includes(y)){
      labor_multipliers_columns.push(y)
    }
  })
  location.state.labormultipliers.data?.forEach((element) => {
    var x = element.x_index
    var flag= true
    var new_array= Array(labor_multipliers_columns.length).fill(0)
    labor_multipliers_data.forEach((element2) => {
      if (element2.name === x){
        flag = false
      }
    })
    if (flag){
      labor_multipliers_data.push({name:x, data: new_array})
    }
  })
  location.state.labormultipliers.data?.forEach((element) => {
    var x = element.x_index
    var v= element.value
    var y= capitalize(element.y_index)
    labor_multipliers_data.forEach((element2) => {
      if(x === element2.name){
        element2.data[labor_multipliers_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })



  //Display functions
  function GraphicDisplay(){
    if(appState.activeObject === 1 && appState.activeGraphic === 1){
      return(<div className='mt-3 flex justify-center items-center'>
          <div className='custom-table-container' style={{ maxWidth }}>
          <ThemeProvider theme={getMuiTheme()}><MUIDataTable
        data={resumed_mip_data}
        columns={columns_convert(resumed_mip_columns)}
        options={options1}
        components={{TableToolbar: (props) => (
          <div style={{display: 'flex',justifyContent: 'space-between' , alignItems: 'center', backgroundColor: '#b0b0b0',padding: '4px',}}>
            {/* Título personalizado */}
            <div style={{ color: 'black', fontWeight: 'bold', fontSize: '22px', paddingLeft: "16px" }}>
                {location.state.calibratedmip.title}
            </div>
            {/* Mantener los íconos del toolbar predeterminado, sin duplicar el título */}
            <TableToolbar {...props} title={null} />
          </div>
        )}}
        ></MUIDataTable></ThemeProvider>
          </div>
        </div>
      )
    }
    else if(appState.activeObject === 1 && appState.activeGraphic === 2){
      return(<div className='mt-3 flex justify-center items-center'>
        <div className='custom-table-container' style={{ maxWidth }}>
        <ThemeProvider theme={getMuiTheme()}><MUIDataTable
        data={inverted_mip_data}
        columns={columns_convert(inverted_mip_columns)}
        options={options1}
        components={{TableToolbar: (props) => (
          <div style={{display: 'flex',justifyContent: 'space-between' , alignItems: 'center', backgroundColor: '#b0b0b0',padding: '4px',}}>
            {/* Título personalizado */}
            <div style={{ color: 'black', fontWeight: 'bold', fontSize: '22px', paddingLeft: "16px" }}>
                {location.state.inverted.title}
            </div>
            {/* Mantener los íconos del toolbar predeterminado, sin duplicar el título */}
            <TableToolbar {...props} title={null} />
          </div>
        )}}
        ></MUIDataTable></ThemeProvider>
        </div>
      </div>
      )
    }
    else if(appState.activeObject === 1 && appState.activeGraphic === 3){
      return(<div className='mt-3 flex justify-center items-center'>
        <div className='custom-table-container' style={{ maxWidth }}>
        <ThemeProvider theme={getMuiTheme()}><MUIDataTable
        components={{TableToolbar: (props) => (
          <div style={{display: 'flex',justifyContent: 'space-between' , alignItems: 'center', backgroundColor: '#b0b0b0',padding: '4px',}}>
            {/* Título personalizado */}
            <div style={{ color: 'black', fontWeight: 'bold', fontSize: '22px', paddingLeft: "16px" }}>
                {location.state.calibratedmipcomplete.title}
            </div>
            {/* Mantener los íconos del toolbar predeterminado, sin duplicar el título */}
            <TableToolbar {...props} title={null} />
          </div>
        )}}
        data={complete_mip_data}
        columns={columns_convert(complete_mip_columns)}
        options={options1}
        ></MUIDataTable></ThemeProvider>
        </div>
      </div>
      )
    }
    else if(appState.activeObject === 1 && appState.activeGraphic === 4){
      return(<div className='mt-3 flex justify-center items-center'>
        <div className='custom-table-container' style={{ maxWidth }}>
        <ThemeProvider theme={getMuiTheme()}><MUIDataTable
        components={{TableToolbar: (props) => (
          <div style={{display: 'flex',justifyContent: 'space-between' , alignItems: 'center', backgroundColor: '#b0b0b0',padding: '4px',}}>
            {/* Título personalizado */}
            <div style={{ color: 'black', fontWeight: 'bold', fontSize: '22px', paddingLeft: "16px" }}>
                {location.state.summary.title}
            </div>
            {/* Mantener los íconos del toolbar predeterminado, sin duplicar el título */}
            <TableToolbar {...props} title={null} />
          </div>
        )}}
        data={summary_data}
        columns={columns_convert(summary_columns)}
        options={options1}
        ></MUIDataTable></ThemeProvider>
        </div>
      </div>
      )
    }
    else if(appState.activeObject === 1 && appState.activeGraphic === 5){
      // Convertir los valores a float y calcular el valor total
        const totalValue = aggregate_value_data
        .map(value => parseFloat(value))  // Convierte cada valor de string a float
        .reduce((acc, current) => acc + current, 0);  // Suma los valores convertidos

      const ChartData = {
        labels: aggregate_value_columns,
        datasets: [{
          label: `Valor agregado [${coins[values.country]}]`,
          data: aggregate_value_data.map(value => parseFloat(value)), // Asegurarse de que los datos sean floats
          backgroundColor: graphColors(aggregate_value_columns),
          borderWidth: 1,
        }]
      };

      const options = {
        plugins: {
          title: {
            display: true,
            text: `Valor agregado [${coins[values.country]}]`,
            align: "center",
          },
          // Personalización para mostrar el total en el centro del gráfico de dona
          centerText: {
            display: true,
            title: 'Valor agregado',  // Primera línea (título)
            value: `${totalValue.toFixed(2)} [${coins[values.country]}]`  // Segunda línea (valor total)
          },
          tooltip: {
            callbacks: {
              label: function(tooltipItem) {
                const dataset = tooltipItem.dataset;
                const currentValue = dataset.data[tooltipItem.dataIndex];
                const percentage = ((currentValue / totalValue) * 100).toFixed(2);  // Calcula el porcentaje
                return `Valor agregado: ${currentValue} [${coins[values.country]}] (${percentage}%)`;  // Muestra el valor y el porcentaje
              }
            }
          }
        }
      };
      return(
      <div style={{maxHeight: "510px", display:"flex", alignItems: "center", justifyContent: "center"}}>
        <Doughnut data={ChartData} options={options}/>
      </div>)
    }
    else if(appState.activeObject === 1 && appState.activeGraphic === 6){
      // Convertir los valores a float y calcular el valor total
        const totalValue = pib_data
        .map(value => parseFloat(value))  // Convierte cada valor de string a float
        .reduce((acc, current) => acc + current, 0);  // Suma los valores convertidos

      const ChartData = {
        labels: pib_columns,
        datasets: [{
          label: `PIB nacional [${coins[values.country]}]`,
          data: pib_data.map(value => parseFloat(value)), // Asegurarse de que los datos sean floats
          backgroundColor: graphColors(pib_columns),
          borderWidth: 1,
        }]
      };

      const options = {
        plugins: {
          title: {
            display: true,
            text: `PIB nacional [${coins[values.country]}]`,
            align: "center",
          },
          // Personalización para mostrar el total en el centro del gráfico de dona
          centerText: {
            display: true,
            title: 'PIB nacional',  // Primera línea (título)
            value: `${totalValue.toFixed(2)} [${coins[values.country]}]`  // Segunda línea (valor total)
          },
          tooltip: {
            callbacks: {
              label: function(tooltipItem) {
                const dataset = tooltipItem.dataset;
                const currentValue = dataset.data[tooltipItem.dataIndex];
                const percentage = ((currentValue / totalValue) * 100).toFixed(2);  // Calcula el porcentaje
                return`PIB nacional: ${currentValue} [${coins[values.country]}] (${percentage}%)`;  // Muestra el valor y el porcentaje
              }
            }
          }
        }
      };
      return(
      <div style={{maxHeight: "510px", display:"flex", alignItems: "center", justifyContent: "center"}}>
        <Doughnut data={ChartData} options={options}/>
      </div>)
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 1){
      const ChartData2 = {
        labels: agregate_components_columns,
        datasets: agregate_components_datasets
      };
      const options = {
        plugins:{
          title: {
          display: true,
          text: location.state.components.coponentes_PIB_values.title,
          align: "center",
          },
      },
      indexAxis: 'y',
          scales: {
              x: {title: {display: "true", text: coins[values.country], color:"black"}, stacked: true},
              y: {stacked: true}
          }};
      return(<div className='flex justify-center items-center'>
        <div style={{width: "49vw"}}>
        {HorizontalTables(ChartData2, options)}
        </div>
      </div>)
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 2){
      const ChartData2 = {
        labels: porcentage_components_columns,
        datasets: porcentage_components_datasets
      };
      const options = {
        plugins:{
          title: {
          display: true,
          text: location.state.components.componentes_PIB_por_sector.title,
          align: "center",
          },
          
      },
      indexAxis: 'y',
          scales: {
              x: {title: {display: "true", text: "Porcentaje de participación", color:"black"}, stacked: true, max:100},
              y: {stacked: true}
          }};
      return(<div className='flex justify-center items-center'>
        <div style={{width: "49vw"}}>
        {HorizontalTables(ChartData2, options)}
        </div>
      </div>)
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 3){
      const ChartData2 = {
        labels: porcentage_participation_columns,
        datasets: porcentage_participation_datasets
      };
      const options = {
        plugins:{
          title: {
          display: true,
          text: location.state.components.componentes_PIB_por_componentes_valor_agregado.title,
          align: "center",
          },
          
      },
      indexAxis: 'y',
          scales: {
              x: {title: {display: "true", text: "Porcentaje de participación", color:"black"}, stacked: true, max:100},
              y: {stacked: true}
          }};
      return(
        <div className='flex justify-center items-center'>
        <div style={{width: "49vw"}}>
        {HorizontalTables(ChartData2, options)}
        </div>
      </div>)
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 4){
      return(<div className='flex justify-center items-center'>
        <div className='custom-table-container' style={{ maxWidth }}>
        <ThemeProvider theme={getMuiTheme()}><MUIDataTable
        components={{TableToolbar: (props) => (
          <div style={{display: 'flex',justifyContent: 'space-between' , alignItems: 'center', backgroundColor: '#b0b0b0',padding: '4px',}}>
            {/* Título personalizado */}
            <div style={{ color: 'black', fontWeight: 'bold', fontSize: '22px', paddingLeft: "16px" }}>
                {location.state.detailcomposition.title}
            </div>
            {/* Mantener los íconos del toolbar predeterminado, sin duplicar el título */}
            <TableToolbar {...props} title={null} />
          </div>
        )}}
        data={detail_compositions_data}
        columns={columns_convert(detail_compositions_columns)}
        options={options2}
        ></MUIDataTable></ThemeProvider>
        </div>
      </div>
      )
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 5){
      const ChartData2 = {
        labels: pib_total_columns,
        datasets: [
          {
          label: pib_total_data[0].name,
          data: pib_total_data[0].data,
          backgroundColor: graphColors([pib_total_data[0].name]),
          borderWidth: 0,
          },
          {
            label: pib_total_data[1].name,
            data: pib_total_data[1].data,
            backgroundColor: graphColors([pib_total_data[1].name]),
            borderWidth: 0,
            }
        ]
      };
      const options = {
        plugins:{
          title: {
          display: true,
          text: location.state.impactscontribution.PIBsDirectosIndirectosTotales.title,
          align: "center",
          },
          
      },
      indexAxis: 'y',
          scales: {
              x: {title: {display: "true", text: coins[values.country], color:"black"}, stacked: true},
              y: {stacked: true}
          }};
      return(
        <div className='flex justify-center items-center'>
        <div style={{width: "49vw"}}>
        {HorizontalTables(ChartData2, options)}
        </div>
      </div>)
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 20){
      // Convertir los valores a float y calcular el valor total
      const roundedArray = pib_total_data_doughnut.map(num => parseFloat(num.toFixed(2)));
      const totalValue = roundedArray
      .map(value => parseFloat(value))  // Convierte cada valor de string a float
      .reduce((acc, current) => acc + current, 0);  // Suma los valores convertidos

    const ChartData = {
      labels: pib_total_columns_doughnut,
      datasets: [{
        label: `Impacto PIB total [${coins[values.country]}]`,
        data: roundedArray.map(value => parseFloat(value)), // Asegurarse de que los datos sean floats
        backgroundColor: graphColors(pib_total_columns_doughnut),
        borderWidth: 1,
      }]
    };

    const options = {
      plugins: {
        title: {
          display: true,
          text: `Impacto total en el PIB de los sectores generación, almacenamiento y H2 en ${coins[values.country]}`,
          align: "center",
        },
        // Personalización para mostrar el total en el centro del gráfico de dona
        centerText: {
          display: true,
          title: 'Impacto PIB total',  // Primera línea (título)
          value: `${totalValue.toFixed(2)} [${coins[values.country]}]`  // Segunda línea (valor total)
        },
        tooltip: {
          callbacks: {
            label: function(tooltipItem) {
              const dataset = tooltipItem.dataset;
              const currentValue = dataset.data[tooltipItem.dataIndex];
              const percentage = ((currentValue / totalValue) * 100).toFixed(2);  // Calcula el porcentaje
              return `Impacto PIB total: ${currentValue} [${coins[values.country]}] (${percentage}%)`;  // Muestra el valor y el porcentaje
            }
          }
        }
      }
    };
    return(
    <div style={{maxHeight: "510px", display:"flex", alignItems: "center", justifyContent: "center"}}>
      <Doughnut data={ChartData} options={options}/>
    </div>
    )
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 6){
      const ChartData = {
        labels: porcentual_direct_contribution_columns,
        datasets: [{
          label: "Contribución porcentual",
          data: porcentual_direct_contribution_data,
          backgroundColor: graphColors(porcentual_direct_contribution_columns),
          borderWidth: 1
        } ]
      }
      const options = {
        plugins : {
          title: {
            display: true,
            text: location.state.impactscontribution.DirectContributionPercentage.title,
            align: "center",
          }}
      }
      return(
      <div style={{maxHeight: "510px", display:"flex", alignItems: "center", justifyContent: "center"}}>
        <Doughnut data={ChartData} options={options}/>
      </div>)
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 7){
      const ChartData = {
        labels: porcentual_indirect_contribution_columns,
        datasets: [{
          label: "Contribución porcentual",
          data: porcentual_indirect_contribution_data,
          backgroundColor: graphColors(porcentual_indirect_contribution_columns),
          borderWidth: 1
        } ]
      }
      const options = {
        plugins : {
          title: {
            display: true,
            text: location.state.impactscontribution.InducedContributionPercentage.title,
            align: "center",
          }}
      }
      return(
      <div style={{maxHeight: "510px", display:"flex", alignItems: "center", justifyContent: "center"}}>
        <Doughnut data={ChartData} options={options}/>
      </div>)
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 8){
      const ChartData = {
        labels: porcentual_total_contribution_columns,
        datasets: [{
          label: "Contribución porcentual",
          data: porcentual_total_contribution_data,
          backgroundColor: graphColors(porcentual_total_contribution_columns),
          borderWidth: 1
        } ]
      }
      const options = {
        plugins : {
          title: {
            display: true,
            text: location.state.impactscontribution.TotalContributionPercentage.title,
            align: "center",
          }}
      }
      return(
      <div style={{maxHeight: "510px", display:"flex", alignItems: "center", justifyContent: "center"}}>
        <Doughnut data={ChartData} options={options}/>
      </div>)
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 9){
      const ChartData = {
        labels: export_imports_columns,
        datasets: export_imports_datasets}
      const options = {
        scales: {
          x: {title:{display: true, text: "Tecnologías"}},
          y: {title:{display: true, text: "Exportaciones e importaciones ["+coins[values.country]+"]"}}
        },
        plugins : {
          title: {
            display: true,
            text: location.state.exportimports.title,
            align: "center",
          }
        }
      }
      return(
        <div className='flex justify-center items-center'>
        <div style={{width: "49vw"}}>
          <Bar data={ChartData} options={options}/>
        </div>
      </div>)
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 10){
      const ChartData = {
        labels: rate_fosil_columns,
        datasets: [{
          label: "Importaciones combustibles fosiles [MMLCP]",
          data: rate_fosil_data,
          backgroundColor: graphColors(rate_fosil_columns),
          borderWidth: 0,
          }]
      }
      const options = {
        scales: {
          x: {title:{display: true, text: "Combustibles fósiles", color: "black"}},
          y: {title:{display: true, text: "Importaciones combustibles fosiles [MMLCP]", color: "black"}}
        },
        plugins : {
          legend: {display: false},
          title: {
            display: true,
            text: location.state.ratefosilrenovable.title,
            align: "center",
          }
        }
      }
      return(
        <div className='flex justify-center items-center'>
        <div style={{width: "49vw"}}>
          <Bar data={ChartData} options={options}/>
        </div>
      </div>)
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 11){
      const ChartData = {
        labels: capital_distribution_columns,
        datasets: [{
          label: location.state.capitaldistribution.data[0].x_index,
          data: capital_distribution_data,
          backgroundColor: graphColors(capital_distribution_columns),
          borderWidth: 0,
          }]
      }
      const options = {
        scales: {
          x: {title:{display: true, text: "Tecnologías", color: "black"}},
          y: {title:{display: true, text: location.state.capitaldistribution.data[0].x_index, color: "black"}}
        },
        plugins : {
          legend: {display: false},
          title: {
            display: true,
            text: location.state.capitaldistribution.title,
            align: "center",
          }
        }
      }
      return(
        <div className='flex justify-center items-center'>
        <div style={{width: "49vw"}}>
          <Bar data={ChartData} options={options}/>
        </div>
      </div>)
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 12){
      const ChartData = {
        labels: remuneration_distribution_columns,
        datasets: [{
          label: location.state.remunerationdistribution.data[0].x_index,
          data: remuneration_distribution_data,
          backgroundColor: graphColors(remuneration_distribution_columns),
          borderWidth: 0,
          }]
      }
      const options = {
        scales: {
          x: {title:{display: true, text: "Tecnologías", color: "black"}},
          y: {title:{display: true, text: location.state.remunerationdistribution.data[0].x_index, color: "black"}}
        },
        plugins : {
          legend: {display: false},
          title: {
            display: true,
            text: location.state.remunerationdistribution.title,
            align: "center",
          }
        }
      }
      return(
        <div className='flex justify-center items-center'>
        <div style={{width: "49vw"}}>
          <Bar data={ChartData} options={options}/>
        </div>
      </div>)
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 13){
      return(<div className='flex justify-center items-center'>
        <div className='custom-table-container' style={{ maxWidth }}>
        <ThemeProvider theme={getMuiTheme()}><MUIDataTable
        components={{TableToolbar: (props) => (
          <div style={{display: 'flex',justifyContent: 'space-between' , alignItems: 'center', backgroundColor: '#b0b0b0',padding: '4px',}}>
            {/* Título personalizado */}
            <div style={{ color: 'black', fontWeight: 'bold', fontSize: '22px', paddingLeft: "16px" }}>
                {location.state.fiscalimpact.title}
            </div>
            {/* Mantener los íconos del toolbar predeterminado, sin duplicar el título */}
            <TableToolbar {...props} title={null} />
          </div>
        )}}
        data={fiscal_impacts_data}
        columns={columns_convert(fiscal_impacts_columns)}
        options={options4}
        ></MUIDataTable></ThemeProvider>
        </div>
      </div>
      )
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 14){
      const ChartData = {
        labels: production_multipliers_columns,
        datasets: [{
          label: "Multiplicadores del producto",
          data: production_multipliers_data,
          backgroundColor: graphColors(production_multipliers_columns),
          borderWidth: 0,
          }]
      }
      const options = {
        scales: {
          x: {title:{display: true, text: "Actividades económicas", color: "black"}},
          y: {title:{display: true, text: "Multiplicadores del producto", color: "black"}}
        },
        plugins : {
          legend: {display: false},
          title: {
            display: true,
            text: location.state.productionmultipliers.title,
            align: "center",
          }
        }
      }
      return(
        <div className='flex justify-center items-center'>
        <div style={{width: "49vw"}}>
          <Bar data={ChartData} options={options}/>
        </div>
      </div>)
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 15){
      const ChartData = {
        labels: gwh_contribution_columns,
        datasets: [{
          label: location.state.gwhcontribution.data[0].x_index,
          data: gwh_contribution_data,
          backgroundColor: graphColors(gwh_contribution_columns),
          borderWidth: 0,
          }]
      }
      const options = {
        scales: {
          x: {title:{display: true, text: "Tecnologías", color: "black"}},
          y: {title:{display: true, text: location.state.gwhcontribution.data[0].x_index, color: "black"}}
        },
        plugins : {
          legend: {display: false},
          title: {
            display: true,
            text: location.state.gwhcontribution.title,
            align: "center",
          }
        }
      }
      return(
        <div className='flex justify-center items-center'>
        <div style={{width: "49vw"}}>
          <Bar data={ChartData} options={options}/>
        </div>
      </div>)
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 16){
      const ChartData = {
        labels: pib_multipliers_columns,
        datasets: pib_multipliers_datasets
      };
      const options = {
        plugins:{
          title: {
          display: true,
          text: location.state.pibmultipliers.title,
          align: "center",
          },
      },
          scales: {
              x: {title: {display: "true", text: "Tecnologías", color:"black"}},
              y: {title: {display: "true", text: "Multiplicadores del PIB", color:"black"}}
          }};
      return(
        <div className='flex justify-center items-center'>
        <div style={{width: "49vw"}}>
          <Bar data={ChartData} options={options}/>
        </div>
      </div>)
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 17){
      const ChartData = {
        labels: rent_multipliers_columns,
        datasets: rent_multipliers_datasets
      };
      const options = {
        plugins:{
          title: {
          display: true,
          text: location.state.rentmultipliers.title,
          align: "center",
          },
      },
          scales: {
              x: {title: {display: "true", text: "Tecnologías", color:"black"}},
              y: {title: {display: "true", text: "Multiplicadores de renta", color:"black"}}
          }};
      return(
        <div className='flex justify-center items-center'>
        <div style={{width: "49vw"}}>
          <Bar data={ChartData} options={options}/>
        </div>
      </div>)
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 18){
      const ChartData = {
        labels: inversion_multipliers_columns,
        datasets: [{
          label: location.state.inversionmultipliers.data[0].x_index,
          data: inversion_multipliers_data,
          backgroundColor: graphColors(inversion_multipliers_columns),
          borderWidth: 0,
          }]
      }
      const options = {
        scales: {
          x: {title:{display: true, text: "Tecnologías"}},
          y: {title:{display: true, text: location.state.inversionmultipliers.data[0].x_index}}
        },
        plugins : {
          legend: {display: false},
          title: {
            display: true,
            text: location.state.inversionmultipliers.title,
            align: "center",
          }
        }
      }
      return(
        <div className='flex justify-center items-center'>
        <div style={{width: "49vw"}}>
          <Bar data={ChartData} options={options}/>
        </div>
      </div>)
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 19){
      return(<div className='flex justify-center items-center'>
        <div className='custom-table-container' style={{ maxWidth }}>
        <ThemeProvider theme={getMuiTheme()}><MUIDataTable
        components={{TableToolbar: (props) => (
          <div style={{display: 'flex',justifyContent: 'space-between' , alignItems: 'center', backgroundColor: '#b0b0b0',padding: '4px',}}>
            {/* Título personalizado */}
            <div style={{ color: 'black', fontWeight: 'bold', fontSize: '22px', paddingLeft: "16px" }}>
                {location.state.techimportance.title}
            </div>
            {/* Mantener los íconos del toolbar predeterminado, sin duplicar el título */}
            <TableToolbar {...props} title={null} />
          </div>
        )}}
        data={tech_importance_data}
        columns={columns_convert(tech_importance_columns)}
        options={options3}
        ></MUIDataTable></ThemeProvider>
        </div>
      </div>
      )
    }
    else if(appState.activeObject === 3 && appState.activeGraphic === 1){
      const ChartData = {
        labels: direct_emissions_columns,
        datasets: [
          {
          label: direct_emissions_data[0].name,
          data: direct_emissions_data[0].data,
          backgroundColor: graphColors([direct_emissions_data[0].name]),
          borderWidth: 0,
          categoryPercentage: 0.7
          },
          {
            label: direct_emissions_data[1].name,
            data: direct_emissions_data[1].data,
            backgroundColor: graphColors([direct_emissions_data[1].name]),
            borderWidth: 0,
            categoryPercentage: 0.7
            }
        ]
      }
      const options = {
        scales: {
          x: {title:{display: true, text: "Actividades Económicas"}},
          y: {title:{display: true, text: "Emisiones Directas y Huella de Carbono [Mt CO2 eq]"}}
        },
        plugins : {
          title: {
            display: true,
            text: location.state.directemissions.title,
            align: "center",
          }
        }
      }
      return(
        <div className='mt-3 flex justify-center items-center'>
        <div style={{width: "940px"}}>
          <Bar data={ChartData} options={options}/>
        </div>
      </div>)
    }
    else if(appState.activeObject === 3 && appState.activeGraphic === 2){
      const ChartData = {
        labels: carbon_footprint_columns,
        datasets: [{
          label: "[Kt CO2 eq/"+coins[values.country]+"]",
          data: carbon_footprint_data,
          backgroundColor: graphColors(carbon_footprint_columns),
          borderWidth: 0,
          }]
      }
      const options = {
        scales: {
          x: {title:{display: true, text: "Actividades Económicas"}},
          y: {title:{display: true, text: "Huella de carbono sobre valor agregado "+"[Kt CO2 eq/"+coins[values.country]+"]"}}
        },
        plugins : {
          legend: {display: false},
          title: {
            display: true,
            text: location.state.carbonfootprint.title,
            align: "center",
          }
        }
      }
      return(
        <div className='mt-3 flex justify-center items-center'>
        <div style={{width: "940px"}}>
          <Bar data={ChartData} options={options}/>
        </div>
      </div>)
    }
    else if(appState.activeObject === 3 && appState.activeGraphic === 3){
      return(<div className='mt-3 flex justify-center items-center'>
        <div className='custom-table-container' style={{ maxWidth }}>
        <ThemeProvider theme={getMuiTheme()}><MUIDataTable
        components={{TableToolbar: (props) => (
          <div style={{display: 'flex',justifyContent: 'space-between' , alignItems: 'center', backgroundColor: '#b0b0b0',padding: '4px',}}>
            {/* Título personalizado */}
            <div style={{ color: 'black', fontWeight: 'bold', fontSize: '22px', paddingLeft: "16px" }}>
                {location.state.carbonsource.title}
            </div>
            {/* Mantener los íconos del toolbar predeterminado, sin duplicar el título */}
            <TableToolbar {...props} title={null} />
          </div>
        )}}
        data={carbon_source_data}
        columns={columns_convert(carbon_source_columns)}
        options={options1}
        ></MUIDataTable></ThemeProvider>
        </div>
      </div>
      )
    }
    else if(appState.activeObject === 3 && appState.activeGraphic === 4){
      const ChartData2 = {
        labels: carbon_essh2_columns,
        datasets: carbon_essh2_datasets
      };
      const options = {
        plugins:{
          title: {
          display: true,
          text: location.state.carbonessh2.title,
          align: "center",
          }},
      indexAxis: 'x',
          scales: {
              x: {title: {display: "true", text: "Actividades económicas", color:"black"}, stacked: true},
              y: {title: {display: "true", text: "Huella de carbono [MT CO2eq]", color:"black"}, stacked: true}
          }};
      return(
        <div className='mt-3 flex justify-center items-center'>
        <div style={{width: "940px"}}>
          <Bar data={ChartData2} options={options}/>
        </div>
      </div>)
    }
    else if(appState.activeObject === 3 && appState.activeGraphic === 5){
      return(<div className='mt-3 flex justify-center items-center'>
        <div className='custom-table-container' style={{ maxWidth }}>
        <ThemeProvider theme={getMuiTheme()}><MUIDataTable
        components={{TableToolbar: (props) => (
          <div style={{display: 'flex',justifyContent: 'space-between' , alignItems: 'center', backgroundColor: '#b0b0b0',padding: '4px',}}>
            {/* Título personalizado */}
            <div style={{ color: 'black', fontWeight: 'bold', fontSize: '22px', paddingLeft: "16px" }}>
                {location.state.carbonfinaldemand.title}
            </div>
            {/* Mantener los íconos del toolbar predeterminado, sin duplicar el título */}
            <TableToolbar {...props} title={null} />
          </div>
        )}}
        data={carbon_final_demand_data}
        columns={columns_convert(carbon_final_demand_columns)}
        options={options5}
        ></MUIDataTable></ThemeProvider>
        </div>
      </div>
      )
    }
    else if(appState.activeObject === 3 && appState.activeGraphic === 6){
      const ChartData2 = {
        labels: carbon_final_demand_essh2_columns,
        datasets: carbon_final_demand_essh2_datasets
      };
      const options = {
        plugins:{
          title: {
          display: true,
          text: location.state.carbonfinaldemandessh2.title,
          align: "center",
          }},
      indexAxis: 'x',
          scales: {
              x: {title: {display: "true", text: "Actividades económicas", color:"black"}, stacked: true},
              y: {title: {display: "true", text: "[kt CO2 eq/"+coins[values.country] + "]", color:"black"}, stacked: true}
          }};
      return(
        <div className='mt-3 flex justify-center items-center'>
        <div style={{width: "940px"}}>
          <Bar data={ChartData2} options={options}/>
        </div>
      </div>)
    }
    else if(appState.activeObject === 4 && appState.activeGraphic === 1){
      const ChartData2 = {
        labels: induced_employment_columns,
        datasets: induced_employment_datasets
      };
      const options = {
        plugins:{
          title: {
          display: true,
          text: location.state.inducedemployment.title,
          align: "center",
          }},
      indexAxis: 'x',
          scales: {
              x: {title: {display: "true", text: "Tecnologías", color:"black"}, stacked: true},
              y: {title: {display: "true", text: "Empleos", color:"black"}, stacked: true}
          }};
      return(
        <div className='mt-3 flex justify-center items-center'>
        <div style={{width: "940px"}}>
          <Bar data={ChartData2} options={options}/>
        </div>
      </div>)
    }
    else if(appState.activeObject === 4 && appState.activeGraphic === 2){
      return(<div className='mt-3 flex justify-center items-center'>
        <div className='custom-table-container' style={{ maxWidth }}>
        <ThemeProvider theme={getMuiTheme()}><MUIDataTable
        components={{TableToolbar: (props) => (
          <div style={{display: 'flex',justifyContent: 'space-between' , alignItems: 'center', backgroundColor: '#b0b0b0',padding: '4px',}}>
            {/* Título personalizado */}
            <div style={{ color: 'black', fontWeight: 'bold', fontSize: '22px', paddingLeft: "16px" }}>
                {location.state.remunerationanalisis.title}
            </div>
            {/* Mantener los íconos del toolbar predeterminado, sin duplicar el título */}
            <TableToolbar {...props} title={null} />
          </div>
        )}}
        data={remuneration_analisis_data}
        columns={columns_convert(remuneration_analisis_columns)}
        options={options4}
        ></MUIDataTable></ThemeProvider>
        </div>
      </div>
      )
    }
    else if(appState.activeObject === 4 && appState.activeGraphic === 3){
      const ChartData2 = {
        labels: historical_employees_columns,
        datasets: historical_employees_datasets
      };
      const options = {
        responsive: true,
        plugins:{
          title: {
          display: true,
          text: location.state.historicalemployees.title,
          align: "center",
          }},
          scales: {
              x: {title: {display: "true", text: "Años", color:"black", stacked: true}},
              y: {title: {display: "true", text: "Empleos directos", color:"black", stacked: true}}
          }};
      return(
        <div className='mt-3 flex justify-center items-center'>
        <div style={{width: "940px"}}>
        <Line data={ChartData2} options={options}/>
        </div>
      </div>)
    }
    else if(appState.activeObject === 4 && appState.activeGraphic === 4){
      const ChartData = {
        labels: pib_employment_columns,
        datasets: [{
          label: "PIB / Empleados ["+coins[values.country]+"]",
          data: pib_employment_data,
          backgroundColor: graphColors(pib_employment_columns),
          borderWidth: 0,
          }]
      }
      const options = {
        scales: {
          x: {title:{display: true, text: "Tecnologías"}},
          y: {title:{display: true, text: "PIB / Empleados ["+coins[values.country]+"]"}}
        },
        plugins : {
          legend: {display: false},
          title: {
            display: true,
            text: location.state.pibemployment.title,
            align: "center",
          }
        }
      }
      return(
        <div className='mt-3 flex justify-center items-center'>
        <div style={{width: "940px"}}>
          <Bar data={ChartData} options={options}/>
        </div>
      </div>)
    }
    else if(appState.activeObject === 4 && appState.activeGraphic === 5){
      const ChartData = {
        labels: final_demand_columns,
        datasets: [
          {label: final_demand_data[0].name,
          data: final_demand_data[0].data,
          backgroundColor: graphColors([final_demand_data[0].name]),
          borderWidth: 0,
          categoryPercentage: 0.8},
          {label: final_demand_data[1].name,
            data: final_demand_data[1].data,
            backgroundColor: graphColors([final_demand_data[1].name]),
            borderWidth: 0,
            categoryPercentage: 0.8},
        ]}
      const options = {
        scales: {
          x: {title:{display: true, text: "Tecnologías"}},
          y: {title:{display: true, text: "Empleos/Demanda final[-/"+coins[values.country]+"]"}}
        },
        plugins : {
          title: {
            display: true,
            text: location.state.finaldemand.title,
            align: "center",
          }
        }
      }
      return(
        <div className='mt-3 flex justify-center items-center'>
        <div style={{width: "940px"}}>
          <Bar data={ChartData} options={options}/>
        </div>
      </div>)
    }
    else if(appState.activeObject === 4 && appState.activeGraphic === 6){
      const ChartData = {
        labels: employment_gwh_columns,
        datasets: [{
          label: "Empleos/GWh",
          data: employment_gwh_data,
          backgroundColor: graphColors(employment_gwh_columns),
          borderWidth: 0,
          }]
      }
      const options = {
        scales: {
          x: {title:{display: true, text: "Tecnologías"}},
          y: {title:{display: true, text: "Empleos/GWh"}}
        },
        plugins : {
          legend: {display: false},
          title: {
            display: true,
            text: location.state.employmentgwh.title,
            align: "center",
          }
        }
      }
      return(
        <div className='mt-3 flex justify-center items-center'>
        <div style={{width: "940px"}}>
          <Bar data={ChartData} options={options}/>
        </div>
      </div>)
    }
    else if(appState.activeObject === 4 && appState.activeGraphic === 7){
      const ChartData = {
        labels: labor_multipliers_columns,
        datasets: [
          {label: labor_multipliers_data[0].name,
          data: labor_multipliers_data[0].data,
          backgroundColor: graphColors([labor_multipliers_data[0].name]),
          borderWidth: 0,
          categoryPercentage: 0.8},
          {label: labor_multipliers_data[1].name,
            data: labor_multipliers_data[1].data,
            backgroundColor: graphColors([labor_multipliers_data[1].name]),
            borderWidth: 0,
            categoryPercentage: 0.8},
          {label: labor_multipliers_data[2].name,
          data: labor_multipliers_data[2].data,
          backgroundColor: graphColors([labor_multipliers_data[2].name]),
          borderWidth: 0,
          categoryPercentage: 0.8},
        ]}
      const options = {
        scales: {
          x: {title:{display: true, text: "Tecnologías"}},
          y: {title:{display: true, text: "Multiplicadores de empleo"}}
        },
        plugins : {
          title: {
            display: true,
            text: location.state.labormultipliers.title,
            align: "center",
          }
        }
      }
      return(
        <div className='mt-3 flex justify-center items-center'>
        <div style={{width: "940px"}}>
          <Bar data={ChartData} options={options}/>
        </div>
      </div>)
    }
    else{
      return(<div></div>)
    }
  }

  //Generate CSV function
  function CSVData(){
    if(appState.activeObject === 1 && appState.activeGraphic === 1){
      array_data = []
      resumed_mip_data.forEach((element) =>{
        resumed_mip_columns.forEach((element2,index2)=>{
          if(index2 !== 0){
            array_data.push([element[0],element2,element[index2]])
          }
        })
      })
      return([['Actividades económicas X','Actividades económicas Y',coins[values.country]]].concat(array_data))
    }
    else if(appState.activeObject === 1 && appState.activeGraphic === 2){
      array_data = []
      inverted_mip_data.forEach((element) =>{
        inverted_mip_columns.forEach((element2,index2)=>{
          if(index2 !== 0){
            array_data.push([element[0],element2,element[index2]])
          }
        })
      })
      return([['Actividades económicas X','Actividades económicas Y','Multiplicadores de producción']].concat(array_data))
    }
    else if(appState.activeObject === 1 && appState.activeGraphic === 3){
      array_data = []
      complete_mip_data.forEach((element) =>{
        complete_mip_columns.forEach((element2,index2)=>{
          if(index2 !== 0){
            array_data.push([element[0],element2,element[index2]])
          }
        })
      })
      return([['Actividades económicas X','Actividades económicas Y',coins[values.country]]].concat(array_data))
    }
    else if(appState.activeObject === 1 && appState.activeGraphic === 4){
      array_data = []
      summary_data.forEach((element) =>{
        summary_columns.forEach((element2,index2)=>{
          if(index2 !== 0){
            array_data.push([element[0],element2,element[index2]])
          }
        })
      })
      return([['Indicadores','Tecnologías','Valor']].concat(array_data))
    }
    else if(appState.activeObject === 1 && appState.activeGraphic === 5){
      array_data = []
      aggregate_value_columns.forEach((element,index) => {
        array_data.push([element,aggregate_value_data[index]])
      })
      return([['Tecnologías','Valor agregado ['+coins[values.country]+"]"]].concat(array_data))
    }
    else if(appState.activeObject === 1 && appState.activeGraphic === 6){
      array_data = []
      pib_columns.forEach((element,index) => {
        array_data.push([element,pib_data[index]])
      })
      return([['Tecnologías','PIB nacional ['+coins[values.country]+"]"]].concat(array_data))
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 1){
      array_data = []
      agregate_components_columns.forEach((element,index) =>{
        var array_data2 = [element]
        agregate_components_data.forEach((element2)=>{array_data2.push(element2.data[index])})
        array_data.push(array_data2)
      })
      return([['Tecnologías',agregate_components_data[0].name + " [" + coins[values.country]+"]",agregate_components_data[1].name + " [" + coins[values.country]+"]",agregate_components_data[2].name + " [" + coins[values.country]+"]"]].concat(array_data))
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 2){
      array_data = []
      porcentage_components_columns.forEach((element,index) =>{
        var array_data2 = [element]
        porcentage_components_data.forEach((element2)=>{array_data2.push(element2.data[index])})
        array_data.push(array_data2)
      })
      return([['Tecnologías','Porcentaje ' + agregate_components_data[0].name,'Porcentaje ' +agregate_components_data[1].name,'Porcentaje ' +agregate_components_data[2].name]].concat(array_data))
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 3){
      array_data = []
      porcentage_participation_columns.forEach((element,index) =>{
        var array_data2 = [element]
        porcentage_participation_data.forEach((element2)=>{array_data2.push(element2.data[index])})
        array_data.push(array_data2)
      })
      column_array = ['Elementos valor agregado']
      porcentage_participation_data.forEach((element)=>{
        column_array.push('Porcentaje ' + element.name)
      })
      return([column_array].concat(array_data))
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 4){
      array_data = []
      detail_compositions_data.forEach((element) =>{
        detail_compositions_columns.forEach((element2,index2)=>{
          if(index2 !== 0){
            array_data.push([element[0],element2,element[index2]])
          }
        })
      })
      return([['Detalle valor agregado','Tecnologías',coins[values.country]]].concat(array_data))
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 5){
      array_data = []
      pib_total_columns.forEach((element,index) =>{
        var array_data2 = [element]
        pib_total_data.forEach((element2)=>{array_data2.push(element2.data[index])})
        array_data.push(array_data2)
      })
      return([['Tecnologías',pib_total_data[0].name + " ["+coins[values.country]+"]",pib_total_data[1].name+ " ["+coins[values.country]+"]"]].concat(array_data))
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 20){
      array_data = []
      pib_total_columns_doughnut.forEach((element,index) =>{
        array_data.push([element, pib_total_data_doughnut[index]])
      })
      return([['Tecnologías',"Impacto PIB total "+coins[values.country]]].concat(array_data))
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 6){
      array_data = []
      porcentual_direct_contribution_columns.forEach((element,index) =>{
        array_data.push([element, porcentual_direct_contribution_data[index]])
      })
      return([['Tecnologías','Contribución porcentual']].concat(array_data))
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 7){
      array_data = []
      porcentual_indirect_contribution_columns.forEach((element,index) =>{
        array_data.push([element, porcentual_indirect_contribution_data[index]])
      })
      return([['Tecnologías','Contribución porcentual']].concat(array_data))
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 8){
      array_data = []
      porcentual_total_contribution_columns.forEach((element,index) =>{
        array_data.push([element, porcentual_total_contribution_data[index]])
      })
      return([['Tecnologías','Contribución porcentual']].concat(array_data))
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 9){
      array_data = []
      export_imports_columns.forEach((element,index) =>{
          var array_data2 = [element]
          export_imports_data.forEach((element2)=>{array_data2.push(element2.data[index])})
          array_data.push(array_data2)
      })
      return([['Tecnologías',export_imports_data[0].name + ' ['+coins[values.country]+"]",export_imports_data[1].name + ' ['+coins[values.country]+"]",export_imports_data[2].name + ' ['+coins[values.country]+"]"]].concat(array_data))
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 11){
      array_data = []
      capital_distribution_columns.forEach((element,index) =>{
        array_data.push([element,capital_distribution_data[index]])
      })
      return([['Tecnologías',location.state.capitaldistribution.data[0].x_index]].concat(array_data))
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 12){
      array_data = []
      remuneration_distribution_columns.forEach((element,index) =>{
        array_data.push([element,remuneration_distribution_data[index]])
      })
      return([['Tecnologías',location.state.remunerationdistribution.data[0].x_index]].concat(array_data))
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 13){
      array_data = []
      fiscal_impacts_data.forEach((element) =>{
        fiscal_impacts_columns.forEach((element2,index2)=>{
          if(index2 !== 0){
            array_data.push([element[0],element2,element[index2]])
          }
        })
      })
      return([['Indicadores','Tecnologías','Valor']].concat(array_data))
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 14){
      array_data = []
      production_multipliers_columns.forEach((element,index) =>{
        array_data.push([element,production_multipliers_data[index]])
      })
      return([['Actividades económicas','Multiplicadores del producto']].concat(array_data))
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 15){
      array_data = []
      gwh_contribution_columns.forEach((element,index) =>{
        array_data.push([element,gwh_contribution_data[index]])
      })
      return([['Tecnologías',location.state.gwhcontribution.data[0].x_index]].concat(array_data))
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 16){
      array_data = []
      pib_multipliers_columns.forEach((element,index) =>{
          var array_data2 = [element]
          pib_multipliers_data.forEach((element2)=>{array_data2.push(element2.data[index])})
          array_data.push(array_data2)
      })
      return([['Tecnologías',pib_multipliers_data[0].name,pib_multipliers_data[1].name,pib_multipliers_data[2].name]].concat(array_data))
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 17){
      array_data = []
      rent_multipliers_columns.forEach((element,index) =>{
          var array_data2 = [element]
          rent_multipliers_data.forEach((element2)=>{array_data2.push(element2.data[index])})
          array_data.push(array_data2)
      })
      return([['Tecnologías',rent_multipliers_data[0].name,rent_multipliers_data[1].name]].concat(array_data))
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 18){
      array_data = []
      inversion_multipliers_columns.forEach((element,index) =>{
        array_data.push([element,inversion_multipliers_data[index]])
      })
      return([['Tecnologías',location.state.inversionmultipliers.data[0].x_index]].concat(array_data))
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 19){
      array_data = []
      tech_importance_columns.forEach((element,index) =>{
        array_data.push([element,tech_importance_data[0][index]])
      })
      return([['Tecnología','Clasificación sectorial']].concat(array_data))
    }
    else if(appState.activeObject === 3 && appState.activeGraphic === 1){
      array_data = []
      direct_emissions_columns.forEach((element,index) =>{
          var array_data2 = [element]
          direct_emissions_data.forEach((element2)=>{array_data2.push(element2.data[index])})
          array_data.push(array_data2)
      })
      return([['Actividades económicas',direct_emissions_data[0].name,direct_emissions_data[1].name]].concat(array_data))
    }
    else if(appState.activeObject === 3 && appState.activeGraphic === 2){
      array_data = []
      carbon_footprint_columns.forEach((element,index) =>{
        array_data.push([element,carbon_footprint_data[index]])
      })
      return([['Actividades económicas',"Huella de carbono sobre valor agregado "+"[Kt CO2 eq/"+coins[values.country]+"]"]].concat(array_data))
    }
    else if(appState.activeObject === 3 && appState.activeGraphic === 3){
      array_data = []
      carbon_source_data.forEach((element) =>{
        carbon_source_columns.forEach((element2,index2)=>{
          if(index2 !== 0){
            array_data.push([element[0],element2,element[index2]])
          }
        })
      })
      return([['Actividades económicas X','Actividades económicas Y',' [Mt CO2 eq]']].concat(array_data))
    }
    else if(appState.activeObject === 3 && appState.activeGraphic === 4){
      array_data = []
      carbon_essh2_columns.forEach((element,index) =>{
        var array_data2 = [element]
        carbon_essh2_data.forEach((element2)=>{array_data2.push(element2.data[index])})
        array_data.push(array_data2)
      })
      column_array = ['Actividades económicas']
      carbon_essh2_data.forEach((element)=>{
        column_array.push(element.name + ' [MT CO2 eqv]')
      })
      return([column_array].concat(array_data))
    }
    else if(appState.activeObject === 3 && appState.activeGraphic === 5){
      array_data = []
      carbon_final_demand_data.forEach((element) =>{
        carbon_final_demand_columns.forEach((element2,index2)=>{
          if(index2 !== 0){
            array_data.push([element[0],element2,element[index2]])
          }
        })
      })
      return([['Actividades económicas X','Actividades económicas Y',' [KT CO2 eq/'+coins[values.country]+']']].concat(array_data))
    }
    else if(appState.activeObject === 3 && appState.activeGraphic === 6){
      array_data = []
      carbon_final_demand_essh2_columns.forEach((element,index) =>{
        var array_data2 = [element]
        carbon_final_demand_essh2_data.forEach((element2)=>{array_data2.push(element2.data[index])})
        array_data.push(array_data2)
      })
      column_array = ['Actividades económicas']
      carbon_final_demand_essh2_data.forEach((element)=>{
        column_array.push(element.name + ' [KT CO2 eq/'+coins[values.country]+']')
      })
      return([column_array].concat(array_data))
    }
    else if(appState.activeObject === 4 && appState.activeGraphic === 1){
      array_data = []
      induced_employment_columns.forEach((element,index) =>{
        var array_data2 = [element]
        induced_employment_data.forEach((element2)=>{array_data2.push(element2.data[index])})
        array_data.push(array_data2)
      })
      return([['Tecnologías',induced_employment_data[0].name,induced_employment_data[1].name]].concat(array_data))
    }
    else if(appState.activeObject === 4 && appState.activeGraphic === 2){
      array_data = []
      remuneration_analisis_data.forEach((element) =>{
        remuneration_analisis_columns.forEach((element2,index2)=>{
          if(index2 !== 0){
            array_data.push([element[0],element2,element[index2]])
          }
        })
      })
      return([['Indicadores','Tecnologías','Valor']].concat(array_data))
    }
    else if(appState.activeObject === 4 && appState.activeGraphic === 3){
      array_data = []
      historical_employees_columns.forEach((element,index) =>{
        var array_data2 = [element]
        historical_employees_data.forEach((element2)=>{array_data2.push(element2.data[index])})
        array_data.push(array_data2)
      })
      column_array = ['Año']
      historical_employees_data.forEach((element)=>{
        column_array.push(element.name)
      })
      return([column_array].concat(array_data))
    }
    else if(appState.activeObject === 4 && appState.activeGraphic === 4){
      array_data = []
      pib_employment_columns.forEach((element,index) =>{
        array_data.push([element,pib_employment_data[index]])
      })
      return([['Tecnologías','PIB / Empleados ['+coins[values.country]+']']].concat(array_data))
    }
    else if(appState.activeObject === 4 && appState.activeGraphic === 5){
      array_data = []
      final_demand_columns.forEach((element,index) =>{
          var array_data2 = [element]
          final_demand_data.forEach((element2)=>{array_data2.push(element2.data[index])})
          array_data.push(array_data2)
      })
      return([['Tecnologías',final_demand_data[0].name,final_demand_data[1].name]].concat(array_data))
    }
    else if(appState.activeObject === 4 && appState.activeGraphic === 6){
      array_data = []
      employment_gwh_columns.forEach((element,index) =>{
        array_data.push([element,employment_gwh_data[index]])
      })
      return([['Tecnologías','Empleos/GWh']].concat(array_data))
    }
    else if(appState.activeObject === 4 && appState.activeGraphic === 7){
      array_data = []
      labor_multipliers_columns.forEach((element,index) =>{
          var array_data2 = [element]
          labor_multipliers_data.forEach((element2)=>{array_data2.push(element2.data[index])})
          array_data.push(array_data2)
      })
      return([['Tecnologías',labor_multipliers_data[0].name,labor_multipliers_data[1].name,labor_multipliers_data[2].name]].concat(array_data))
    }
    else{
      return([[""]])
    }
  }


  //Generate titles of CSV function
  function CSVTitle(){
    if(appState.activeObject === 1 && appState.activeGraphic === 1){
      return(location.state.calibratedmip.title+".csv")
    }
    else if(appState.activeObject === 1 && appState.activeGraphic === 2){
      return(location.state.inverted.title+".csv")
    }
    else if(appState.activeObject === 1 && appState.activeGraphic === 3){
      return(location.state.calibratedmipcomplete.title+".csv")
    }
    else if(appState.activeObject === 1 && appState.activeGraphic === 4){
      return(location.state.summary.title+".csv")
    }
    else if(appState.activeObject === 1 && appState.activeGraphic === 5){
      return("Valor agregado.csv")
    }
    else if(appState.activeObject === 1 && appState.activeGraphic === 6){
      return("PIB nacional ["+coins[values.country]+"] .csv")
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 1){
      return(location.state.components.coponentes_PIB_values.title+".csv")
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 2){
      return(location.state.components.componentes_PIB_por_sector.title+".csv")
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 3){
      return(location.state.components.componentes_PIB_por_componentes_valor_agregado.title+".csv")
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 4){
      return(location.state.detailcomposition.title+".csv")
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 5){
      return(location.state.impactscontribution.PIBsDirectosIndirectosTotales.title+".csv")
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 20){
      return("Impacto total en el PIB de los sectores generación, almacenamiento y H2 en "+coins[values.country]+".csv")
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 6){
      return(location.state.impactscontribution.DirectContributionPercentage.title+".csv")
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 7){
      return(location.state.impactscontribution.InducedContributionPercentage.title+".csv")
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 8){
      return(location.state.impactscontribution.TotalContributionPercentage.title+".csv")
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 9){
      return(location.state.exportimports.title+".csv")
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 11){
      return(location.state.capitaldistribution.title+".csv")
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 12){
      return(location.state.remunerationdistribution.title+".csv")
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 13){
      return(location.state.fiscalimpact.title+".csv")
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 14){
      return(location.state.productionmultipliers.title+".csv")
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 15){
      return(location.state.gwhcontribution.title+".csv")
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 16){
      return(location.state.pibmultipliers.title+".csv")
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 17){
      return(location.state.rentmultipliers.title+".csv")
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 18){
      return(location.state.inversionmultipliers.title+".csv")
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 19){
      return(location.state.techimportance.title+".csv")
    }
    else if(appState.activeObject === 3 && appState.activeGraphic === 1){
      return(location.state.directemissions.title+".csv")
    }
    else if(appState.activeObject === 3 && appState.activeGraphic === 2){
      return(location.state.carbonfootprint.title+".csv")
    }
    else if(appState.activeObject === 3 && appState.activeGraphic === 3){
      return(location.state.carbonsource.title+".csv")
    }
    else if(appState.activeObject === 3 && appState.activeGraphic === 4){
      return(location.state.carbonessh2.title+".csv")
    }
    else if(appState.activeObject === 3 && appState.activeGraphic === 5){
      return(location.state.carbonfinaldemand.title+".csv")
    }
    else if(appState.activeObject === 3 && appState.activeGraphic === 6){
      return(location.state.carbonfinaldemandessh2.title+".csv")
    }
    else if(appState.activeObject === 4 && appState.activeGraphic === 1){
      return(location.state.inducedemployment.title+".csv")
    }
    else if(appState.activeObject === 4 && appState.activeGraphic === 2){
      return(location.state.remunerationanalisis.title+".csv")
    }
    else if(appState.activeObject === 4 && appState.activeGraphic === 3){
      return(location.state.historicalemployees.title+".csv")
    }
    else if(appState.activeObject === 4 && appState.activeGraphic === 4){
      return(location.state.pibemployment.title+".csv")
    }
    else if(appState.activeObject === 4 && appState.activeGraphic === 5){
      return(location.state.finaldemand.title+".csv")
    }
    else if(appState.activeObject === 4 && appState.activeGraphic === 6){
      return(location.state.employmentgwh.title+".csv")
    }
    else if(appState.activeObject === 4 && appState.activeGraphic === 7){
      return(location.state.labormultipliers.title+".csv")
    }
    else{
      return("")
    }
  }

  //Buttons display function
  function ButtonsDisplay(){
    if(appState.activeObject === 1){
      return(
        <div className='grid grid-cols-3 ml-auto'> 
          <button onClick={() => {toggleActive(null, 0)}} className={`w-20 h-12 m-1 ${toggleActiveStylesGraph(0)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize: "12px"}}>MIP Resumida</button>
          <button onClick={() => {toggleActive(null, 1)}} className={`w-20 h-12 m-1 ${toggleActiveStylesGraph(1)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize: "12px"}}>MIP Leontieff</button>
          <button onClick={() => {toggleActive(null, 2)}} className={`w-20 h-12 m-1 ${toggleActiveStylesGraph(2)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize: "12px"}}>MIP Completa</button>
          <button onClick={() => {toggleActive(null, 3)}} className={`w-20 h-12 m-1 ${toggleActiveStylesGraph(3)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize: "12px"}}>Resumen</button>
          <button onClick={() => {toggleActive(null, 4)}} className={`w-20 h-12 m-1 ${toggleActiveStylesGraph(4)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize: "12px"}}>Valor agregado</button>
          <button onClick={() => {toggleActive(null, 5)}} className={`w-20 h-12 m-1 ${toggleActiveStylesGraph(5)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize: "12px"}}>PIB nacional</button>
        </div>
      )
    }
    else if(appState.activeObject === 2){
      return(
        <div className='ml-auto'>
          <div style={{display: "flex", overflow: "auto", scrollSnapType: "x-mandatory", scrollPadding: "0", height: " 72px", width: "460px"}}> 
            <button onClick={() => {toggleActive(null, 0)}} className={`max-w-20 max-h-12 m-1 ${toggleActiveStylesGraph(0)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize:"10px", scrollSnapAlign:"start", flex:"0 0 25%"}}>Componentes valor agregado</button>
            <button onClick={() => {toggleActive(null, 1)}} className={`max-w-20 max-h-12 m-1 ${toggleActiveStylesGraph(1)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize:"10px", scrollSnapAlign:"start", flex:"0 0 25%"}}>Porcentaje participación componentes</button>
            <button onClick={() => {toggleActive(null, 2)}} className={`max-w-20 max-h-12 m-1 ${toggleActiveStylesGraph(2)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize:"10px", scrollSnapAlign:"start", flex:"0 0 25%"}}>Porcentaje participación total</button>
            <button onClick={() => {toggleActive(null, 4)}} className={`max-w-20 max-h-12 m-1 ${toggleActiveStylesGraph(4)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize:"10px", scrollSnapAlign:"start", flex:"0 0 25%"}}>Impacto PIB directo e indirecto</button>
            <button onClick={() => {toggleActive(null, 19)}} className={`max-w-20 max-h-12 m-1 ${toggleActiveStylesGraph(19)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize:"12px", scrollSnapAlign:"start", flex:"0 0 25%"}}>Impacto PIB total</button>
            <button onClick={() => {toggleActive(null, 5)}} className={`max-w-20 max-h-12 m-1 ${toggleActiveStylesGraph(5)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize:"10px", scrollSnapAlign:"start", flex:"0 0 25%"}}>Contribución porcentual PIB directo</button>
            <button onClick={() => {toggleActive(null, 6)}} className={`max-w-20 max-h-12 m-1 ${toggleActiveStylesGraph(6)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize:"10px", scrollSnapAlign:"start", flex:"0 0 25%"}}>Contribución porcentual PIB indirecto</button>
            <button onClick={() => {toggleActive(null, 7)}} className={`max-w-20 max-h-12 m-1 ${toggleActiveStylesGraph(7)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize:"10px", scrollSnapAlign:"start", flex:"0 0 25%"}}>Contribución porcentual PIB total</button>
            <button onClick={() => {toggleActive(null, 8)}} className={`max-w-20 max-h-12 m-1 ${toggleActiveStylesGraph(8)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize:"10px", scrollSnapAlign:"start", flex:"0 0 25%"}}>Exportaciones e importaciones</button>
            <button onClick={() => {toggleActive(null, 10)}} className={`max-w-20 max-h-12 m-1 ${toggleActiveStylesGraph(10)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize:"12px", scrollSnapAlign:"start", flex:"0 0 25%"}}>Distribución capital</button>
            <button onClick={() => {toggleActive(null, 11)}} className={`max-w-20 max-h-12 m-1 ${toggleActiveStylesGraph(11)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize:"10px", scrollSnapAlign:"start", flex:"0 0 25%"}}>Distribución remuneraciones</button>
            <button onClick={() => {toggleActive(null, 13)}} className={`max-w-20 max-h-12 m-1 ${toggleActiveStylesGraph(13)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize:"10px", scrollSnapAlign:"start", flex:"0 0 25%"}}>Multiplicadores del producto</button>
            <button onClick={() => {toggleActive(null, 14)}} className={`max-w-20 max-h-12 m-1 ${toggleActiveStylesGraph(14)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize:"12px", scrollSnapAlign:"start", flex:"0 0 25%"}}>Contribución GWh</button>
            <button onClick={() => {toggleActive(null, 15)}} className={`max-w-20 max-h-12 m-1 ${toggleActiveStylesGraph(15)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize:"10px", scrollSnapAlign:"start", flex:"0 0 25%"}}>Multiplicadores del PIB</button>
            <button onClick={() => {toggleActive(null, 16)}} className={`max-w-20 max-h-12 m-1 ${toggleActiveStylesGraph(16)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize:"10px", scrollSnapAlign:"start", flex:"0 0 25%"}}>Multiplicadores de renta</button>
            <button onClick={() => {toggleActive(null, 17)}} className={`max-w-20 max-h-12 m-1 ${toggleActiveStylesGraph(17)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize:"10px", scrollSnapAlign:"start", flex:"0 0 25%"}}>Multiplicadores de inversión</button>
          </div>
          <div style={{display: "flex", height: "48px", width: "460px"}}> 
            <button onClick={() => {toggleActive(null, 3)}} className={`max-w-20 max-h-12 m-1 ${toggleActiveStylesGraph(3)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize:"12px", scrollSnapAlign:"start", flex:"0 0 25%"}}>Detalle valor agregado</button>
            <button onClick={() => {toggleActive(null, 12)}} className={`max-w-20 max-h-12 m-1 ${toggleActiveStylesGraph(12)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize:"12px", scrollSnapAlign:"start", flex:"0 0 25%"}}>Impacto fiscal</button>
            <button onClick={() => {toggleActive(null, 18)}} className={`max-w-20 max-h-12 m-1 ${toggleActiveStylesGraph(18)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize:"12px", scrollSnapAlign:"start", flex:"0 0 25%"}}>Clasificación sectorial</button>
          </div>
        </div>
       
        
      )
    }
    else if(appState.activeObject === 3){
      return(
        <div className='grid grid-cols-3 ml-auto'>
          <button onClick={() => {toggleActive(null, 0)}} className={`max-w-20 h-12 m-1 ${toggleActiveStylesGraph(0)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize: "10px"}}>Emisiones directas y huella de carbono</button>
          <button onClick={() => {toggleActive(null, 1)}} className={`max-w-20 h-12 m-1 ${toggleActiveStylesGraph(1)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize: "10px"}}>Huella de carbono sobre valor agregado</button>
          <button onClick={() => {toggleActive(null, 2)}} className={`max-w-20 h-12 m-1 ${toggleActiveStylesGraph(2)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize: "10px"}}>Huella de carbono por fuente</button>
          <button onClick={() => {toggleActive(null, 3)}} className={`max-w-20 h-12 m-1 ${toggleActiveStylesGraph(3)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize: "10px"}}>Huella de carbono ESS+H2</button>
          <button onClick={() => {toggleActive(null, 4)}} className={`max-w-20 h-12 m-1 ${toggleActiveStylesGraph(4)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize: "10px"}}>Huella demanda final por fuente</button>
          <button onClick={() => {toggleActive(null, 5)}} className={`max-w-20 h-12 m-1 ${toggleActiveStylesGraph(5)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize: "10px"}}>Huella demanda final por fuente ESS+H2</button>
        </div>
      )
    }
    else if(appState.activeObject === 4){
      return(
        <div className='grid grid-cols-4 ml-auto'> 
          <button onClick={() => {toggleActive(null, 0)}} className={`max-w-20 h-12 m-1 ${toggleActiveStylesGraph(0)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize:"10px"}}>Empleo directo e indirecto</button>
          <button onClick={() => {toggleActive(null, 1)}} className={`max-w-20 h-12 m-1 ${toggleActiveStylesGraph(1)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize:"10px"}}>Análisis de remuneraciones</button>
          <button onClick={() => {toggleActive(null, 2)}} className={`max-w-20 h-12 m-1 ${toggleActiveStylesGraph(2)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize:"12px"}}>Empleo histórico</button>
          <button onClick={() => {toggleActive(null, 3)}} className={`max-w-20 h-12 m-1 ${toggleActiveStylesGraph(3)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize: "12px"}}>PIB por empleo</button>
          <button onClick={() => {toggleActive(null, 4)}} className={`max-w-20 h-12 m-1 ${toggleActiveStylesGraph(4)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize: "10px"}}>Demanda final por tecnología</button>
          <button onClick={() => {toggleActive(null, 5)}} className={`max-w-20 h-12 m-1 ${toggleActiveStylesGraph(5)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize:"12px"}}>Empleo por GWh</button>
          <button onClick={() => {toggleActive(null, 6)}} className={`max-w-20 h-12 m-1 ${toggleActiveStylesGraph(6)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize: "10px"}}>Multiplicadores de empleo</button>
        </div>
      )
    }
    else{
      return(<div></div>)
    }
  }

  //Description of graph and tables function
  function TextDisplay(){
    if (appState.activeObject === 1 && appState.activeGraphic === 1){
      return("MIP Resumida: La tabla muestra la MIP o Matriz Insumo Producto resumida del año y país ingresado resultante de la expansión de la actividad de generación eléctrica y posterior calibración según los datos bottom-up del sector eléctrico. Esta se agrupa por los sectores de actividades económicas para los ejes y sus valores corresponden a la moneda nacional medida en " + coins[values.country] +".")
    }
    else if (appState.activeObject === 1 && appState.activeGraphic === 2){
      return("MIP Leontieff: La tabla muestra la MIP o Matriz Insumo Producto inversa de Leontieff. Esta es resultante de la Matriz Insumo Producto expandida  de generación eléctrica y posteriormente calibrada según los datos bottom-up del sector eléctrico. Se agrupa por sector de la actividad económica para sus ejes y la suma de cada columna corresponde a los multiplicadores de la producción, teniendo de esta manera un valor en el orden de 1.")
    }
    else if (appState.activeObject === 1 && appState.activeGraphic === 3){
      return("MIP Completa: La tabla muestra la MIP o Matriz Insumo Producto completa resultante de la expansión de la actividad de generación eléctrica y posterior calibración según los datos bottom-up del sector eléctrico. Esta se agrupa por los sectores de actividades económicas para los ejes y sus valores corresponden a la moneda nacional medida en " + coins[values.country] +".")
    }
    else if (appState.activeObject === 1 && appState.activeGraphic === 4){
      return("Resumen Indicadores: La tabla muestra un resumen de los indicadores de la MIP Expandida. Las columnas de esta tabla corresponden a diferentes tecnologías, mientras que las filas son indicadores MIP y sus valores varían para cada una de estas.")
    }
    else if (appState.activeObject === 1 && appState.activeGraphic === 5){
      return("Valor agregado: En este gráfico se observa el total de valor agregado y sus porcentajes de los sectores de generación y almacenamiento para el año y país ingresado.")
    }
    else if (appState.activeObject === 1 && appState.activeGraphic === 6){
      return("PIB nacional: En este gráfico se observa el total de PIB nacional y sus porcentajes de los sectores de generación, almacenamiento y H2 para el año y país ingresado.")
    }
    else if (appState.activeObject === 2 && appState.activeGraphic === 1){
      return("Componentes valor agregado: En el gráfico pueden verse las relaciones del valor agregado, específicamente, el aporte al PIB de diferentes tecnologías para los componentes de remuneraciones de asalariados, excedente bruto de importación e impuestos netos sobre la producción.")
    }
    else if (appState.activeObject === 2 && appState.activeGraphic === 2){
      return("Porcentaje participación componentes: En el gráfico pueden verse las relaciones del valor agregado, específicamente, los porcentajes de participación de diferentes tecnologías para los componentes de remuneraciones de asalariados, excedente bruto de importación e impuestos netos sobre la producción.")
    }
    else if (appState.activeObject === 2 && appState.activeGraphic === 3){
      return("Porcentaje participación total: En el gráfico pueden verse las relacionados del valor agregado, específicamente los porcentajes de participación de los componentes del valor agregado (remuneraciones de aslariados, excedente bruto de explotación, impuestos netos sobre la producción y valor agregado) para diferentes tecnologías.")
    }
    else if (appState.activeObject === 2 && appState.activeGraphic === 4){
      return("Detalle valor agregado: En esta tabla pueden observarse los detalles de demanda y valor agregado del sector de generación eléctrica. Las columnas de esta tabla corresponden a diferentes tipos de tecnologías, mientras que sus filas corresponden a los indicadores medidos en " + coins[values.country] +".")
    }
    else if (appState.activeObject === 2 && appState.activeGraphic === 5){
      return("Impacto PIB directo e indirecto: En este gráfico se muestra el efecto directo e indirecto en el PIB. Las columnas representan diferentes tecnologías de los sectores de generación, almacenamiento y H2, mientras que sus valores corresponden a los impactos medidos en " + coins[values.country] +".")
    }
    else if (appState.activeObject === 2 && appState.activeGraphic === 20){
      return("Impacto PIB total: En este gráfico se observa el efecto total del PIB y sus porcentajes en los sectores de generación, almacenamiento y H2 para el año y país ingresado.")
    }
    else if (appState.activeObject === 2 && appState.activeGraphic === 6){
      return("Contribución porcentual PIB directo: En este gráfico se observa la contribución porcentual de diferentes tecnologías de los sectores de generación, almacenamiento y H2 respecto al PIB directo para el año y país ingresado.")
    }
    else if (appState.activeObject === 2 && appState.activeGraphic === 7){
      return("Contribución porcentual PIB indirecto: En este gráfico se observa la contribución porcentual de diferentes tecnologías de los sectores de generación, almacenamiento y H2 respecto al PIB indirecto para el año y país ingresado.")
    }
    else if (appState.activeObject === 2 && appState.activeGraphic === 8){
      return("Contribución porcentual PIB total: En este gráfico se observa la contribución porcentual de diferentes tecnologías de los sectores de generación, almacenamiento y H2 respecto al PIB total (sumando el directo e indirecto) para el año y país ingresado.")
    }
    else if (appState.activeObject === 2 && appState.activeGraphic === 9){
      return("Exportaciones e importaciones: En este gráfico se muestran las exportaciones, importaciones y exportaciones netas para diferentes tecnologías del año y país ingresados medidas en " + coins[values.country] +".")
    }
    else if (appState.activeObject === 2 && appState.activeGraphic === 10){
      return("Importaciones combustibles fósiles: El gráfico muestra el ratio de importaciones cde ombustibles fosiles medido en " + coins[values.country] +" por cada GWh producido de Electricidad RRNNCC.")
    }
    else if (appState.activeObject === 2 && appState.activeGraphic === 11){
      return("Distribución capital: En este gráfico se muestra el excedente bruto de capital medido en " + coins[values.country] +" de diferentes tecnologías para el año y país ingresados.")
    }
    else if (appState.activeObject === 2 && appState.activeGraphic === 12){
      return("Distribución remuneraciones: En este gráfico se muestra el total de remuneraciones medido en " + coins[values.country] +" de diferentes tecnologías para el año y país ingresados.")
    }
    else if (appState.activeObject === 2 && appState.activeGraphic === 13){
      return("Impacto fiscal: En esta tabla se muestra un resumen del impacto fiscal para el año y país seleccionado. Sus columnas corresponden a diferentes tecnologías, mientras que sus filas son diversos indicadores de impactos fiscales, cada uno con diferentes unidades de medidas.")
    }
    else if (appState.activeObject === 2 && appState.activeGraphic === 14){
      return("Multiplicadores del producto: En este gráfico se muestran los multiplicadores del producto de los sectores de generación, almacenamiento y H2. Este indicador indica cuanto aumenta la producción al aumentar en 1 unidad la demanda final. Las columnas del gráfico representan diferentes tecnologías y sus valores corresponden a los multiplicadores del producto.")
    }
    else if (appState.activeObject === 2 && appState.activeGraphic === 15){
      return("Contribución GWh: En este gráfico se muestra la contribución al PIB de diferentes tecnologías por GWh. Las columnas representan las tecnologías de generación y sus valores corresponden a la razón del valor agregado dividido por la generación, medida en " + coins[values.country] +".")
    }
    else if (appState.activeObject === 2 && appState.activeGraphic === 16){
      return("Multiplicadores del PIB: En este gráfico se muestran los multiplicadores del PIB para diferentes tipos de tecnologías. Estas indican el aumento del PIB cuando la demanda final aumenta en 1 unidad. Se observan 3 tipos tipos de multiplicadores, el directo, el indirecto y el total, siendo este una suma de los 2 anteriores.")
    }
    else if (appState.activeObject === 2 && appState.activeGraphic === 17){
      return("Multiplicadores de renta: En este gráfico se muestran los multiplicadores del efecto renta para diferentes tipos de tecnologías. Estas indican el aumento de la renta cuando la demanda final aumenta en 1 unidad. Se observan 2 tipos de multiplicadores, el de producción de renta total y el consumos intermedios de renta.")
    }
    else if (appState.activeObject === 2 && appState.activeGraphic === 18){
      return("Multiplicadores de inversión: En este gráfico se muestran los multiplicadores de inversión para diferentes tipos de tecnologías. Estas indican el aumento de la inversión cuando la demanda final aumenta en 1 unidad.")
    }
    else if (appState.activeObject === 2 && appState.activeGraphic === 19){
      return("Clasificación sectorial: La tabla muestra la clasificación sectorial de Rasmussen. Esta asigna diferentes sectores para cada tipo de tecnología en base a la matriz inversa de Leontieff.")
    }
    else if (appState.activeObject === 3 && appState.activeGraphic === 1){
      return("Emisiones directas y huella de carbono: El gráfico muestra las emisiones directas y la huella de carbono medidas en mega toneladas de CO2 equivalente para diferentes actividades económicas del año y país ingresados.")
    }
    else if (appState.activeObject === 3 && appState.activeGraphic === 2){
      return("Huella de carbono sobre valor agregado: El gráfico muestra el ratio de huella de carbon sobre el valor agregado para diferentes tecnologías, es decir, la huella de carbono de cada sector por cada mil de millones de pesos de aporte al PIB. Esta se mida en kilo toneladas de CO2 equivalente dividido en " + coins[values.country] +".")
    }
    else if (appState.activeObject === 3 && appState.activeGraphic === 3){
      return("Huella de carbono por fuente: En este gráfico se muestra el origen de la huella de carbono para todas las actividades económicas consideradas en las emisiones directas. Las columnas corresponden a la actividad económica que realiza la emisión, las filas corresponden a la actividad económica cuya demanda final provoca la emisión, y sus valores corresponden al resultado de estas medidas en mega toneladas de CO2 equivalente.")
    }
    else if (appState.activeObject === 3 && appState.activeGraphic === 4){
      return("Huella de carbono ESS+H2: En el gráfico se muestra el origen de las emisiones de la huella de carbono de los sectores de generación eléctrica, almacenamiento e hidrogeno verde. Las columnas representan las actividades económicas y sus valores la huella de carbono de diversos sectores medida en mega toneladas de CO2 equivalente.")
    }
    else if (appState.activeObject === 3 && appState.activeGraphic === 5){
      return("Huella demanda final por fuente: En este gráfico se muestra el origen de la huella de carbono para todas las actividades económicas consideradas en las emisiones directas por cada "+ coins[values.country] +" de demanda final. Las columnas corresponden a la actividad económica que realiza la emisión, las filas corresponden a la actividad económica cuya demanda final provoca la emisión, y sus valores corresponden al resultado de estas medidas en mega toneladas de CO2 equivalente por " + coins[values.country] +".")
    }
    else if (appState.activeObject === 3 && appState.activeGraphic === 6){
      return("Huella demanda final por fuente ESS+H2: En el gráfico se muestra el origen de las emisiones de la huella de carbono de los sectores de generación eléctrica, almacenamiento y H2 por cada "+ coins[values.country] +" de demanda final. Las columnas representan las actividades económicas y sus valores la huella de carbono de diversos sectores medida en kilo toneladas de CO2 equivalente por " + coins[values.country] +".")
    }
    else if (appState.activeObject === 4 && appState.activeGraphic === 1){
      return("Empleo directo e indirecto: En el gráfico se observa la cantidad total de empleos directos e indirectos generados para diferentes tecnologías del año y país seleccionados.")
    }
    else if (appState.activeObject === 4 && appState.activeGraphic === 2){
      return("Análisis de remuneraciones: En la tabla se muestra un análisis de remuneraciones de empleo del año y país ingresado. Las columnas de esta tabla corresponden a diversos tipos de tecnologías y las filas corresponden a los indicadores, cada una con diferentes unidades de medida.")
    }
    else if (appState.activeObject === 4 && appState.activeGraphic === 3){
      return("Empleo histórico: El gráfico muestra el avance histórico de los empleos directos generados para diferentes tipos de tecnologías.")
    }
    else if (appState.activeObject === 4 && appState.activeGraphic === 4){
      return("PIB por empleo: El gráfico muestra el PIB promedio generado por empleados medido en " + coins[values.country] +" para diferentes tipos de tecnologías del año y país seleccionados.")
    }
    else if (appState.activeObject === 4 && appState.activeGraphic === 5){
      return("Demanda final por tecnología: El gráfico muestra el ratio número de empleos totales y directos generados divididos por la demanda final para diferentes tipos de tecnologías del año y país seleccionados.")
    }
    else if (appState.activeObject === 4 && appState.activeGraphic === 6){
      return("Empleos por Gwh: El gráfico muestra la cantidad de empleos por GWh generado para diferentes tipos de tecnologías del año y país seleccionados.")
    }
    else if (appState.activeObject === 4 && appState.activeGraphic === 7){
      return("Multiplicadores de empleo: El gráfico muestra los multiplicadores de empleo directo, indirecto y totales (suma de los 2 anteriores) para diferentes tecnologías del año y país ingresados.")
    }
    else{
      return("Selecciona uno de los 4 indicadores de la zona superior (MIP, Economía, Emisiones y Empleo). Posteriormente selecciona uno de los módulos disponibles en la zona derecha para poder ver los gráficos o tablas del año y país ingresado de la MIP Expandida de EcoKinesis.")
    }
  }

  //Title display function
  function TitleDisplay(){
    if (appState.activeObject === 1){
      return("MIP Expandida " + capitalize(location.state.values.country) + " Línea Base")
    }
    else if (appState.activeObject === 2){
      return("Indicadores económicos " + capitalize(location.state.values.country) + " Línea Base")
    }
    else if (appState.activeObject === 3){
      return("Indicadores de emisiones " + capitalize(location.state.values.country) + " Línea Base")
    }
    if (appState.activeObject === 4){
      return("Indicadores de empleo " + capitalize(location.state.values.country) + " Línea Base")
    }
    else{
      return("MIP Expandida " + capitalize(location.state.values.country) + " Línea Base")
    }
  }

  //Page render
  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
      <div className='flex'> 
        <div className='w-3/5 h-14 grid grid-cols-4 gap-x-3 p-2' style={{backgroundColor: "white", border: "1px solid", borderRadius:"10px", borderColor: "gray"}}>
          <button onClick={() => {toggleActive(0)}} className={`max-h-10 max-w-36 rounded-lg ${toggleActiveStyles(0)}`}>MIP</button>
          <button onClick={() => {toggleActive(1)}} className={`max-h-10 max-w-36 rounded-lg ${toggleActiveStyles(1)}`}>Economía</button>
          <button onClick={() => {toggleActive(2)}} className={`max-h-10 max-w-36 rounded-lg ${toggleActiveStyles(2)}`}>Emisiones</button>
          <button onClick={() => {toggleActive(3)}} className={`max-h-10 max-w-36 rounded-lg ${toggleActiveStyles(3)}`}>Empleo</button>
        </div>
        <div className='grid grid-cols-5 ml-auto w-1/4 gap-x-4'>
          <div className='justify-center items-center text-center' style={{fontSize: "10px"}}><button onClick={() => {setIsModalOpen(true)}}><img src={expand} className='w-10 h-10' alt='' title='Pulsa para abrir la tabla o gráfico actual en pantalla completa'/>Expandir pantalla</button></div>

          <div className='justify-center items-center text-center' style={{fontSize: "10px"}}><button onClick={() => {navigate("/dashboard/expanded_mip/expanded_mip_reports", {state: {values,calibratedmip, inverted, calibratedmipcomplete, summary, aggregate_value_columns, aggregate_value_data, pib_columns, pib_data,components, detailcomposition,impactscontribution, exportimports, ratefosilrenovable, capitaldistribution, remunerationdistribution, fiscalimpact, productionmultipliers, gwhcontribution, pibmultipliers, rentmultipliers, inversionmultipliers, techimportance, directemissions, carbonfootprint, carbonsource, carbonessh2, carbonfinaldemand, carbonfinaldemandessh2, inducedemployment, remunerationanalisis, historicalemployees, pibemployment, finaldemand, employmentgwh, labormultipliers,

            resumed_mip_columns, resumed_mip_data, inverted_mip_columns, inverted_mip_data, complete_mip_data, complete_mip_columns, summary_columns, summary_data, direct_emissions_columns, direct_emissions_data, carbon_footprint_columns, carbon_footprint_data, carbon_source_columns, carbon_source_data, carbon_essh2_columns, carbon_essh2_datasets, carbon_final_demand_columns, carbon_final_demand_data, carbon_final_demand_essh2_columns, carbon_final_demand_essh2_datasets, agregate_components_columns, agregate_components_datasets, porcentage_components_columns, porcentage_components_datasets, porcentage_participation_columns, porcentage_participation_datasets, detail_compositions_columns, detail_compositions_data, pib_total_columns, pib_total_data, pib_total_columns_doughnut, pib_total_data_doughnut, porcentual_direct_contribution_columns, porcentual_direct_contribution_data, porcentual_indirect_contribution_columns, porcentual_indirect_contribution_data, porcentual_total_contribution_columns, porcentual_total_contribution_data, export_imports_columns, export_imports_datasets, capital_distribution_columns, capital_distribution_data, remuneration_distribution_columns, remuneration_distribution_data, fiscal_impacts_columns, fiscal_impacts_data, production_multipliers_columns, production_multipliers_data, gwh_contribution_columns, gwh_contribution_data, pib_multipliers_columns, pib_multipliers_datasets, rent_multipliers_columns, rent_multipliers_datasets, inversion_multipliers_columns, inversion_multipliers_data, tech_importance_columns, tech_importance_data, induced_employment_columns, induced_employment_datasets, remuneration_analisis_columns, remuneration_analisis_data, historical_employees_columns, historical_employees_datasets, pib_employment_columns, pib_employment_data, final_demand_columns, final_demand_data, employment_gwh_columns, employment_gwh_data, labor_multipliers_columns, labor_multipliers_data, coins
          }})}} title='Pulsa para ir al menú de guardado y descarga de reportes. Esta opción sólo está disponible para usuarios premium'><img src={save} className='w-10 h-10'alt=''/>Guardar reporte</button></div>

          <div className='justify-center items-center text-center' style={{fontSize: "10px"}}><button onClick={() => {navigate("/dashboard/expanded_mip/expanded_mip_reports", {state: {values,calibratedmip, inverted, calibratedmipcomplete, summary, aggregate_value_columns, aggregate_value_data, pib_columns, pib_data, components, detailcomposition,impactscontribution, exportimports, ratefosilrenovable, capitaldistribution, remunerationdistribution, fiscalimpact, productionmultipliers, gwhcontribution, pibmultipliers, rentmultipliers, inversionmultipliers, techimportance, directemissions, carbonfootprint, carbonsource, carbonessh2, carbonfinaldemand, carbonfinaldemandessh2, inducedemployment, remunerationanalisis, historicalemployees, pibemployment, finaldemand, employmentgwh, labormultipliers,

          resumed_mip_columns, resumed_mip_data, inverted_mip_columns, inverted_mip_data, complete_mip_data, complete_mip_columns, summary_columns, summary_data, direct_emissions_columns, direct_emissions_data, carbon_footprint_columns, carbon_footprint_data, carbon_source_columns, carbon_source_data, carbon_essh2_columns, carbon_essh2_datasets, carbon_final_demand_columns, carbon_final_demand_data, carbon_final_demand_essh2_columns, carbon_final_demand_essh2_datasets, agregate_components_columns, agregate_components_datasets, porcentage_components_columns, porcentage_components_datasets, porcentage_participation_columns, porcentage_participation_datasets, detail_compositions_columns, detail_compositions_data, pib_total_columns, pib_total_data, pib_total_columns_doughnut, pib_total_data_doughnut, porcentual_direct_contribution_columns, porcentual_direct_contribution_data, porcentual_indirect_contribution_columns, porcentual_indirect_contribution_data, porcentual_total_contribution_columns, porcentual_total_contribution_data, export_imports_columns, export_imports_datasets, capital_distribution_columns, capital_distribution_data, remuneration_distribution_columns, remuneration_distribution_data, fiscal_impacts_columns, fiscal_impacts_data, production_multipliers_columns, production_multipliers_data, gwh_contribution_columns, gwh_contribution_data, pib_multipliers_columns, pib_multipliers_datasets, rent_multipliers_columns, rent_multipliers_datasets, inversion_multipliers_columns, inversion_multipliers_data, tech_importance_columns, tech_importance_data, induced_employment_columns, induced_employment_datasets, remuneration_analisis_columns, remuneration_analisis_data, historical_employees_columns, historical_employees_datasets, pib_employment_columns, pib_employment_data, final_demand_columns, final_demand_data, employment_gwh_columns, employment_gwh_data, labor_multipliers_columns, labor_multipliers_data, coins
          }})}} title='Pulsa para ir al menú de guardado y descarga de reportes. Esta opción sólo está disponible para usuarios premium'><img src={download} className='w-10 h-10' alt=''/>Descargar reporte</button></div>

          {CSVRender(user_type)}

          <div className='justify-center items-center text-center ' style={{fontSize: "10px"}}><button onClick={() => {navigate("/dashboard/expanded_mip/main", {state: {values}})}}><img src={edit} className='w-10 h-10' alt='' title='Pulsa para volver a la pantalla de selección de datos con el último año y país seleccionado'/>Volver a selección</button></div>
        </div>
      </div>
      <div className='mt-1 mb-1'><p className='text-3xl font-extrabold tracking-tight text-slate-900'>{TitleDisplay()}</p></div>
      <div className='flex gap-4'>
        <h4 className='font-bold text-gray-400 w-3/5 text-sm mt-1'>{TextDisplay()}</h4>
        <div className="ml-auto">
          {ButtonsDisplay()}
        </div>
      </div>
      <div className='mt-3' style={{maxHeight: "490px"}}>
          {GraphicDisplay()}
      </div>
      <FullScreen isOpen={isModalOpen} closeModal={() => setIsModalOpen(false)} item={GraphicDisplay()}/>
    </div>
  )
}

export default ExpandedMIPResults