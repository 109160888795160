//Imports
import {React} from 'react';
import { Header, Button } from '../../components';
import { Link } from 'react-router-dom';
import { useStateContext } from '../../contexts/ContextProvider';
import bd_logo from '../../assets/bd_logo.svg';
import area from "../../assets/area_chart_FILL0_wght300_GRAD0_opsz24.svg"
import reports from "../../assets/folder_copy_FILL0_wght300_GRAD0_opsz24.svg"
import mip from "../../assets/list_FILL0_wght300_GRAD0_opsz24.svg"
import edit from "../../assets/selector.svg"

//Home Page of Dashboard
const Home = () => {
  //Color of page
  const { currentColor, currentMode }= useStateContext();

  //Render of home
  return (
    <div className={currentMode === 'Dark' ? 'dark': ''}>
      <div className='m-2 md:m-10 p-2 md:p-10 dark:bg-stone-400 bg-white rounded-3xl'>
      <Header title="Bienvenid@ nuevamente a EcoKinesis"/>
      <div className='mb-4'>
       EcoKinesis es una plataforma web en donde puedes verificar información de la base de datos de diferentes países y años sobre el cambio climático, ver las matrices de insumo producto o MIPs expandidas o realizar simulaciones de cambio climático para el futuro. Pulsa los botones de la barra izquierda para acceder a cada sección de EcoKinesis.
      </div>
      <Header title="Acceso rápido"/>
      <div className='grid grid-cols-5 gap-5'>
      <div className='max-w-56'>
          <div className='max-h-32 dark:bg-stone-500 bg-light-gray rounded-lg'>
            <div className='grid grid-cols-1 justify-items-center'>
              <img src={edit} width="60px" height="60px" className='mt-3' alt=''/>
              <div className='text-gray-400'><strong style={{fontSize: "14px"}}>Selección de año y país</strong></div>
            </div>
          </div>
          <div className="flex justify-center items-center mb-1">
            <Link to="/dashboard/selector" className='w-full' >
                <Button
                  color="white"
                  bgColor={currentColor}
                  text="Seleccionar datos"
                  borderRadius="10px"
                  size="md"
                  width="full"
                />
            </Link>
          </div>
          <div className='max-w-56 text-xs'>
            Selecciona un año y país base para utilizar en tus siguientes simulaciones.
          </div>
        </div>

        <div className='max-w-56'>
          <div className='max-h-32 dark:bg-stone-500 bg-light-gray rounded-lg'>
            <div className='grid grid-cols-1 justify-items-center'>
              <img src={bd_logo} width="60px" height="60px" className='mt-3' alt=''/>
              <div className='text-gray-400'><strong style={{fontSize: "14px"}}>Base de datos</strong></div>
            </div>
          </div>
          <div className="flex justify-center items-center mb-1">
            <Link to="/dashboard/database" className='w-full' >
                <Button
                  color="white"
                  bgColor={currentColor}
                  text="Revisar Base de Datos"
                  borderRadius="10px"
                  size="md"
                  width="full"
                />
            </Link>
          </div>
          <div className='max-w-56 text-xs'>
            Verifica las tablas y gráficos de la base de datos de EcoKinesis.
          </div>
        </div>

        <div className='max-w-56'>
          <div className='max-h-32 dark:bg-stone-500 bg-light-gray rounded-lg'>
            <div className='grid grid-cols-1 justify-items-center'>
              <img src={mip} width="60px" height="60px" className='mt-3' alt=''/>
              <div className='text-gray-400'><strong style={{fontSize: "14px"}}>MIP Expandida</strong></div>
            </div>
          </div>
          <div className="flex justify-center items-center mb-1">
            <Link to="/dashboard/expanded_mip" className='w-full' >
                <Button
                  color="white"
                  bgColor={currentColor}
                  text="Revisar MIPs Expandidas"
                  borderRadius="10px"
                  size="md"
                  width="full"
                />
            </Link>
          </div>
          <div className='max-w-56 text-xs'>
            Verifica las tablas y gráficos de la MIP Expandida de EcoKinesis.
          </div>
        </div>

        <div className='max-w-56'>
          <div className='max-h-32 dark:bg-stone-500 bg-light-gray rounded-lg'>
            <div className='grid grid-cols-1 justify-items-center'>
              <img src={area} width="60px" height="60px" className='mt-3' alt=''/>
              <div className='text-gray-400'><strong style={{fontSize: "14px"}}>Simulación de escenarios</strong></div>
            </div>
          </div>
          <div className="flex justify-center items-center mb-1">
            <Link to="/dashboard/simulations" className='w-full' >
                <Button
                  color="white"
                  bgColor={currentColor}
                  text="Comenzar a simular"
                  borderRadius="10px"
                  size="md"
                  width="full"
                />
            </Link>
          </div>
          <div className='max-w-56 text-xs'>
            Genera tus propias simulaciones en diferentes tipos de escenarios.
          </div>
        </div>

        <div className='max-w-56'>
          <div className='max-h-32 dark:bg-stone-500 bg-light-gray rounded-lg'>
            <div className='grid grid-cols-1 justify-items-center'>
              <img src={reports} width="60px" height="60px" className='mt-3' alt=''/>
              <div className='text-gray-400'><strong style={{fontSize: "14px"}}>Reportes</strong></div>
            </div>
          </div>
          <div className="flex justify-center items-center mb-1">
            <Link to="/dashboard/reports" className='w-full' >
                <Button
                  color="white"
                  bgColor={currentColor}
                  text="Ver mis reportes"
                  borderRadius="10px"
                  size="md"
                  width="full"
                />
            </Link>
          </div>
          <div className='max-w-56 text-xs'>
            Revisa los resultados de tus simulaciones guardadas en tu cuenta.
          </div>
        </div>

      </div>
        <div className='mt-5 p-5 rounded-3xl' style={{backgroundColor: currentColor}}>
          <div className='flex justify-center items-center gap-4'>
            <div className='white w-3/4 pl-5'><strong>Da el siguiente paso y adquiere tu membresía para desbloquear todas las funciones de EcoKinesis.</strong></div>
            <Link to="/dashboard/premium">
              <Button
                    color="white"
                    bgColor="blue"
                    text="Contratar"
                    borderRadius="10px"
                    size="md"
                    width="48"
                />
            </Link>
          </div>
        </div>
    </div>
    </div>
    
  )
}

export default Home