//Imports
import React from "react";
import PersonalizedMessage from "../../components/PersonalizedMessage";
import { Link } from "react-router-dom";

//Not Found page
function NotFound() {
    return (
        <div className="text-white">
            <Link to="/"><PersonalizedMessage
        title="Página no encontrada 😔"
        secondary="Vuelve a iniciar sesión en plataforma.ecokinesis.cl"
    /></Link></div>
        
    );
}

export default NotFound;