//Return colors of diferent graphs
function graphColors(graph){
    var colors = []
    const base = " (MIP Año Base)"
    graph.forEach((element) => {
        if (element === "fósil" || element === "Fósil" || element === ("Fósil" + base)){
            colors.push('#4c4c4c')
        }
        else if (element === "hidráulica" || element === "Hidráulica" || element === "Hidráulica embalse" || element === ("Hidráulica" + base)){
            colors.push('#031aa8')
        }
        else if (element === "Hidráulica de pasada"){
            colors.push("aqua")
        }
        else if (element === "Mini hidráulica"){
            colors.push("#425df9")
        }
        else if (element === "biomasa y biogas" || element === "Biomasa y biogas" || element === "Biogas" || element === ("Biomasa y biogas" + base)){
            colors.push('#30d072')
        }
        else if (element === "solar" || element === "Solar" || element === "Solar fotovoltaica" || element === ("Solar" + base)){
            colors.push('#f1c732')
        }
        else if (element === "eólica" || element === "Eólica" || element === ("Eólica" + base)){
            colors.push('#888fd4')
        }
        else if (element === "geotermia" || element === "Geotermia" || element === ("Geotermia" + base)){
            colors.push('#e1737e')
        }
        else if (element === "almacenamiento corto plazo" || element === "Almacenamiento corto plazo" || element === ("Almacenamiento corto plazo" + base)){
            colors.push('#db3a46')
        }
        else if (element === "almacenamiento largo plazo" || element === "Almacenamiento largo plazo" || element === ("Almacenamiento largo plazo" + base)){
            colors.push('#f78a55')
        }
        else if (element === "h2" || element === "H2" || element === ("H2" + base)){
            colors.push('#6ec30f')
        }
        else if (element === "total generación" || element === "Total generación"){
            colors.push('#d80268')
        }
        else if (element === "total almacenamiento" || element === "Total almacenamiento"){
            colors.push('#black')
        }
        else if (element === "agricultura" || element === "Agricultura"){
            colors.push('#a1db70')
        }
        else if (element === "minería" || element === "Minería" || element === "Mineria" || element === "Minería del cobre"){
            colors.push('#7e4529')
        }
        else if (element === "Mineria de otros productos"){
            colors.push('#ffeda4')
        }
        else if (element === "industria" || element === "Industria"){
            colors.push('#666666')
        }
        else if (element === "transmisión eléctrica" || element === "Transmisión eléctrica"){
            colors.push('#a900e4')
        }
        else if (element === "distribución eléctrica" || element === "Distribución eléctrica"){
            colors.push('#bb46e8')
        }
        else if (element === "servicios" || element === "Servicios"){
            colors.push('#425df9')
        }
        else if (element === "transporte" || element === "Transporte"){
            colors.push('#d07fed')
        }
        else if (element === "otros servicios" || element === " Otros servicios" || element === "Otros servicios"){
            colors.push('#df4c93')
        }
        else if (element === "otras actividades económicas" || element === "Otras actividades económicas"){
            colors.push('#82d2e8')
        }
        else if (element === "biomasa" || element === "Biomasa"){
            colors.push('#58dd92')
        }
        else if (element === "carbón" || element === "Carbón" || element === "extracción de carbón" || element === "Extracción de carbón"){
            colors.push('#000000')
        }
        else if (element === "Rumbo a carbono neutralidad"){
            colors.push('blue')
        }
        else if (element === "gas natural" || element === "Gas natural"){
            colors.push('#565ebf')
        }
        else if (element === "otro carbón bituminoso" || element === "Otro carbón bituminoso"){
            colors.push('#b3b3b3')
        }
        else if (element === "petroleo diessel" || element === "Petroleo diessel" || element === "Petróleo diésel" || element === "Petróleo y gas natural"){
            colors.push('#262626')
        }
        else if (element === "EGA"){
            colors.push("#d61873")
        }
        else if (element === "Construcción"){
            colors.push("#878787")
        }
        else if (element === "Com-Rest-Hot" || element === "Com-Res-Hot"){
            colors.push("#f65c02")
        }
        else if (element === "Resto Ss."){
            colors.push("#f46729")
        }
        else if (element === "Consumo hogares"){
            colors.push("#47aee3")
        }
        else if (element === "Resto Demanda Final" || element === "Resto demanda Final"){
            colors.push("#a27c6c")
        }
        else if (element === "Consumo Gobierno"){
            colors.push("#878787") 
        }
        else if (element === "Consumo privado"){
            colors.push("#47aee3") 
        }
        else if (element === "Exportaciones"){
            colors.push("#f1c732") 
        }
        else if (element === "Exportaciones de bienes y servicios"){
            colors.push("#f1c732") 
        }
        else if (element === "Inversión"){
            colors.push("#db3a46") 
        }
        else if (element === "Agri-Pec-Sil-Pes"){
            colors.push("#a1db70")
        }
        else if (element === "Industria manufacturera"){
            colors.push("#666666")
        }
        else if (element === "Emisiones Directas [Mt CO2 eq]"){
            colors.push("#425df9")
        }
        else if (element === "Huella de Carbono [Mt CO2 eq]"){
            colors.push("#4c4c4c")
        }
        else if(element === "Empleos directos"){
            colors.push("#47aee3")
        }
        else if(element === "Empleos indirectos"){
            colors.push("#f46729")
        }
        else if(element === "Empleos totales"){
            colors.push("green")
        }
        else if(element === "Comercio, Restaurantes y Hotelería"){
            colors.push("yellow")
        }
        else if(element === "Resto servicios"){
            colors.push("#565ebf")
        }
        else if(element === "Rate empleos totales/Demanda final [-/MMCLP]"){
            colors.push("#425df9")
        }
        else if(element === "Rate empleos directos/Demanda final [-/MMCLP]"){
            colors.push("orange")
        }
        else if (element === "Multiplicador de empleo directo"){
            colors.push("blue")
        }
        else if(element === "Multiplicador de empleo indirecto"){
            colors.push("orange")
        }
        else if (element === "Multiplicador de empleo total"){
            colors.push("green")
        }
        else if(element === "Remuneraciones de asalariados"){
            colors.push("blue")
        }
        else if(element === "Excedente bruto de explotación"){
            colors.push("orange")
        }
        else if(element === "Impuestos netos sobre la producción"){
            colors.push("green")
        }
        else if(element === "PIB directo"){
            colors.push("blue")
        }
        else if(element === "PIB [MMCLP]"){
            colors.push("blue")
        }
        else if(element === "PIB indirecto"){
            colors.push("yellow")
        }
        else if(element === "PIB / Empleados [MMCLP]"){
            colors.push("yellow")
        }
        else if(element === "Cantidad de empleados"){
            colors.push("green")
        }
        else if(element === "Importaciones"){
            colors.push("blue")
        }
        else if(element === "Importaciones de bienes y servicios"){
            colors.push("blue")
        }
        else if (element === "Exportaciones netas"){
            colors.push("green")
        }
        else if (element === "Rate importaciones sobre generación RRNNCC"){
            colors.push("grey")
        }
        else if (element === "Total"){
            colors.push("orange")
        }
        else if (element === "Total sector generación" || element === ("Total sector generación" + base)){
            colors.push("orange")
        }
        else if (element === "Promedio"){
            colors.push("green")
        }
        else if(element === "Multiplicador de PIB total"){
            colors.push("blue")
        }
        else if(element === "Multiplicador de PIB directo"){
            colors.push("orange")
        }
        else if(element === "Multiplicador de PIB indirecto"){
            colors.push("green")
        }
        else if(element === "Multiplicador de renta producción total"){
            colors.push("blue")
        }
        else if(element === "Multiplicador de renta consumos intermedios"){
            colors.push("orange")
        }
        else if(element === "Escenario"){
            colors.push("red")
        }
        else if(element === "Transición acelerada"){
            colors.push("orange")
        }
        else if(element === "Recuperación lenta post-Covid"){
            colors.push("green")
        }
        else if(element === "Valor agregado"){
            colors.push("gray")
        }
        else if (element === "Cogeneración"){
            colors.push("#BEC290")
        }
        else if (element === "Fuel oil"){
            colors.push("#C29090")
        }
        else if (element === "Solar CSP"){
            colors.push("#976F0C")
        }
    })
    return(colors)
}

export default graphColors
