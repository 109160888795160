//Imports
import {React, useEffect, useState, useContext} from 'react'
import { UserContext } from '../../../contexts/UserContext';
import avatar from '../../../data/avatar.jpg'
import { Header } from '../../../components';
import { Button } from '../../../components';
import { Link, useNavigate} from 'react-router-dom';
import {API_PORT} from "../../../API_PORT"
import edit from "../../../assets/edit.svg"
import capitalize from '../../../hook/capitalize';
import ProfesionCodeText2 from '../AdminUser/ProfesionCodeText2';
import LoadingScreen from '../../../components/LoadingScreen';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { useStateContext } from '../../../contexts/ContextProvider';
import UpdateToken from '../../../hook/UpdateToken';

//Main function
const Account = () => {
  const {currentColor} = useStateContext();
  const navigate = useNavigate();
  const [users, setUsers] = useState([])
  const [file, setFile] = useState(null);
  const [loadingscreen, setLoadingScreen] = useState({started: true});
  const [open, setOpen] = useState(true);
  const userContext = useContext(UserContext);
  const token = userContext.value.access_token;
  const { setValue } = useContext(UserContext);

  const [values, setValues] = useState({
    "email": "",
    "username": "",
    "first_name": "",
    "last_name": "",
    "user_type": "",
    "profesion_level": "0",
    "profesion_code": "0",
    "institution_name": "",
    "country": "",
    "current_pass": "",
  });

  //Function for obtain profesion levels
  function ProfesionLevel(level){
    if (level === 1){
        return("Academia")
    }
    else if (level === 2){
        return("Sector Público")
    }
    else if (level === 3){
        return("Sector Privado")
    }
    else if (level === 4){
        return("Organización no gubernamental")
    }
    else {
        return("Sin definir")
    }

  }

  const options2 = [
    { value: users?.profesion_level, label: ProfesionLevel(users?.profesion_level)},
    { value: "1", label: "Academia"}, 
    { value: "2", label: "Sector Público"},
    { value: "3", label: "Sector Privado"},
    { value: "4", label: "Organización no gubernamental"},
  ];

  const options3 = [
    { value: users?.country, label: users?.country},
    { value: "Argentina", label: "Argentina"},
    { value: "Bolivia", label: "Bolivia"},
    { value: "Brasil", label: "Brasil"},
    { value: "Chile", label: "Chile"},
    { value: "Colombia", label: "Colombia"},
    { value: "Costa Rica", label: "Costa Rica"},
    { value: "Ecuador", label: "Ecuador"},
    { value: "España", label: "España"},
    { value: "Estados Unidos", label: "Estados Unidos"},
    { value: "México", label: "México"},
    { value: "Paraguay", label: "Paraguay"},
    { value: "Perú", label: "Perú"},
    { value: "Uruguay", label: "Uruguay"},
    { value: "Venezuela", label: "Venezuela"},
  ];

  //Get User Data
  useEffect(() => {
    fetch(API_PORT+"/admin/users_accounts/user/get_data/?token="+token, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json',
        'Accept': 'application/json' 
      }
      })
      .then(res => {
        if (!res.ok) {
          throw new Error(`HTTP error! status: ${res.status}`);
        }
        return res.json();
      })
      .then(data => {
        setUsers(data);
        setOpen(false);
        setLoadingScreen(prevState => ({ ...prevState, started: false }));
      })
      .catch(error => {
        console.error("Error fetching data:", error); 
        alert("No se han podido obtener tus datos. Recarga tu sesión o inténtalo más tarde.")
        setOpen(false);
        setLoadingScreen(prevState => ({ ...prevState, started: false }));
      });
    fetch(API_PORT+"/admin/users_accounts/user/get_user_photo/?token="+token, {
      method: 'GET',
      headers: {
        'Accept': 'application/json'
      }
      }).then(response => {
        if (!response.ok) {
          throw new Error('User don´t have photo');
        }
        return response.blob(); // Obtener el contenido como un blob (Binary Large Object)
      })
      .then(blob => {
        setFile(blob); // Almacenar el blob en el estado 'file'
      })
      .catch(error => {
        console.error('Fetch error:', error);
      });
    }, [token]);

  const handleInput = (event) =>{
      setValues(prev => ({...prev, [event.target.name]:event.target.value}))
  };

  const ISCOCodes= {
    0: [{ value: "0", label: ""}],
    1: [ 
      { value: "1", label: "Ingeniería"}, 
      { value: "2", label: "Sector Económico"},
      { value: "3", label: "Ciencias de la administración"},
      { value: "4", label: "Ciencias sociales"},
      { value: "5", label: "Sector medioambiental"}, 
      ],
    2: [ 
      { value: "1", label: "Ingeniería"}, 
      { value: "2", label: "Sector Económico"},
      { value: "3", label: "Ciencias de la administración"},
      { value: "4", label: "Ciencias sociales"},
      { value: "5", label: "Sector medioambiental"}
      ],
    3: [ 
      { value: "1", label: "Ingeniería"}, 
      { value: "2", label: "Sector Económico"},
      { value: "3", label: "Ciencias de la administración"},
      { value: "4", label: "Ciencias sociales"},
      { value: "5", label: "Sector medioambiental"}, 
      ],
    4: [ 
      { value: "1", label: "Ingeniería"}, 
      { value: "2", label: "Sector Económico"},
      { value: "3", label: "Ciencias de la administración"},
      { value: "4", label: "Ciencias sociales"},
      { value: "5", label: "Sector medioambiental"}
      ],
  }

  //Update elements values
  function updateValues(){
    if(values.email === ""){
      values.email =users.email
    }
    if(values.username === ""){
      values.username =users.username
    }
    if(values.first_name === ""){
      values.first_name =users.first_name
    }
    if(values.last_name === ""){
      values.last_name = users.last_name
    }
    if(values.profesion_level === "0"){
      values.profesion_level = users.profesion_level
    }
    if(values.profesion_code === "0"){
      values.profesion_code = users.profesion_code
    }
    if(values.institution_name === ""){
      values.institution_name =users.institution_name
    }
    if(values.country === ""){
      values.country =capitalize(users.country)
    }
  }

  //Change data endpoint
  const handleSubmit = async (event) =>{
    event.preventDefault();
    if(values.current_pass !== ""){
      updateValues()
      setOpen(true);
      setLoadingScreen(prevState => {return {...prevState, started: true}});
      const new_body={
        "current_pass": values.current_pass,
        "new_username": values.username,
        "new_first_name": values.first_name,
        "new_last_name": values.last_name,
        "new_institution_name": values.institution_name,
        "new_profesion_level": values.profesion_level,
        "new_profesion_code": values.profesion_code,
        "new_country": values.country
      }

      try {
        const emailResponse = await fetch(API_PORT+`/admin/users_accounts/user/modify_email/?token=${token}`, {
          method: 'PATCH',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({"current_pass": values.current_pass, "new_email": values.email}),
        });
  
        if (!emailResponse) {
          throw new Error('Error al actualizar el correo electrónico');
        }
        const response = await fetch(API_PORT+"/admin/users_accounts/user/modify_data/?token="+token, {
          method: 'PATCH',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(new_body),
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        if(data.detail === "Usuario modificado exitosamente"){
          const newToken = await UpdateToken(values.email, values.current_pass);
          setValue({
            access_token: newToken.access_token,
            user_photo: newToken.photo_URL,
            username: values.username,
            first_name: values.first_name,
            last_name: values.last_name
          });
          alert("Los datos de usuario han sido modificados exitosamente.");
          navigate("/dashboard/home");
        }
        else{
          alert(data.detail)
        }
        

      } catch (error) {
        console.log(error)
        alert("No se han podido actualizar tus datos, vuelve a intentarlo.")
        setOpen(false);
        setLoadingScreen(prevState => {return {...prevState, started: false}});
      }
    }
    else{
      alert("Debes ingresar tu contraseña actual para poder modificar tus datos.")
    }
  }

  //Render image function
  function ImageRender(){
    if(file){
      return(<img className="rounded-full h-24 w-24" style={{border: "2px solid", borderColor: currentColor}} src={URL.createObjectURL(file)} alt=''/>)
    }
    else{
      return(<img className="rounded-full h-24 w-24" style={{border: "2px solid", borderColor: currentColor}} src={avatar}alt="user-profile"/>)
    }
  }

  //User Type text
  function UserType(type){
    if(type === "basic"){return("Usuario Básico")}
    else if(type === "premium"){return("Usuario Premium")}
    else if(type === "admin"){return("Administrador")}
    else{return("")}
  }

  //Main render
  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
      <Header title="Mi Perfil"/>
      <div className="flex gap-1">
        <div className='mb-4 w-1/5' title='Actualizar foto de perfil'>
          <Link to ="/dashboard/account/edit_photo">{ImageRender()}<img className="ml-9" src={edit} alt=''/></Link>
          <strong>{UserType(users.user_type)}</strong>
        </div>
        <div className='w-1/5'>
          <p>Nombre</p>
          <div style={{display:"flex", alignItems:"center", color: "grey", border: "1px solid", borderRadius:"10px", height:"40px", marginBottom: "20px", maxWidth: "240px"}}>
            <label htmlFor='first_name'></label>
            <input type='string' name='first_name' placeholder={users?.first_name} className='form-control rounded-0 m-1' onChange={handleInput} style={{maxWidth:"220px"}}></input>
          </div>
          <p>Apellido</p>
          <div style={{display:"flex", alignItems:"center", color: "grey", border: "1px solid", borderRadius:"10px", height:"40px", marginBottom: "20px", maxWidth: "240px"}}>
            <label htmlFor='last_name'></label>
            <input type='string' name='last_name' placeholder={users?.last_name} className='form-control rounded-0 m-1' onChange={handleInput} style={{maxWidth:"220px"}}></input>
          </div>
          <p>Nombre de usuario</p>
          <div style={{display:"flex", alignItems:"center", color: "grey", border: "1px solid", borderRadius:"10px", height:"40px", marginBottom: "20px", maxWidth: "240px"}}>
            <label htmlFor='last_name'></label>
            <input type='string' name='username' placeholder={users?.username} className='form-control rounded-0 m-1' onChange={handleInput} style={{maxWidth:"230px"}}></input>
          </div>
        </div>

        <div className='w-1/5'>
          <p>Email</p>
          <div style={{display:"flex", alignItems:"center", color: "grey", border: "1px solid", borderRadius:"10px", height:"40px", marginBottom: "20px", maxWidth: "240px"}}>
            <input type='string' name='email' placeholder={users?.email} className='form-control rounded-0 m-1' onChange={handleInput} style={{maxWidth:"230px"}}></input>
          </div>
          <p>Área Ocupacional</p>
          <div style={{display:"flex", alignItems:"center", color: "grey", border: "1px solid", borderRadius:"10px", height:"40px", marginBottom: "20px", maxWidth: "240px", fontSize: "15px"}}>
            <select type='string' name='profesion_level'onChange={handleInput} className='form-select rounded-0 m-1' style={{maxWidth: "210px"}}>
                {options2.map(option =>(<option value={option.value}>{option.label}</option>))}
            </select>
          </div>
          <p>Profesión</p>
          <div style={{display:"flex", alignItems:"center", color: "grey", border: "1px solid", borderRadius:"10px", height:"40px", marginBottom: "20px", maxWidth: "240px", fontSize: "15px"}}>
            <select type='string' name='profesion_code'onChange={handleInput} className='form-select rounded-0 m-1' style={{maxWidth: "210px"}}>
              <option value={users?.profesion_code}>{ProfesionCodeText2(users?.profesion_code)}</option>
              {ISCOCodes[values.profesion_level]?.map(option =>(
                                <option value={option.value}>{option.label}</option>
                            ))}
            </select>
          </div>
        </div>

        <div className='w-1/5'>
        <p>Institución</p>
          <div style={{display:"flex", alignItems:"center", color: "grey", border: "1px solid", borderRadius:"10px", height:"40px", marginBottom: "20px", maxWidth: "240px"}}>
            <label htmlFor='institution_name'></label>
            <input type='string' name='institution_name' placeholder={users?.institution_name} className='form-control rounded-0 m-1' onChange={handleInput}></input>
          </div>

          <p>País</p>
          <div style={{display:"flex", alignItems:"center", color: "grey", border: "1px solid", borderRadius:"10px", height:"40px", marginBottom: "20px", maxWidth: "240px"}}>
            <label htmlFor='country'></label>
            <select type='string' name='country'onChange={handleInput} className='form-select rounded-0 m-1' style={{maxWidth: "210px"}}>
                {options3.map(option =>(<option value={option.value}>{option.label}</option>))}
            </select>
          </div>
        </div>

        <div className='w-1/5'>
          <p style={{fontSize: "10px", marginBottom: "10px"}}>Ingresa tu contraseña para actualizar tus datos</p>
          <div style={{display:"flex", alignItems:"center", color: "grey", border: "1px solid", borderRadius:"10px", height:"40px", marginBottom: "20px", maxWidth: "240px"}}>
            <label htmlFor='current_pass'></label>
            <input type='password' name='current_pass' placeholder="Contraseña" className='form-control rounded-0 m-1' onChange={handleInput}></input>
          </div>
          <div className='flex justify-center items-center'><TooltipComponent content="Al pulsar este botón, se actualizarán todos los datos que hayas modificado. Los datos en blanco permanecerán como estaban."><button style={{color: "white", backgroundColor: "#425df9", borderRadius: "10px", height: "40px", minWidth:"160px", maxWidth:"240px auto", fontSize: "14px"}} onClick={handleSubmit}>Actualizar datos</button></TooltipComponent></div>
          
        </div>

      </div>
      <div className='flex justify-center items-center mt-6'>
          <p className='font-bold text-gray-400 mb-3'>Opciones</p>
      </div>
      <div className='flex justify-center items-center gap-4'>
          <div>
            <Link to="/dashboard/account/modifypass">
              <Button className
                color="white"
                bgColor="#425df9"
                text="Cambiar contraseña"
                borderRadius="10px"
                size="md"
                width="60"
              />
            </Link>
          </div>
          <div>
            <Link to="/dashboard/account/notifications">
              <Button className
                color="white"
                bgColor="#425df9"
                text="Mis notificaciones"
                borderRadius="10px"
                size="md"
                width="60"
              />
            </Link>
          </div>
          <div>
            <Link to="/dashboard/account/evaluate_platform">
              <Button className
                color="white"
                bgColor="#425df9"
                text="Calificar plataforma"
                borderRadius="10px"
                size="md"
                width="60"
              />
            </Link>
          </div>
          <div>
            <Link to="/dashboard/account/deleteaccount">
              <Button className
                color="white"
                bgColor="red"
                text="Borrar cuenta"
                borderRadius="10px"
                size="md"
                width="60"
              />
            </Link>
          </div>
        </div>
        <LoadingScreen loaderscreen={loadingscreen} isOpen={open} closeModal={() => setOpen(false)}/>
    </div>
  )
}

export default Account