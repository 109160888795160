//Imports
import {React, useState, useEffect, useContext} from 'react'
import { UserContext } from '../../../contexts/UserContext';
import { Header} from '../../../components';
import {API_PORT} from "../../../API_PORT"
import capitalize from '../../../hook/capitalize';
import LoadingScreen from '../../../components/LoadingScreen';
import { useNavigate } from 'react-router-dom';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

//Selector Function
const Selector = () => {
  const userContext = useContext(UserContext);
  const token = userContext.value.access_token;
  const link= API_PORT + "/admin/database_information/get-avaible-MIPs?token="+token;
  const navigate = useNavigate();
  const [loadingscreen, setLoadingScreen] = useState({started: true});
  const [open, setOpen] = useState(true);
  const [mips, setMips] = useState([]);

  //Elements for update in User Context
  const { value, setValue } = useContext(UserContext);
  const [country, setCountry] = useState(value.country);
  const [year, setYear] = useState(value.year);
  const [logged] = useState(value.logged);
  const [email] = useState(value.email);
  const [username] = useState(value.username);
  const [first_name] = useState(value.first_name);
  const [last_name] = useState(value.last_name);
  const [user_type] = useState(value.user_type);
  const [access_token] = useState(value.access_token);
  const [user_photo] = useState(value.user_photo);

  //Obtain Databse Years and Countries
  useEffect(() => {
    fetch(link, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
      })
      .then(res => res.json())
      .then(data => {
        setMips(data);
        setOpen(false);
        setLoadingScreen(prevState => {return {...prevState, started: false}});
      })
      .catch(error => {
        // Manejo del error de conexión u otros errores
        console.error('Error al realizar la solicitud:', error);
        alert("No se han podido obtener los datos. Recarga la página o inténtalo nuevamente más tarde.");
        setOpen(false);
        setLoadingScreen(prevState => ({ ...prevState, started: false }));
    })
    }, [link]);

  //Save year and countries
  var mip_values = mips.MIPbase;
  var year_data= [{value:"Sin asignar",label:"Sin asignar"}];
  var country_data= [{value:"Sin asignar",label:"Sin asignar"}];
  mip_values?.forEach((element) =>
    {year_data.push({value: element[0], label: element[0]});
    country_data.push({value: element[1], label: capitalize(element[1])})}
  )

  //Function to save values on UserContexts
  const SaveResults = () => {
    if (country && country !== "Sin asignar" && year && year !== "Sin asignar"){
      setValue({ country, year, logged, email, username, first_name, last_name, user_type, access_token, user_photo }); //Necesary save ALL elements in UserContext
      alert("Se han guardado los datos de año y país base. La próxima vez que realices una simulación, estos datos serán seleccionados automáticamente.")
      navigate("/dashboard/home")
    }
    else{
      alert("No has seleccionado un valor")
    }
  }

  //Function to save values on UserContexts
  const DeleteResults = () => {
    setValue({ country:"Sin asignar", year:"Sin asignar", logged, email, username, first_name, last_name, user_type, access_token, user_photo }); //Necesary save ALL elements in UserContext
    alert("Se han borrado los datos actuales de año y país base. Si quieres guardar unos nuevos, deberás volver a seleccionarlos en esta pantalla.");
    navigate("/dashboard/home")
  }

  //Page render
  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
      <Header title="Selector de año y país"/>
      <p className='text-gray-500'>En esta sección podrás seleccionar un año y país base para guardarlos en tu sesión, de manera que estos se auto seleccionen para las siguientes simulaciones que realices en la plataforma.</p>
      <p className='text-gray-500 mt-2'><strong>Año base actual seleccionado: {year}</strong></p>
      <p className='text-gray-500'><strong>País actual seleccionado: {capitalize(country)}</strong></p>
      <div className='mt-8'><Header title="Selecciona 1 opción disponible para el año y país"/></div>
      <div className='grid grid-cols-3 gap-3'>
        <div className='columns-1'>
          <h1 className='mb-3'><strong>Año Base</strong></h1>
            <label htmlFor='year'></label>
            <select type='string' name='year'
              onChange={(e) => setYear(e.target.value)} className='form-select rounded-0 w-60 h-10' style={{color: "white", backgroundColor: "#425df9", borderRadius: "8px"}}>
              {year_data.map(option =>(
                <option value={option.value} style={{border:"1px solid", color: "gray", backgroundColor: "white"}}>{option.label}</option>
                ))}
            </select>
        </div>
        <div className='columns-1'>
          <h1 className='mb-3'><strong>País Base</strong></h1>
          <label htmlFor='country'></label>
            <select type='string' name='country'
              onChange={(e) => setCountry(e.target.value)} className='form-select rounded-0 w-60 h-10' style={{color: "white", backgroundColor: "#425df9", borderRadius: "8px"}}>
              {country_data.map(option =>(
                <option value={option.value} style={{border:"1px solid", color: "gray", backgroundColor: "white"}}>{option.label}</option>
                ))}
            </select>
        </div>
      </div>
      <div className='flex gap-5 mt-32'>
        <button style={{color:'white', backgroundColor:"#425df9", borderRadius: "10px"}} className="text-lg p-3 w-60" onClick={SaveResults}>Guardar Datos</button>
        <TooltipComponent content="Al pulsar este botón se borrarán los datos seleccionados actuales dejando estos vacíos."><button style={{color:'white', backgroundColor:"#425df9", borderRadius: "10px"}} className="text-lg p-3 w-60" onClick={()=>{DeleteResults()}}>Borrar datos actuales</button></TooltipComponent>
      </div>
      <LoadingScreen loaderscreen={loadingscreen} isOpen={open} closeModal={() => setOpen(false)}/>
    </div>
  )
}

export default Selector