//Imports
import {React, useState, useEffect, useContext} from 'react'
import { Link } from 'react-router-dom';
import { Header, Button } from '../../../../components';
import capitalize from '../../../../hook/capitalize';
import { API_PORT } from '../../../../API_PORT';
import { UserContext } from '../../../../contexts/UserContext';
import LoadingScreen from '../../../../components/LoadingScreen';
import "../../../../hook/Tables.css"
import MUIDataTable, {TableToolbar} from 'mui-datatables';

//Main Function
const AvailableCoins = () => {
    const userContext = useContext(UserContext);
    const token = userContext.value.access_token;
    const coin_columns = ["País","Moneda"]
    var coin_data= [];
    const [loadingscreen, setLoadingScreen] = useState({started: true});
    const [open, setOpen] = useState(true);
    const options={
      rowsPerPage: 10,
      rowsPerPageOptions: [1,2,3,4,5,6,7,8,9,10,15,20,25,30],
      selectableRows: "none",
      tableBodyMaxHeight: "400px",
      print: "false",
      download: "false",
      filter: "false",
      viewColumns: "false",
      responsive: "vertical",
      textLabels: {
        toolbar: {
          search: "Buscar",  // Cambiar el texto del tooltip de "Search" a "Buscar fila"
        },
        pagination: {
          rowsPerPage: "Filas por página:",  // Cambiar el texto de "Rows per page" a "Filas por página"
          next: "Siguiente página",
          previous: "Página anterior",
          displayRows: "de",
      },
      body: {
        noMatch: "No hay monedas disponibles", // Cambia este texto a lo que necesites
      },
      },
    }
  
    const [coins, setCoins] = useState([])
    const link= API_PORT + "/admin/database_information/get-coin-name?token="+token
  
    //Get coins data
    useEffect(() => {
      fetch(link, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json'
        }
        })
        .then(res => res.json())
        .then(data => {
          setCoins(data);
          setOpen(false);
          setLoadingScreen(prevState => {return {...prevState, started: false}});
        }).catch(error => {
          // Manejo del error de conexión u otros errores
          console.error('Error al realizar la solicitud:', error);
          alert("No se han podido obtener los datos. Recarga la página o inténtalo nuevamente más tarde.");
          setOpen(false);
          setLoadingScreen(prevState => ({ ...prevState, started: false }));
      })
      }, [link]);
  
    var coin_values = coins
    for (let pais in coin_values) {
        let valor = coin_values[pais];
        coin_data.push([capitalize(pais),valor]);
      }

    //Function to fix columns design
  function columns_convert(columns) { 
    return columns.map((column, index) => ({
      name: column,
      label: column,
      options: {
        // Estilos del encabezado con bordes y sticky aplicado a la primera columna
        customHeadRender: (columnMeta) => {
          return (
            <th
              key={columnMeta.index}
              className="custom-header custom-cell-spacing"
              style={{
                background: "#b0b0b0",  // Color de fondo gris oscuro para el encabezado
                color: "white",  // Texto en blanco para el encabezado
                fontWeight: "bold",  // Texto en negrita
                textAlign: 'center',  // Centrar el contenido en el encabezado
                position: 'sticky',  // Sticky para todas las celdas del encabezado
                top: 0,  // Fijar la fila del encabezado en la parte superior
                left: index === 0 ? 0 : 'auto',  // Fijar la primera columna
                zIndex: index === 0 ? 102 : 101,  // Asegura que el encabezado esté por encima del contenido
                height: '50px',  // Ajustar el alto del encabezado
                verticalAlign: 'middle',  // Alinear verticalmente al centro
              }}
            >
              {columnMeta.label}
            </th>
          );
        },
        // Estilos para las celdas del cuerpo de la tabla
        setCellProps: () => ({
          style: {
            textAlign: 'center',  // Centrar el contenido horizontalmente
            verticalAlign: 'middle',  // Centrar el contenido verticalmente
            borderRight: '1px solid #9c9c9c',  // Borde gris oscuro entre columnas
            borderBottom: '1px solid #9c9c9c',  // Borde gris oscuro debajo de las filas
            backgroundClip: 'padding-box', // Asegura que el fondo no se solape con el borde
            boxSizing: 'border-box',  // Asegura que los bordes y el padding se incluyan en el tamaño de la celda
          },
        }),
      }
    }));
  }
  
    //Main render
    return (
      <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
          <div className='flex justify-between items-center mb-10'>
              <Link to="/dashboard/adminbd" >
                <Button
                  color="white"
                  bgColor="gray"
                  text="Volver"
                  borderRadius="10px"
                  size="md"
                />
              </Link>
          </div>  
        <Header title="Lista de monedas nacionales disponibles"/>
        <div className='flexd'>
            <div className="custom-table-container" style={{maxHeight:"100%"}}>
              <MUIDataTable
              data={coin_data}
              columns={columns_convert(coin_columns)}
              options={options}
              components={{
                TableToolbar: (props) => (
                  <div
                    style={{
                      backgroundColor: '#b0b0b0',
                    }}
                  >
                    {/* Título personalizado */}
                    <div style={{ color: 'black', fontWeight: 'bold', fontSize: '20px', paddingLeft: "24px", paddingTop: "10px" }}>
                    Lista de monedas nacionales
                    </div>
                    {/* Mantener los íconos del toolbar predeterminado, sin duplicar el título */}
                    <TableToolbar {...props} title={null} />
                  </div>
                ),
              }}
              ></MUIDataTable>
            </div>
        </div>
        <LoadingScreen loaderscreen={loadingscreen} isOpen={open} closeModal={() => setOpen(false)}/>
      </div>
    )
}

export default AvailableCoins