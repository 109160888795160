//Import
import {React, useEffect, useContext, useState} from 'react'
import { Header, Button} from '../../../../components';
import { UserContext } from '../../../../contexts/UserContext';
import { Link, useNavigate } from 'react-router-dom';
import { API_PORT } from '../../../../API_PORT';
import MUIDataTable, {TableToolbar} from "mui-datatables";
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import deletesvg from "../../../../assets/delete.svg"
import LoadingScreen from '../../../../components/LoadingScreen';
import { MdOutlineViewKanban, MdOutlinePageview } from "react-icons/md";
import { CiExport } from "react-icons/ci";
import "../../../../hook/Tables.css"

//Main function
const SimulationOperationList = () => {
  const [loadingscreen, setLoadingScreen] = useState({started: true});
  const [open, setOpen] = useState(true);
  const userContext = useContext(UserContext);
  const token = userContext.value.access_token;
  const user_type = userContext.value.user_type;
  const navigate = useNavigate();
  const [msoData, setMsoData] = useState([])
  var mso_export = {
    "Fosil": 0,
    "Hidraulica": 0,
    "BiomasaBiogas": 0,
    "Solar": 0,
    "Eolica": 0,
    "Geotermia": 0,
    "AlmacenamientoLargo": 0,
    "AlmacenamientoCorto": 0,
    "H2": 0,
    "FosilCapacity": 0,
    "HidraulicaCapacity": 0,
    "BiomasaBiogasCapacity": 0,
    "SolarCapacity": 0,
    "EolicaCapacity": 0,
    "GeotermiaCapacity": 0,
    "AlmacenamientoLargoCapacity": 0,
    "AlmacenamientoCortoCapacity": 0,
  }
  const options={
    rowsPerPage: 10,
    rowsPerPageOptions: [1,2,3,4,5,6,7,8,9,10,15],
    selectableRows: "none",
    tableBodyMaxHeight: "350px",
    print: "false",
    download: "false",
    filter: "false",
    viewColumns: "false",
    responsive: "vertical",
    textLabels: {
      toolbar: {
        search: "Buscar simulación",  // Cambiar el texto del tooltip de "Search" a "Buscar fila"
      },
      pagination: {
        rowsPerPage: "Filas por página:",  // Cambiar el texto de "Rows per page" a "Filas por página"
        next: "Siguiente página",
        previous: "Página anterior",
        displayRows: "de",
      },
      body: {
        noMatch: "No hay simulaciones guardadas en tu cuenta", // Cambia este texto a lo que necesites
      },
    },
  }
  const mso_columns = ["Nombre simulación","Fecha de simulación","Tiempo de carga del escenario","Acciones"]
  var mso_data = []

  //Obtain MSO List
  useEffect(() => {
    fetch(API_PORT+'/MSO/get-MSO-simulation-list/?token=' + token, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
      })
      .then(res => res.json()).then(data => {
        setMsoData(data);
        setOpen(false);
        setLoadingScreen(prevState => {return {...prevState, started: false}});
      }).catch(error => {
        // Manejo del error de conexión u otros errores
        console.error('Error al realizar la solicitud:', error);
        alert("No se han podido obtener los datos. Recarga la página o inténtalo nuevamente más tarde.");
        setOpen(false);
        setLoadingScreen(prevState => ({ ...prevState, started: false }));
    });
    }, [token]);

  msoData?.forEach((element, index) => {
    if(element.process_type === "MSO_simulation" && element.simulation_state === "completada"){
      mso_data.push([element.simulation_name, element.simulations_starting_date, element.simulation_time.toFixed(2) + " segundos", 
        <div className='flex justify-center items-center gap-4'>
        <TooltipComponent content="Ver resultados"><button onClick={()=>{goResults(element.id)}}><MdOutlineViewKanban className='w-6 h-6'/></button></TooltipComponent>
        <TooltipComponent content="Revisar datos ingresados"><button onClick={()=>{getInformation(element.id, element.simulations_starting_date, element.simulation_name, element.simulation_time.toFixed(2) + " segundos")}}><MdOutlinePageview className='w-6 h-6'/></button></TooltipComponent>
        <TooltipComponent content="Exportar datos a escenario personalizado"><button onClick={()=>{exportResults(element.id)}}><CiExport className='w-6 h-6'/></button></TooltipComponent>
        <TooltipComponent content="Eliminar escenario"><button onClick={()=>{handleDelete(index,element.id)}}><img src={deletesvg} className='w-6 h-6' alt="Eliminar reporte"></img></button></TooltipComponent>
      </div>
      ])
      }
    }
  )

  //Obtain results from MSO endpoint
  const goResults = async (id) => {
    setOpen(true);
    setLoadingScreen(prevState => ({ ...prevState, started: true }));
    
    try {
      // Llamada al primer endpoint
      const response1 = await fetch(`${API_PORT}/MSO/get-MSO-result/${id}?token=${token}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json'
        }
      });

      if (!response1.ok) {
        throw new Error('Network response was not ok for first endpoint');
      }

      const data1 = await response1.json();
      const mso = data1?.MSO_result;
      const values = {
        country: data1?.simulation_parameters?.country,
        pelp_year: data1?.simulation_parameters?.year,
        scenary: data1?.simulation_parameters?.scenario,
        year: data1?.simulation_parameters?.year,
        year_simulation: data1?.simulation_parameters?.year
      };
      const configuration = {
        "Combustible": data1?.simulation_parameters?.MSO_user_configuration.fuel_price_incr*100,
        "Eficiencia": data1?.simulation_parameters?.MSO_user_configuration.eff_incr*100,
        "Hidrica": data1?.simulation_parameters?.MSO_user_configuration.disp_hidro*100,
        "FactorDemanda": data1?.simulation_parameters?.MSO_user_configuration.demand_factor*100,
        "DemandaH2": data1?.simulation_parameters?.MSO_user_configuration.demand_H2*100,
        "Solver": data1?.simulation_parameters?.MSO_user_configuration.solver,
        "CostosEncendido": data1?.simulation_parameters?.MSO_user_configuration.start_cost,
        "Mintime": data1?.simulation_parameters?.MSO_user_configuration.mintime,
        "Ramp": data1?.simulation_parameters?.MSO_user_configuration.ramp,
      }

      // Llamada al endpoint de coins
      const response2 = await fetch(`${API_PORT}/admin/database_information/get-coin-name?token=${token}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json'
        }
      });

      if (!response2.ok) {
        throw new Error('Network response was not ok for second endpoint');
      }
      const data2 = await response2.json();
      const coins = data2; 
      navigate('/dashboard/simulations/main/simulation_operation_results', { state: { mso, configuration, values, coins } });
    } catch (err) {
      console.error('Error fetching data:', err);
      alert("No se han podido obtener los datos. Recarga la página o inténtalo nuevamente más tarde.");
      setOpen(false);
      setLoadingScreen(prevState => ({ ...prevState, started: false }));
    } finally {
      setOpen(false);
      setLoadingScreen(prevState => ({ ...prevState, started: false }));
    }
  };

  //Export to basic scenary function
  const exportResults = async (id) => {
    setOpen(true);
    setLoadingScreen(prevState => ({ ...prevState, started: true }));
    
    try {
      // Llamada al primer endpoint
      const response1 = await fetch(`${API_PORT}/MSO/get-MSO-result/${id}?token=${token}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json'
        }
      });

      if (!response1.ok) {
        throw new Error('Network response was not ok for first endpoint');
      }

      const data1 = await response1.json();
      const values = {
        country: data1?.simulation_parameters?.country,
        pelp_year: data1?.simulation_parameters?.year,
        scenary: data1?.simulation_parameters?.scenario,
        year: "2020",
        year_simulation: data1?.simulation_parameters?.year
      };
      const energy = data1?.MSO_result?.Energy_production_by_tech?.data
      const capacity = data1?.MSO_result?.Capacity_by_tech?.data
        energy?.forEach((element) => {
          var v = element.value.toFixed(2)
          var y = element.y_index
          if (y === "fósil"){
            mso_export.Fosil= v
          }
          else if (y === "hidráulica"){
            mso_export.Hidraulica= v
          }
          else if (y === "biomasa y biogas"){
            mso_export.BiomasaBiogas= v
          }
          else if (y === "solar"){
            mso_export.Solar= v
          }
          else if (y === "eólica"){
            mso_export.Eolica= v
          }
          else if (y === "geotermia"){
            mso_export.Geotermia= v
          }
          else if (y === "almacenamiento largo plazo"){
            mso_export.AlmacenamientoLargo= v
          }
          else if (y === "almacenamiento corto plazo"){
            mso_export.AlmacenamientoCorto= v
          }
          else if (y === "H2"){
            mso_export.H2= v
          }
        })
        capacity.forEach((element) => {
          var v = element.value.toFixed(2)
          var y = element.y_index
          if (y === "fósil"){
            mso_export.FosilCapacity= v
          }
          else if (y === "hidráulica"){
            mso_export.HidraulicaCapacity= v
          }
          else if (y === "biomasa y biogas"){
            mso_export.BiomasaBiogasCapacity= v
          }
          else if (y === "solar"){
            mso_export.SolarCapacity= v
          }
          else if (y === "eólica"){
            mso_export.EolicaCapacity= v
          }
          else if (y === "geotermia"){
            mso_export.GeotermiaCapacity= v
          }
          else if (y === "almacenamiento largo plazo"){
            mso_export.AlmacenamientoLargoCapacity= v
          }
          else if (y === "almacenamiento corto plazo"){
            mso_export.AlmacenamientoCortoCapacity= v
          }
        })

      // Llamada al endpoint de coins
      const response2 = await fetch(`${API_PORT}/admin/database_information/get-coin-name?token=${token}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json'
        }
      });

      if (!response2.ok) {
        throw new Error('Network response was not ok for second endpoint');
      }
      const data2 = await response2.json();
      const coins = data2; 
      navigate('/dashboard/simulations/main/basic_scenary_selector_2', { state: { values, mso_export, coins } });
    } catch (err) {
      console.error('Error fetching data:', err);
      alert("No se han podido obtener los datos. Recarga la página o inténtalo nuevamente más tarde.");
      setOpen(false);
      setLoadingScreen(prevState => ({ ...prevState, started: false }));
    } finally {
      setOpen(false);
      setLoadingScreen(prevState => ({ ...prevState, started: false }));
    }
  };

  //Delete MSO function
  const DeleteSimulation= (index) => {
    fetch(API_PORT+"/MSO/delete-MSO-result/"+index+"?token="+token, {
      method: 'DELETE',
      headers: {
        'Content-type': 'application/json'
      }
      })
      .then(res => res.json())
      .then(data => {
        alert(data.detail);
        setOpen(false);
        setLoadingScreen(prevState => ({ ...prevState, started: false }));
      }).catch(error => {
        // Manejo del error de conexión u otros errores
        console.error('Error al realizar la solicitud:', error);
        alert("No se han podido eliminar correctamente el escenario. Recarga la página o inténtalo nuevamente más tarde.");
        setOpen(false);
        setLoadingScreen(prevState => ({ ...prevState, started: false }));
    });
  }

  const getInformation = async (id, date, name, time) => {
    setOpen(true);
    setLoadingScreen(prevState => ({ ...prevState, started: true }));
    
    try {
      // Llamada al primer endpoint
      const response1 = await fetch(`${API_PORT}/MSO/get-MSO-result/${id}?token=${token}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json'
        }
      });

      if (!response1.ok) {
        throw new Error('Network response was not ok for first endpoint');
      }

      const data1 = await response1.json();
      const values = {
        country: data1?.simulation_parameters?.country,
        pelp_year: data1?.simulation_parameters?.year,
        scenary: data1?.simulation_parameters?.scenario,
        year: data1?.simulation_parameters?.year,
        year_simulation: data1?.simulation_parameters?.year
      };
      const configuration = {
        "Combustible": data1?.simulation_parameters?.MSO_user_configuration.fuel_price_incr*100,
        "Eficiencia": data1?.simulation_parameters?.MSO_user_configuration.eff_incr*100,
        "Hidrica": data1?.simulation_parameters?.MSO_user_configuration.disp_hidro*100,
        "FactorDemanda": data1?.simulation_parameters?.MSO_user_configuration.demand_factor*100,
        "DemandaH2": data1?.simulation_parameters?.MSO_user_configuration.demand_H2*100,
        "Solver": data1?.simulation_parameters?.MSO_user_configuration.solver,
        "CostosEncendido": data1?.simulation_parameters?.MSO_user_configuration.start_cost,
        "Mintime": data1?.simulation_parameters?.MSO_user_configuration.mintime,
        "Ramp": data1?.simulation_parameters?.MSO_user_configuration.ramp,
      }

      // Llamada al endpoint de coins
      const response2 = await fetch(`${API_PORT}/admin/database_information/get-coin-name?token=${token}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json'
        }
      });

      if (!response2.ok) {
        throw new Error('Network response was not ok for second endpoint');
      }
      const data2 = await response2.json();
      const coins = data2; 
      navigate('/dashboard/simulations/main/simulation_operation_information', { state: { configuration, values, coins, date, name, time } });
    } catch (err) {
      console.error('Error fetching data:', err);
      alert("No se han podido obtener los datos. Recarga la página o inténtalo nuevamente más tarde.");
      setOpen(false);
      setLoadingScreen(prevState => ({ ...prevState, started: false }));
    } finally {
      setOpen(false);
      setLoadingScreen(prevState => ({ ...prevState, started: false }));
    }
  };  

  //Delete mso from list function
  const handleDelete = (index,id) => {
    setOpen(true);
    setLoadingScreen(prevState => ({ ...prevState, started: true }));
    setMsoData(prevNotifications =>
      prevNotifications.filter((_, i) => i !== index)
    );
    DeleteSimulation(id)
  };

  //Function to fix columns design
  function columns_convert(columns) { 
    return columns.map((column, index) => ({
      name: column,
      label: column,
      options: {
        // Estilos del encabezado con bordes y sticky aplicado a la primera columna
        customHeadRender: (columnMeta) => {
          return (
            <th
              key={columnMeta.index}
              className="custom-header custom-cell-spacing"
              style={{
                background: "#b0b0b0",  // Color de fondo gris oscuro para el encabezado
                color: "white",  // Texto en blanco para el encabezado
                fontWeight: "bold",  // Texto en negrita
                textAlign: 'center',  // Centrar el contenido en el encabezado
                position: 'sticky',  // Sticky para todas las celdas del encabezado
                top: 0,  // Fijar la fila del encabezado en la parte superior
                left: index === 0 ? 0 : 'auto',  // Fijar la primera columna
                zIndex: index === 0 ? 102 : 101,  // Asegura que el encabezado esté por encima del contenido
                height: '50px',  // Ajustar el alto del encabezado
                verticalAlign: 'middle',  // Alinear verticalmente al centro
              }}
            >
              {columnMeta.label}
            </th>
          );
        },
        // Estilos para las celdas del cuerpo de la tabla
        setCellProps: () => ({
          style: {
            textAlign: 'center',  // Centrar el contenido horizontalmente
            verticalAlign: 'middle',  // Centrar el contenido verticalmente
            borderRight: '1px solid #9c9c9c',  // Borde gris oscuro entre columnas
            borderBottom: '1px solid #9c9c9c',  // Borde gris oscuro debajo de las filas
            backgroundClip: 'padding-box', // Asegura que el fondo no se solape con el borde
            boxSizing: 'border-box',  // Asegura que los bordes y el padding se incluyan en el tamaño de la celda
          },
        }),
      }
    }));
  }

  //Render list
  function ScenaryRender(user){
    if(user === "premium" || user === "admin"){
      return(<div className='mt-5'>
        <div className='custom-table-container'><MUIDataTable
          data={mso_data}
          columns={columns_convert(mso_columns)}
          options={options}
          components={{
              TableToolbar: (props) => (
                <div
                  style={{
                    backgroundColor: '#b0b0b0',
                  }}
                >
                  {/* Mantener los íconos del toolbar predeterminado, sin duplicar el título */}
                  <TableToolbar {...props} title={null} />
                </div>
              ),
            }}
          ></MUIDataTable>
        </div>
      </div>)
    }
    else{
      return(
        <div className='mt-16'>
                <h1 className='flex justify-center mb-3'><strong>Contrata la suscripción	premium de EcoKinesis para desbloquear los escenarios de operación simulada</strong></h1>
                <div className='flex justify-center'>
                    <div className='mb-8' style={{backgroundColor:"#E0E2E2", borderRadius: "20px", maxWidth:"540px", maxHeight:"380px"}}>
                      <div className='justify-center align-items-center text-center'>
                        <div className="text-3xl" style={{color: "black"}}><strong>Suscripción Premium</strong></div>
                        <div className="text-sm mt-3" style={{color:"gray"}}><strong>Contrata la suscripción premium de EcoKinesis para poder usar esta sección</strong></div>
                        <button className="text-lg" style={{color:"white",backgroundColor:"#425df9",borderRadius:"20px", minWidth: "120px", maxWidth:"260px", height:"54px", marginTop:"10px", marginBottom: "10px"}} onClick={()=>{navigate("/dashboard/premium")}}>Contratar</button>
                      </div>
                    </div>
                </div>
            </div>
      )
    }
  }

  //Page render
  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
      <div className='flex justify-between items-center mb-5'>
            <Link to="/dashboard/simulations/main/simulation_operation_menu" >
              <Button
                color="white"
                bgColor="gray"
                text="Volver al menú de escenarios de operación simulada"
                borderRadius="10px"
                size="md"
              />
            </Link>
      </div> 
      <Header title="Lista de escenarios de operación simulada"/>
      <p className='text-gray-500'>En esta sección podrás ver los resultados de tus escenarios de operación simulada. Selecciona los botones de la tabla en la zona derecha para realizar diferentes acciones. El botón de "Ver resultados" te permitirá ver los gráficos y tablas de tu simulación. El botón de "Revisar datos ingresados" te permitirá revisar los datos que ingresaste en tu escenario de operación simulada, así como reutilizar estos en un nuevo escenario de operación simulada. El botón de "Exportar datos a escenario personalizado" te permitirá exportar el resultado de tu simulación a un escenario personalizado. El botón de "Eliminar escenario" te permitirá eliminar tu escenario de esta lista permanentemente. Las simulaciones de escenarios operación simulada sólo están disponibles para usuarios que cuenten con la <Link to= "/dashboard/premium" className='text-blue-500'>suscripción premium de EcoKinesis</Link>.</p>
      {ScenaryRender(user_type)}
      <LoadingScreen loaderscreen={loadingscreen} isOpen={open} closeModal={() => setOpen(false)}/>
    </div>
  )
}

export default SimulationOperationList