//Imports
import {React} from 'react'
import Header from './Header';
import Button from './Button';

//Componente of update history
const UpdateView = () => {

  //Custom message of updates
    const msg = 
  <div style={{fontSize:"11px"}}>
    <Header title="Historial de actualizaciones"/>
    <div>
    <strong>Beta 1.6.3 - 22/11/2024</strong>
    <p>-Añadido los gráficos de Valor agregado, PIB nacional e Impacto PIB total en MIP Expandida, Escenarios Predefinidos y Escenarios Personalizados</p>
    <p>-Mejora en el funcionamiento y visualización de las tablas</p>
    <p>-Mejoras en el sistema de editar variables de los escenarios personalizados, escenarios Leontieff y escenarios de operación simulada</p>
    <p className='mb-4'>-Corrección de errores y mejoras en la vista de las páginas de la plataforma</p>

    <strong>Beta 1.4.1 - 11/09/2024</strong>
    <p>-Añadido la selección de año y país como opción en el menú principal</p>
    <p>-Añadido el sistema de notificaciones</p>
    <p>-Modificación en el funcionamiento de los escenarios predefinidos y escenarios de operación simulada</p>
    <p className='mb-4'>-Corrección de errores y mejoras en el rendimiento de la plataforma</p>

    <strong>Beta 1.2.2 - 08/08/2024</strong>
    <p>-Actualizaciones de todas las páginas del sitio web de ecokinesis.cl</p>
    <p>-Se ha cambiado el acceso a la plataforma de nombre usuario/contraseña a email/contraseña</p>
    <p>-Mejoras en el funcionamiento de los reportes </p>
    <p className='mb-4'>-Corrección de errores y mejoras en el rendimiento de la plataforma</p>

    <strong>Beta 1.1 - 16/07/2024</strong>
    <p>-Añadido el historial de actualizaciones en la zona superior derecha</p>
    <p>-Añadidas pantallas de carga entre secciones</p>
    <p>-Añadida la opción de convertir gráficos y tablas en CSV (sólo disponible para usuarios premium)</p>
    <p>-Añadida nueva opción para calificar la plataforma</p>
    <p className='mb-4'>-Solución de errores, corrección de textos en gráficos y tablas</p>

    <strong>Beta 1.0 - 27/06/2024</strong>
    <p>-Añadida la primera versión de EcoKinesis con las zonas de Base de Datos, MIP Expandida, Simulaciones, Reportes, Perfil y Servicios Adicionales</p>
    </div>
  </div>

//Return modal component with message
  return (
    <>
        <div className="modal">
          <div className="overlay"></div>
          <div className="modal-content">
            <div className='close-modal'>
                <Button text="Cerrar" width="24" />
            </div>
            <div className='flex justify-center items-center gap-10 flex-wrap mt-6'>
                {msg && <span>{msg}</span>}
            </div>
          </div>
        </div>
    </>
  )
}

export default UpdateView