// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  /* styles.css */
.custom-header {
  text-transform: none !important;
}

.no-uppercase {
  text-transform: none !important; /* Asegúrate de que no se aplique el estilo de mayúsculas */
}

/* styles.css */
.custom-cell-spacing {
  padding: 16px; /* Ajusta el padding según tus necesidades */
  border-collapse: collapse;
}

/* Aplica un border-radius al contenedor de la tabla */
.custom-table-container .MuiPaper-root {
  border-radius: 16px; /* Cambia el valor de border-radius según lo que necesites */
  overflow: hidden; /* Asegura que el contenido no desborde las esquinas redondeadas */
}


::-webkit-scrollbar-thumb:hover {
  background-color: #425df9; /* Color al pasar el mouse sobre la barra */
}
::-webkit-scrollbar-track {
  background-color: #f7f7f7; /* Color de fondo del espacio entre las barras de desplazamiento */
  border-radius: 12px; /* Bordes redondeados para el track */
}



`, "",{"version":3,"sources":["webpack://./src/hook/Tables.css"],"names":[],"mappings":"EAAE,eAAe;AACjB;EACE,+BAA+B;AACjC;;AAEA;EACE,+BAA+B,EAAE,2DAA2D;AAC9F;;AAEA,eAAe;AACf;EACE,aAAa,EAAE,4CAA4C;EAC3D,yBAAyB;AAC3B;;AAEA,sDAAsD;AACtD;EACE,mBAAmB,EAAE,4DAA4D;EACjF,gBAAgB,EAAE,kEAAkE;AACtF;;;AAGA;EACE,yBAAyB,EAAE,2CAA2C;AACxE;AACA;EACE,yBAAyB,EAAE,kEAAkE;EAC7F,mBAAmB,EAAE,qCAAqC;AAC5D","sourcesContent":["  /* styles.css */\r\n.custom-header {\r\n  text-transform: none !important;\r\n}\r\n\r\n.no-uppercase {\r\n  text-transform: none !important; /* Asegúrate de que no se aplique el estilo de mayúsculas */\r\n}\r\n\r\n/* styles.css */\r\n.custom-cell-spacing {\r\n  padding: 16px; /* Ajusta el padding según tus necesidades */\r\n  border-collapse: collapse;\r\n}\r\n\r\n/* Aplica un border-radius al contenedor de la tabla */\r\n.custom-table-container .MuiPaper-root {\r\n  border-radius: 16px; /* Cambia el valor de border-radius según lo que necesites */\r\n  overflow: hidden; /* Asegura que el contenido no desborde las esquinas redondeadas */\r\n}\r\n\r\n\r\n::-webkit-scrollbar-thumb:hover {\r\n  background-color: #425df9; /* Color al pasar el mouse sobre la barra */\r\n}\r\n::-webkit-scrollbar-track {\r\n  background-color: #f7f7f7; /* Color de fondo del espacio entre las barras de desplazamiento */\r\n  border-radius: 12px; /* Bordes redondeados para el track */\r\n}\r\n\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
