//Imports
import React from 'react'
import { Link } from 'react-router-dom';
import { Header, Button } from '../../../components';
import { useStateContext } from '../../../contexts/ContextProvider';
import bd_logo from '../../../assets/bd_logo.svg';
import mip from "../../../assets/list_FILL0_wght300_GRAD0_opsz24.svg"

//Main function
const Simulations= () => {
  const { currentColor }= useStateContext();
  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
      <Header title="Simulaciones"/>
      <p className='text-gray-500'>En esta sección podrás realizar tus propias simulaciones para el cambio climático. Selecciona una de las 4 opciones disponibles para realizar una simulación. Sigue los pasos hasta obtener los resultados. Al final de estas podrás descargar o guardar los resultados si es que cuentas con la <Link to= "/dashboard/premium" className='text-blue-500'>suscripción premium de EcoKinesis.</Link></p>
      <div className='grid grid-cols-4 gap-10 mt-10'>
      <div className='max-w-1/5'>
          <div className='h-32 bg-light-gray rounded-lg'>
            <div className='grid grid-cols-1 justify-items-center'>
              <img src={mip} width="80px" height="80px" className='mt-3' alt='mip'/>
              <div className='text-gray-400' style={{fontSize:"13px"}}><strong>Escenarios predefinidos</strong></div>
            </div>
          </div>
          <div className="flex justify-center items-center mb-4">
            <Link to="predefined_scenary_selector" className='w-full'>
                <Button
                  color="white"
                  bgColor={currentColor}
                  text="Simular"
                  borderRadius="10px"
                  size="md"
                  width="full"
                />
            </Link>
          </div>
          <div className='w-full'>
            Simula en base a escenarios predefinidos, entre ellos los escenarios oficiales PELP.
          </div>
        </div>

        <div className='max-w-1/5'>
          <div className='h-32 bg-light-gray rounded-lg'>
            <div className='grid grid-cols-1 justify-items-center'>
              <img src={mip} width="80px" height="80px" className='mt-3' alt='mip'/>
              <div className='text-gray-400' style={{fontSize:"13px"}}><strong>Escenarios personalizados</strong></div>
            </div>
          </div>
          <div className="flex justify-center items-center mb-4">
            <Link to="basic_scenary_selector_1" className='w-full'>
                <Button
                  color="white"
                  bgColor={currentColor}
                  text="Simular"
                  borderRadius="10px"
                  size="md"
                  width="full"
                />
            </Link>
          </div>
          <div className='w-full'>
          Simula tus propios escenarios en base a niveles de capacidad y generación por tecnología.
          </div>
        </div>

        <div className='max-w-1/5'>
          <div className='h-32 bg-light-gray rounded-lg'>
            <div className='grid grid-cols-1 justify-items-center'>
              <img src={bd_logo} width="80px" height="80px" className='mt-3' alt='bd'/>
              <div className='text-gray-400' style={{fontSize:"13px"}}><strong>Escenarios Leontieff</strong></div>
            </div>
          </div>
          <div className="flex justify-center items-center mb-4">
            <Link to="leontieff_scenary" className='w-full'>
                <Button
                  color="white"
                  bgColor={currentColor}
                  text="Simular"
                  borderRadius="10px"
                  size="md"
                  width="full"
                />
            </Link>
          </div>
          <div className='w-full'>
            Simula tus propios escenarios en base a cambios de la demanda final y precios.
          </div>
        </div>

        <div className='max-w-1/5'>
          <div className='h-32 bg-light-gray rounded-lg'>
            <div className='grid grid-cols-1 justify-items-center'>
              <img src={bd_logo} width="80px" height="80px" className='mt-3' alt='bd'/>
              <div className='text-gray-400' style={{fontSize:"13px"}}><strong>Escenarios operación simulada</strong></div>
            </div>
          </div>
          <div className="flex justify-center items-center mb-4">
            <Link to="simulation_operation_menu" className='w-full'>
                <Button
                  color="white"
                  bgColor={currentColor}
                  text="Simular"
                  borderRadius="10px"
                  size="md"
                  width="full"
                />
            </Link>
          </div>
          <div className='w-full'>
            Simula tus propios escenarios calculando niveles de generación por tecnología con MSO.
          </div>
        </div>

      </div>
    </div>
  )
}

export default Simulations