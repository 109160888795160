//Imports
import { React , useContext} from 'react';
import { Link, NavLink } from 'react-router-dom';
import logotipo from '../assets/ecokinesis.png'
import { links, user_links } from '../data/dummy';
import { useStateContext } from '../contexts/ContextProvider';
import './Dashboard.css'
import { UserContext } from '../contexts/UserContext';
import logout from "../assets/logout.svg";
import { useNavigate } from 'react-router-dom';

//Left Sidebar component
const Sidebar = () => {
  const { activeMenu, setActiveMenu, screenSize, currentColor, setIsClicked, initialState }= useStateContext();
  const userContext = useContext(UserContext);
  const user_type= userContext.value.user_type;
  const navigate = useNavigate();

  //Auto close sidebar if screenSize <= 900px
  const handleCloseSidebar = () => {
    if (activeMenu && screenSize <= 900){
      setActiveMenu(false)
    }
  }

  const activeLink= 'flex items-center gap-2 pl-3 pt-2 pb-2 rounded-lg text-white text-md ml-1 mr-4';
  const normalLink= 'flex items-center gap-2 pl-3 pt-2 pb-2 rounded-lg text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray ml-1 mr-4';

  //Sign out Function
  const signOut = () => {
    setIsClicked(initialState)
    userContext.setValue({logged: false});
    userContext.setValue({access_token: ""});
    userContext.setValue({username: "", email: "", first_name: "", last_name: "", user_type: "", user_photo: "", country: "Sin asignar", year: "Sin asignar"});
    navigate("/")
  }

  //Sidebar for user and admins
  function SidebarRender(user){
    if (user === "admin"){
      return(
        <div>
           <div>
          {links.map((item) => (
            <div key={item.title}>
              <p className='text-gray-400 ml-2 mt-3'>
                {item.title}
              </p>
              {item.links.map((link)=> (
                <NavLink
                to={`/${link.direction}`}
                  key={link.name}
                  onClick={handleCloseSidebar}
                  style={({isActive}) => ({
                    backgroundColor: isActive ? currentColor: ''
                  })}
                  className={({isActive}) => isActive? activeLink : normalLink}
                >
                  {link.icon}
                  <span>
                    {link.name}
                  </span>
                </NavLink>
              ))}
            </div>
          ))}
        </div>
        <div className='ml-9 w-48' style={{marginTop: "12%"}}>
          <button onClick={()=>{signOut()}} style={{color: "white", borderRadius: "10px", width: "100%", height:"50px", backgroundColor: currentColor}} className='flex justify-center items-center gap-1'>Cerrar sesión<img src={logout} className='w-6 h-6 pt-1' alt=''/></button>
        </div>
      </div>
      )
    }
    else{
      return(
        <div>
           <div>
          {user_links.map((item) => (
            <div key={item.title}>
              <p className='text-gray-400 ml-2 mt-5'>
                {item.title}
              </p>
              {item.links.map((link)=> (
                <NavLink
                to={`/${link.direction}`}
                  key={link.name}
                  onClick={handleCloseSidebar}
                  style={({isActive}) => ({
                    backgroundColor: isActive ? currentColor: ''
                  })}
                  className={({isActive}) => isActive? activeLink : normalLink}
                >
                  {link.icon}
                  <span>
                    {link.name}
                  </span>
                </NavLink>
              ))}
            </div>
          ))}
        </div>
        <div className='ml-9 w-48' style={{marginTop:"48%"}}>
          <button onClick={()=>{signOut()}} style={{color: "white", borderRadius: "10px", width: "100%", height:"50px", backgroundColor: currentColor}} className='flex justify-center items-center gap-1'>Cerrar sesión<img src={logout} className='w-6 h-6 pt-1' alt=''/></button>
          </div>
        </div>
      )
    }
  }

  //Return Left Dashboard or Sidebar to move between screens
  return (
    <div className='ml-3 h-screen md-:overflow-hidden overflow-auto md:hover:overflow-auto pb-10'>
      {activeMenu && (<>
        <div className='flex justify-between items-center'>
          <Link to="/dashboard/home" onClick={handleCloseSidebar} className='items-center gap-1 ml-5 mt-4 flex textl-xl font-extrabold tracking-tight dark:text-white text-slate-900'>
            <img src={logotipo} className="ml-14 h-auto max-w-24"alt=''></img>
          </Link>
        </div>
        <div>
          {SidebarRender(user_type)}
        </div>
      </>)}
    </div>
  )
}

export default Sidebar