//Imports
import { createTheme } from '@mui/material/styles';

//Theme for tables of simulations
const getMuiTheme = () =>
    createTheme({
        palette: {
          background: {
            paper: "#b0b0b0",
          }
        },
        components: {
          MuiTableCell: {
            styleOverrides: {
              
              head: {
                '&:first-child': {
                  backgroundColor: "#b0b0b0",
                  color: 'white',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  position: 'sticky',
                  left: 0,
                  zIndex: 102,
                  '&.no-uppercase': {
                              textTransform: 'none',
                          },
                        },
              '&:not(:first-child)': {
                backgroundColor: "#b0b0b0",
                color: 'white',
                fontWeight: 'bold',
                textAlign: 'center',
                '&.no-uppercase': {
                            textTransform: 'none',
                        },
                        },
              },
              body:{
                '&:first-child': {
                  backgroundColor: '#eaeaea',
                  fontWeight: 'bold',
                  color: 'black',
                  borderRight: '1px solid #b0b0b0',  // Borde gris oscuro entre columnas
                  borderBottom: '1px solid #b0b0b0',  // Borde gris oscuro debajo de las filas
                  textAlign: 'center',  // Centrar el contenido horizontalmente
                  position: 'sticky',
                  left: 0,
                  zIndex: 101,
                        },
                        '&:not(:first-child)': {
                          backgroundColor: 'white',
                          borderRight: '1px solid #b0b0b0',  // Borde gris oscuro entre columnas
                          borderBottom: '1px solid #b0b0b0',  // Borde gris oscuro debajo de las filas
                          textAlign: 'center',  // Centrar el contenido horizontalmente
                        },
              }
            }
          },
          MuiMenu: {
            styleOverrides: {
                paper: {
                    backgroundColor: '#f0f0f0', // Color de fondo del menú
                    color: 'black', // Color del texto del menú
                },
            },
        },
      MuiPopover: {
        styleOverrides: {
            paper: {
                backgroundColor: '#f0f0f0', // Asegúrate de que el fondo del popover sea correcto
            },
        },
    },  
        }
    });

export default getMuiTheme