//Imports
import {React, useState, useContext} from 'react'
import { Header, Button } from '../../../../components';
import DataTable from 'react-data-table-component';
import {API_PORT} from "../../../../API_PORT"
import { UserContext } from '../../../../contexts/UserContext';
import Modal from '../../../../components/Modal';
import { Link } from 'react-router-dom';
import notification from "../../../../assets/help.png"
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

//Main function
const Calibration = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const userContext = useContext(UserContext);
  const token = userContext.value.access_token;

  //Reponse management
  const responsenavigate = (response) => {
    if (response.state === "tablas mip pobladas") {
      setMsg("Carga de archivo completada")
      alert("Se ha subido correctamente el archivo")
    }
    else if (response.state === "tablas sector electrico pobladas") {
      setMsg("Carga de archivo completada")
      alert("Se ha subido correctamente el archivo")
    }
    else if (response.state === "tablas emisiones pobladas") {
      setMsg("Carga de archivo completada")
      alert("Se ha subido correctamente el archivo")
    }
    else if (response.state === "tablas de empleo pobladas") {
      setMsg("Carga de archivo completada")
      alert("Se ha subido correctamente el archivo")
    }
    else if (response.state === "tablas pobladas de glosa para la calibración, pobladas") {
      setMsg("Carga de archivo completada")
      alert("Se ha subido correctamente el archivo")
    }
    else if (response.state === "tablas de grupos para la calibración, pobladas") {
      setMsg("Carga de archivo completada")
      alert("Se ha subido correctamente el archivo")
    }
    else if (response.state === "tablas de los datos de la MIP para la calibración, pobladas") {
      setMsg("Carga de archivo completada")
      alert("Se ha subido correctamente el archivo")
    }
    else if (response.state === "tablas de configuración de calibración, pobladas") {
      setMsg("Carga de archivo completada")
      alert("Se ha subido correctamente el archivo")
    }
    else if (response.state === "tablas historicas pobladas") {
      setMsg("Carga de archivo completada")
      alert("Se ha subido correctamente el archivo")
    }
    else if (response.state === "tablas limpias") {
      setMsg("Tablas eliminadas")
      alert("Se han eliminado correctamente todas las tablas de las bases de datos del año y país seleccionado")
    }
    else if (response.state === "Calibración realizada") {
      setMsg("Calibración completada")
      alert("Se ha completado la calibración exitosamente")
    }
    else if (response.state === "tablas de resumen para módulo mip calculadas") {
      setMsg("Cálculo completado")
      alert("Se ha completado el cálculo de MIP exitosamente")
    }
    else if (response.state === "Huella de carbono calculada") {
      setMsg("Cálculo completado")
      alert("Se ha completado el cálculo de la huella de carbono exitosamente")
    }
    else if (response.state === "Multiplicadores de empleo calculados") {
      setMsg("Cálculo completado")
      alert("Se ha completado el cálculo de los multiplicadores de empleo exitosamente")
    }
    else {
      console.log(response)
      setMsg("Proceso fallido")
      alert(response.detail)
    }
    setLoaderScreen(prevState => {
      return {...prevState, started: false}
    })
  }

  const [progress, setProgress] = useState({started: false, pc: 0});
  const [loaderscreen, setLoaderScreen] = useState({started: false});
  const [msg, setMsg] = useState(null);
  const country_options = [
    { value: "", label: "Selecciona el país"},
    { value: "chile", label: "Chile"}]
  ;
  const year_options = [
    { value: 0, label: "Selecciona el año"},
    { value: 2020, label: "2020"}]
  ; 
  const [values, setValues] = useState({
    "country_name": "",
    "year": "",
  });

  const handleInput = (event) =>{
    setValues(prev => ({...prev, [event.target.name]:event.target.value}))
  };

  const [file, setFile] = useState();
  const handleFileUpload = (event) => {
    setFile(event.target.files[0])
  }

  //Calibration Endpoints functions
  function handleSubmit({endpoint,file_type}){
    var new_link = API_PORT+endpoint+values.country_name+"/"+values.year+"?token="+token;
    if (file_type === "historical_excel_input"){
      new_link= API_PORT+endpoint+values.country_name+"?token="+token
    }
    if (values.country_name === "" || values.year === 0){
      alert("No se ha seleccionado el país o año.")
    }
    else if (file && endpoint !== "/admin/calibration/clean-BaseYear-tables/" && endpoint !== "/admin/calibration/do-calibration/" && endpoint !== "/admin/calibration/calculate-resumed-tables/" && endpoint !== "/admin/calibration/calculate-carbon-footprint/" && endpoint !== "/admin/calibration/calculate-labor-multipliers/"){
      const fd = new FormData()
      fd.append(file_type, file)
      setIsModalOpen(true)
      setMsg("Subiendo el archivo. No cierre esta ventana hasta completar el proceso.")
      setProgress(prevState => {
        return {...prevState, started: true}
      })
      setLoaderScreen(prevState => {
        return {...prevState, started: true}
      })
      fetch(new_link, {
        onUploadProgress: (progressEvent) => {setProgress(prevState => {
          return {...prevState, pc: progressEvent.progress*100}
        })},
        method: 'POST',
        body: fd,
        headers: undefined
        }).then((res => res.json()))
        .catch((error) => {
          console.error(error)
          alert("No se ha podido realizar el proceso. Recarga tu sesión o inténtalo nuevamente más tarde.")
          setMsg("Proceso fallido")
          setLoaderScreen(prevState => {
            return {...prevState, started: false}
          })
        })
        .then((response) => responsenavigate(response));
    }
    else if(endpoint === "/admin/calibration/clean-BaseYear-tables/"){
      setIsModalOpen(true)
      setMsg("Eliminando las tablas. No cierre esta ventana hasta completar el proceso.")
        setProgress(prevState => {
        return {...prevState, started: true}
            })
        setLoaderScreen(prevState => {
          return {...prevState, started: true}
        })
        fetch(new_link, {
            onUploadProgress: (progressEvent) => {setProgress(prevState => {
                return {...prevState, pc: progressEvent.progress*100}
            })},
            method: 'DELETE',
            headers: {
                "Content-Type":"application/json"
            }
            }).then((res => res.json()))
            .catch((error) => {
              console.error(error)
              alert("No se ha podido realizar el proceso. Recarga tu sesión o inténtalo nuevamente más tarde.")
              setMsg("Proceso fallido")
              setLoaderScreen(prevState => {
                return {...prevState, started: false}
              })
            })
            .then((response) => responsenavigate(response));
    }
    else if(endpoint === "/admin/calibration/do-calibration/" || endpoint === "/admin/calibration/calculate-resumed-tables/" || endpoint === "/admin/calibration/calculate-carbon-footprint/" || endpoint === "/admin/calibration/calculate-labor-multipliers/"
    ){
      setIsModalOpen(true)
      setMsg("Realizando el cálculo. No cierre esta ventana hasta completar el proceso.")
      setProgress(prevState => {
          return {...prevState, started: true}
      })
      setLoaderScreen(prevState => {
        return {...prevState, started: true}
      })
      fetch(new_link, {
      onUploadProgress: (progressEvent) => {setProgress(prevState => {
          return {...prevState, pc: progressEvent.progress*100}
      })},
      method: 'POST',
      headers: {
          "Content-Type":"aplication/json"
      }
      }).then((res => res.json()))
      .catch((error) => {
        console.error(error)
        alert("No se ha podido realizar el proceso. Recarga tu sesión o inténtalo nuevamente más tarde.")
        setMsg("Proceso fallido")
        setLoaderScreen(prevState => {
          return {...prevState, started: false}
        })
      })
      .then((response) => responsenavigate(response));
    }
    else {
      alert("No se ha seleccionado un campo o no has subido el archivo.")
    }
  };

  const columns = [
    {
      name: "Nombre",
      selector: row => row.name
    },
    {name: "País",
    selector: row => row.country},
    {name: "Año",
    selector: row => row.year},
    {name: "Archivo",
    selector: row => row.action},
    {name: "Acción",
    selector: row => row.upload}
  ];

  const columns2 = [
    {name: "Acción",
    selector: row => row.action},
    {name: "País",
    selector: row => row.country},
    {name: "Año",
    selector: row => row.year},
    {name: "Calibrar/Calcular",
    selector: row => row.upload}
  ];

  const data = [
    {id: 1,
    name: <div className='flex gap-2'>
            <TooltipComponent content="En esta zona se debe cargar el archivo '1_save-mip.xlsx' que contiene toda la información de la MIP para el año y país especificado."><img src={notification} className='w-4 h-4' alt=""></img></TooltipComponent>
            MIP
          </div>,
    country: 
      <select type='string' placeholder='País' name='country_name'
      onChange={handleInput} className='form-select rounded-0'>
      {country_options.map(option =>(<option value={option.value}>{option.label}</option>))}</select>,
    year: <select type='integer' placeholder='Año' name='year'
      onChange={handleInput} className='form-select rounded-0'>
      {year_options.map(option =>(<option value={option.value}>{option.label}</option>))}</select>,
    action: <input type="file"accept=".xlsx"onChange={handleFileUpload}/>,
    upload: <button className="submit blue" onClick={()=> handleSubmit({endpoint: "/admin/calibration/save-mip/", file_type: "mip_input"})}>Subir</button>},

    {id: 2,
      name: <div className='flex gap-2'>
        <TooltipComponent content="En esta zona se debe cargar el archivo '2_save-electric-sector_V2.xlsx' que contiene toda la información del sector eléctrico para el año y país especificado."><img src={notification} className='w-4 h-4' alt=""></img></TooltipComponent>
        Sector Eléctrico
      </div>,
      country: <select type='string' placeholder='País' name='country_name'
        onChange={handleInput} className='form-select rounded-0'>
        {country_options.map(option =>(<option value={option.value}>{option.label}</option>))}</select>,
      year: <select type='integer' placeholder='Año' name='year'
        onChange={handleInput} className='form-select rounded-0'>{year_options.map(option =>(
        <option value={option.value}>{option.label}</option>))}</select>,
      action: <input type="file"accept=".xlsx"onChange={handleFileUpload}/>,
      upload: <button className="submit blue" onClick={()=> handleSubmit({endpoint: "/admin/calibration/save-electric-sector/", file_type: "electric_sector_input"})}>Subir</button>},

      {id: 3,
        name: <div className='flex gap-2'>
        <TooltipComponent content="En esta zona se debe cargar el archivo '3_save-emissions_V2.xlsx' que contiene toda la información de emisiones para el año y país especificado."><img src={notification} className='w-4 h-4' alt=""></img></TooltipComponent>
        Emisiones
      </div>,
        country: <select type='string' placeholder='País' name='country_name'
          onChange={handleInput} className='form-select rounded-0'>
          {country_options.map(option =>(<option value={option.value}>{option.label}</option>))}</select>,
        year: <select type='integer' placeholder='Año' name='year'
          onChange={handleInput} className='form-select rounded-0'>{year_options.map(option =>(
          <option value={option.value}>{option.label}</option>))}</select>,
        action: <input type="file"accept=".xlsx"onChange={handleFileUpload}/>,
        upload: <button className="submit blue" onClick={()=> handleSubmit({endpoint: "/admin/calibration/save-emissions/", file_type: "emissions_input"})}>Subir</button>},

        {id: 4,
          name: <div className='flex gap-2'>
          <TooltipComponent content="Esta zona se utiliza para guardar los datos relacionados con el empleo para el año y país especificado que deben ser subidos con el archivo '4_save-employment.xlsx'."><img src={notification} className='w-4 h-4' alt=""></img></TooltipComponent>
          Empleo
        </div>,
          country: <select type='string' placeholder='País' name='country_name'
            onChange={handleInput} className='form-select rounded-0'>
            {country_options.map(option =>(<option value={option.value}>{option.label}</option>))}</select>,
          year: <select type='integer' placeholder='Año' name='year'
            onChange={handleInput} className='form-select rounded-0'>{year_options.map(option =>(
            <option value={option.value}>{option.label}</option>))}</select>,
          action: <input type="file"accept=".xlsx"onChange={handleFileUpload}/>,
          upload: <button className="submit blue" onClick={()=> handleSubmit({endpoint: "/admin/calibration/save-employment/", file_type: "labor_input"})}>Subir</button>},

          {id: 5,
            name: <div className='flex gap-2'>
            <TooltipComponent content="Esta zona se debe cargar el archivo '5_load-glosa.csv', el cual contiene los nombres de las actividades económicas. Estos datos se utilizan en la calibración de la MIP año base."><img src={notification} className='w-4 h-4' alt=""></img></TooltipComponent>
            Glosa
          </div>,
            country: <select type='string' placeholder='País' name='country_name'
              onChange={handleInput} className='form-select rounded-0'>
              {country_options.map(option =>(<option value={option.value}>{option.label}</option>))}</select>,
            year: <select type='integer' placeholder='Año' name='year'
              onChange={handleInput} className='form-select rounded-0'>{year_options.map(option =>(
              <option value={option.value}>{option.label}</option>))}</select>,
            action: <input type="file"accept=".csv"onChange={handleFileUpload}/>,
            upload: <button className="submit blue" onClick={()=> handleSubmit({endpoint: "/admin/calibration/load_glosa/", file_type: "glosa_load"})}>Subir</button>},

            {id: 6,
              name: <div className='flex gap-2'>
              <TooltipComponent content="Esta zona se debe cargar el archivo '6_load_inp_grupos.csv', el cual contiene las agrupaciones para realizar la calibración de la MIP año base."><img src={notification} className='w-4 h-4' alt=""></img></TooltipComponent>
              Grupos
            </div>,
              country: <select type='string' placeholder='País' name='country_name'
                onChange={handleInput} className='form-select rounded-0'>
                {country_options.map(option =>(<option value={option.value}>{option.label}</option>))}</select>,
              year: <select type='integer' placeholder='Año' name='year'
                onChange={handleInput} className='form-select rounded-0'>{year_options.map(option =>(
                <option value={option.value}>{option.label}</option>))}</select>,
              action: <input type="file"accept=".csv"onChange={handleFileUpload}/>,
              upload: <button className="submit blue" onClick={()=> handleSubmit({endpoint: "/admin/calibration/load_inp_grupos/", file_type: "inp_grupos_load"})}>Subir</button>},

              {id: 7,
                name: <div className='flex gap-2'>
                <TooltipComponent content="En esta zona se debe cargar el archivo '7_mip_to_expand_and_calibrate.csv'. Este contiene la MIP año base en un formato especifico, el cual se usa en la calibración de la MIP año base."><img src={notification} className='w-4 h-4' alt=""></img></TooltipComponent>
                InpMIP
              </div>,
                country: <select type='string' placeholder='País' name='country_name'
                  onChange={handleInput} className='form-select rounded-0'>
                  {country_options.map(option =>(<option value={option.value}>{option.label}</option>))}</select>,
                year: <select type='integer' placeholder='Año' name='year'
                  onChange={handleInput} className='form-select rounded-0'>{year_options.map(option =>(
                  <option value={option.value}>{option.label}</option>))}</select>,
                action: <input type="file"accept=".csv"onChange={handleFileUpload}/>,
                upload: <button className="submit blue" onClick={()=> handleSubmit({endpoint: "/admin/calibration/load_mip_to_expand_and_calibrate/", file_type: "inp_mip_load"})}>Subir</button>},

                {id: 8,
                  name: <div className='flex gap-2'>
                  <TooltipComponent content="En esta zona se debe cargar el archivo '8_load_calibration_configuration'. Este contiene la forma en que se calibra la matriz año base."><img src={notification} className='w-4 h-4' alt=""></img></TooltipComponent>
                  XVGI
                </div>,
                  country: <select type='string' placeholder='País' name='country_name'
                    onChange={handleInput} className='form-select rounded-0'>
                    {country_options.map(option =>(<option value={option.value}>{option.label}</option>))}</select>,
                  year: <select type='integer' placeholder='Año' name='year'
                    onChange={handleInput} className='form-select rounded-0'>{year_options.map(option =>(
                    <option value={option.value}>{option.label}</option>))}</select>,
                  action: <input type="file"accept=".xlsx"onChange={handleFileUpload}/>,
                  upload: <button className="submit blue" onClick={()=> handleSubmit({endpoint: "/admin/calibration/load_calibration_configuration/", file_type: "XVG_in"})}>Subir</button>},
                    {id: 10,
                      name: <div className='flex gap-2'>
                      <TooltipComponent content="Ingresa el año y el país para eliminar todas las bases de datos subidas de estas."><img src={notification} className='w-4 h-4' alt=""></img></TooltipComponent>
                      Eliminar Tablas
                    </div>,
                      country: <select type='string' placeholder='País' name='country_name'
                        onChange={handleInput} className='form-select rounded-0'>
                        {country_options.map(option =>(<option value={option.value}>{option.label}</option>))}</select>,
                      year: <select type='integer' placeholder='Año' name='year'
                      onChange={handleInput} className='form-select rounded-0'>{year_options.map(option =>(
                      <option value={option.value}>{option.label}</option>))}</select>,
                      action: "",
                      upload: <button className="submit bg-red" onClick={()=> handleSubmit({endpoint: "/admin/calibration/clean-BaseYear-tables/"})}>Eliminar Tablas</button>},
  ]

  const data2 = [
    {id: 1,
    action: <div className='flex gap-2'>
    <TooltipComponent content="En esta zona se realiza la calibración de los módulos del año base. Es NECESARIO haber subido anteriormente los 8 archivos del año base. La MIP calibrada se guarda en la base de datos para ser utilizada en el envío de datos y las simulaciones. Es necesario realizar la calibración antes del envío de datos. La calibración puede durar varios minutos, por lo que una vez pulsado el botón, espere en la pestaña hasta completar el proceso para evitar errores."><img src={notification} className='w-4 h-4' alt=""></img></TooltipComponent>
    Realizar Calibración
  </div>,
    country: <select type='string' placeholder='País' name='country_name'
                      onChange={handleInput} className='form-select rounded-0'>
                      {country_options.map(option =>(<option value={option.value}>{option.label}</option>))}</select>,
    year: <select type='integer' placeholder='Año' name='year'
    onChange={handleInput} className='form-select rounded-0'>{year_options.map(option =>(
    <option value={option.value}>{option.label}</option>))}</select>,
    upload: <button className="submit bg-green" onClick={()=> handleSubmit({endpoint: "/admin/calibration/do-calibration/"})}>Calibrar módulos</button>
  },
  {id: 2,
    action: <div className='flex gap-2'>
    <TooltipComponent content="En esta zona se calculan las MIP resumidas para guardarlas en la base de datos. Es necesario haber realizado la calibración anteriormente. Este proceso puede durar varios segundos, por lo que una vez pulsado el botón, espere en la pestaña hasta completar el proceso para evitar errores."><img src={notification} className='w-4 h-4' alt=""></img></TooltipComponent>
    Calcular MIP Resumidas
  </div>,
    country: <select type='string' placeholder='País' name='country_name'
                      onChange={handleInput} className='form-select rounded-0'>
                      {country_options.map(option =>(<option value={option.value}>{option.label}</option>))}</select>,
    year: <select type='integer' placeholder='Año' name='year'
    onChange={handleInput} className='form-select rounded-0'>{year_options.map(option =>(
    <option value={option.value}>{option.label}</option>))}</select>,
    upload: <button className="submit bg-green" onClick={()=> handleSubmit({endpoint: "/admin/calibration/calculate-resumed-tables/"})}>Calcular MIP</button>
  },
  {id: 3,
    action: <div className='flex gap-2'>
    <TooltipComponent content="En esta zona se calcula la huella de carbono de acuerdo con los datos de emisiones y las MIP calibrada año base. Es necesario haber realizado el cálculo y calibración de la MIP anteriormente. Este proceso puede durar varios segundos, por lo que una vez pulsado el botón, espere en la pestaña hasta completar el proceso para evitar errores."><img src={notification} className='w-4 h-4' alt=""></img></TooltipComponent>
    Calcular Huella de Carbono
  </div>,
    country: <select type='string' placeholder='País' name='country_name'
                      onChange={handleInput} className='form-select rounded-0'>
                      {country_options.map(option =>(<option value={option.value}>{option.label}</option>))}</select>,
    year: <select type='integer' placeholder='Año' name='year'
    onChange={handleInput} className='form-select rounded-0'>{year_options.map(option =>(
    <option value={option.value}>{option.label}</option>))}</select>,
    upload: <button className="submit bg-green" onClick={()=> handleSubmit({endpoint: "/admin/calibration/calculate-carbon-footprint/"})}>Calcular Huella</button>
  },
  {id: 4,
    action:<div className='flex gap-2'>
    <TooltipComponent content="En esta zona se calculan los multiplicadores de empleo y se guardan en la base de datos. Es necesario haber realizado la calibración y el cálculo de la MIP anteriormente. Este proceso puede durar varios segundos, por lo que una vez pulsado el botón, espere en la pestaña hasta completar el proceso para evitar errores."><img src={notification} className='w-4 h-4' alt=""></img></TooltipComponent>
    Calcular Multiplicadores de Empleo
  </div>,
    country: <select type='string' placeholder='País' name='country_name'
                      onChange={handleInput} className='form-select rounded-0'>
                      {country_options.map(option =>(<option value={option.value}>{option.label}</option>))}</select>,
    year: <select type='integer' placeholder='Año' name='year'
    onChange={handleInput} className='form-select rounded-0'>{year_options.map(option =>(
    <option value={option.value}>{option.label}</option>))}</select>,
    upload: <button className="submit bg-green" onClick={()=> handleSubmit({endpoint: "/admin/calibration/calculate-labor-multipliers/"})}>Calcular Empleo</button>
  }
  ]

  //Main render
  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
      <div className='flex justify-between items-center mb-6'>
            <Link to="/dashboard/adminbd" >
              <Button
                color="white"
                bgColor="gray"
                text="Volver"
                borderRadius="10px"
                size="md"
              />
            </Link>
      </div>  
      <Header title="Subir archivos de calibración"/>
      <div className='mt-5'>
        <DataTable 
          columns = {columns}
          data= {data}
        >
        </DataTable>
        <div className='mt-6'>
        <Header title="Calibración y cálculo"/>
        </div>
        <DataTable 
          columns = {columns2}
          data= {data2}
        >
        </DataTable>
        <Modal msg={msg} progress={progress} loaderscreen={loaderscreen} isOpen={isModalOpen} closeModal={() => setIsModalOpen(false)}/>
      </div>
    </div>
  )
}

export default Calibration
