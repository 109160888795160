//Imports
import React from 'react';
import './DynamicTable.css'; 

//Use dynamic table for small tables
const DynamicTableMin = ({ title, columns, rows, cellSize }) => {
  const tableStyle = {
    fontSize: cellSize === 'min' ? '7px' : 'inherit', // Establecemos el tamaño de fuente basado en cellSize
  };

  return (
    <div className="table-container">
      {title && <h2 className='mb-3'><strong>{title}</strong></h2>}
      <table className="data-table" style={tableStyle}>
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th key={index}>{column}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <td 
                  key={cellIndex}
                  style={cellIndex === 0 ? { fontWeight: 'bold' } : {}}
                >
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DynamicTableMin;
