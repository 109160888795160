import React from 'react'
import { RiNotification3Line } from 'react-icons/ri';
import { useStateContext } from '../contexts/ContextProvider'
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

const NotificationButton = () => {
    const { readedNotifications, currentColor, handleClick } = useStateContext();

    const NavButton = ({ title, customFunc, icon, color, dotColor }) => (
        <TooltipComponent content={title} position="BottomCenter">
          <button
            type="button"
            onClick={() => customFunc()}
            style={{ color }}
            className="relative text-xl rounded-full p-3 hover:bg-light-gray"
          >
            <span
              style={{ background: dotColor }}
              className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2"
            >
            </span>
            {icon}
          </button>
        </TooltipComponent>
      );

    if(readedNotifications){
        return(<NavButton
          title="Notificaciones"
          dotColor="rgb(254, 201, 15)"
          customFunc={() => handleClick('notification')}
          color={currentColor}
          icon={<RiNotification3Line />}
        />
        )
      }
      else{
        return(
          <NavButton
            title="Notificaciones"
            customFunc={() => handleClick('notification')}
            color={currentColor}
            icon={<RiNotification3Line />}
          />
        )
      }
}

export default NotificationButton