//Imports
import {React, useContext, useState} from 'react'
import { UserContext } from '../../../contexts/UserContext';
import { Header } from '../../../components';
import { API_PORT } from '../../../API_PORT';
import LoadingScreen from '../../../components/LoadingScreen';
import { useNavigate } from 'react-router-dom';

//Main Function
const Services = () => {
  const [loadingscreen, setLoadingScreen] = useState({started: false});
  const [open, setOpen] = useState(false);
  const [texto, setTexto] = useState('');
  const userContext = useContext(UserContext);
  const token = userContext.value.access_token;
  const navigate = useNavigate();

  //Response management function
  function responseNavigate(response){
    if(response.detail === "Email enviado exitosamente"){
      alert("El mensaje se ha enviado exitosamente. Nos comunicaremos contigo vía email para informarte acerca de tu petición.")
      navigate("/dashboard/home")
    }
    else{
      alert(response.detail)
    }
    setOpen(false);
    setLoadingScreen(prevState => {return {...prevState, started: false}});
  }

  //Send peticion function
  const handleSubmit = (event) =>{
    event.preventDefault();
    if (texto !== ""){
      setOpen(true);
      setLoadingScreen(prevState => {return {...prevState, started: true}});
      fetch(API_PORT+"/admin/users_accounts/contacto?token="+token, {
        method: 'POST',
        headers: {
             'Content-type': 'application/json'
        },
        body: JSON.stringify(texto)
        }).then((res => res.json())).catch((error) => console.log(error)).then((response => {responseNavigate(response)})).catch(error => {
          // Manejo del error de conexión u otros errores
          console.error('Error al realizar la solicitud:', error);
          alert("No se han podido enviar tu solicitud. Recarga la página o inténtalo nuevamente más tarde.");
          setOpen(false);
          setLoadingScreen(prevState => ({ ...prevState, started: false }));
      });
    }
    else{
      alert("Ingresa un mensaje para poder enviar la solicitud")
    }
  }
  
  //Main Render
  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
      <Header title="Servicios Adicionales"/>
      <h1 className='mb-5'>En EcoKinesis puedes solicitar servicios personalizados para la plataforma. Escribe tu solicitud y nos pondremos en contacto contigo para recoger tu petición.</h1>
      <label>
      <textarea 
        placeholder='Escribe tus solicitudes de servicios aquí...'
        style={{borderRadius: "5px", border: "1px solid", padding: "10px"}}
        className='mb-6 w-full'
        rows={11}
        cols={40}
        value={texto} // Valor del textarea
        onChange={(e) => setTexto(e.target.value)} // Función para actualizar el valor
      ></textarea>
      </label>
      <button style={{color:"white",backgroundColor:"#425df9", borderRadius:"10px", width:"160px", height:"50px"}} className='W-60' onClick={handleSubmit}>Enviar solicitud</button>
      <LoadingScreen loaderscreen={loadingscreen} isOpen={open} closeModal={() => setOpen(false)}/>
    </div>
  )
}

export default Services