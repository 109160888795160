//Imports
import React, {useState} from 'react'
import './LoginSignup.css'
import user_icon from '../../assets/person.png'
import email_icon from '../../assets/email.png'
import password_icon from '../../assets/password.png'
import logo from '../../assets/white_logo.png'
import { Link, useNavigate } from 'react-router-dom'
import { RegisterValidation } from './Validation'
import { API_PORT } from '../../API_PORT'
import ISCOCodes from './ISCOCodes'
import LoadingScreen from '../../components/LoadingScreen'
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { IoEye, IoEyeOff } from 'react-icons/io5'; // Importa los íconos

// Register User
const RegisterPage = () => {
    //Loading screen and others
    const [loadingscreen, setLoadingScreen] = useState({started: false});
    const [open, setOpen] = useState(false);
    const [showPassword, setShowPassword] = useState(false); // Estado para visibilidad de contraseña
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});

    //Password Visibility function
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    //Register structure
    const [values, setValues] = useState({
        "email": "",
        "username": "",
        "first_name": "",
        "last_name": "",
        "password": "",
        "user_type": "basic", //Use "basic", "premium" or "admin"
        "profesion_level": "0",
        "profesion_code": "0",
        "institution_name": "Sin definir",
        "country": "Sin definir", 
    });

    //Profesion Level options
    const options = [ 
        { value: "1", label: "Academia"}, 
        { value: "2", label: "Sector Público"},
        { value: "3", label: "Sector Privado"},
        { value: "4", label: "Organización no gubernamental"},
      ];


    //Update values
    const handleInput = (event) =>{
        setValues(prev => ({...prev, [event.target.name]:event.target.value}))
    };

    //Register Endpoint
        const handleSubmit = async (e) => {
            e.preventDefault();
            setErrors(RegisterValidation(values));
                if(errors.username === "" && errors.email === "" && errors.password === "" && errors.first_name === "" && errors.last_name === ""){
                    if(values.profesion_level !== "0" && values.profesion_code !== "0"){
                        const url = API_PORT + '/admin/users_accounts/';
                const data = JSON.stringify(values);
                setOpen(true);
                setLoadingScreen(prevState => {return {...prevState, started: true}});
                try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                    },
                    body: data
                });
            
                if (!response.ok) {
                    throw new Error('Error al crear usuario');
                }
            
                const responseData = await response.json();
                console.log('Respuesta del servidor:', responseData);
            
                // Verifica la respuesta del servidor
                if (responseData.detail === 'Usuario creado exitosamente') {
                    // Realiza la navegación a la página principal
                    alert("Tu usuario ha sido creado exitosamente. Valida tu cuenta pulsando en el enlace envíado a tu email y luego solicita permiso a un administrador para poder habilitar tu cuenta y usar la plataforma. Revisa tu carpeta de spam si no encuentras el email.")
                    navigate("/")
                } else {
                    // Maneja otros casos de respuesta del servidor si es necesario
                    console.log('Respuesta no esperada del servidor:', responseData);
                    alert(responseData.detail)
                    setOpen(false);
                    setLoadingScreen(prevState => {return {...prevState, started: false}});
                }
                } catch (error) {
                    console.error('Error al intentar crear usuario:', error.message);
                    alert("Este usuario ya está registrado en la plataforma. Por favor cambie el email y/o nombre de usuario.")
                    setOpen(false);
                    setLoadingScreen(prevState => {return {...prevState, started: false}});
                }
                    }
                    else{
                        alert("Ingresa un área ocupacional y una profesión")
                    }
            }
          };

//Register Page
  return (
    <div>
        <div className='container2 text-white'>
            <h1 style={{position:"absolute", left:"73%", transform: "translateX(-50%);"}} title='Volver a ecokinesis.cl'><Link to="https://ecokinesis.cl/"><strong>Volver al inicio</strong></Link></h1>
        </div>
        <div className='container bg-login'>
            <div className='header'>
                <img src={logo} className="logo"alt=''></img>
                <div className='text'>Registro</div>
                <div className='subtext white'>Ingresa tus datos y recibirás un enlace de verificación en tu correo electrónico para validar tu cuenta.</div>
            </div>
            <div className='container3b'>
            <form action = "" onSubmit={handleSubmit}>
                <div className='inputs'>
                    <div className='container4'>
                        <div className='input-mini'>
                            <img src={user_icon} alt='' />
                            <input type='string' placeholder='Nombre' name='first_name'
                            onChange={handleInput} className='form-control rounded-0' style={{maxWidth:"82px"}}></input>
                            {errors.first_name && <span style={{fontSize: "10px"}}>{errors.first_name}</span>}
                        </div>
                        <div className='input-mini'>
                            <img src={user_icon} alt='' />
                            <input type='string' placeholder='Apellido' name='last_name'
                            onChange={handleInput} className='form-control rounded-0' style={{maxWidth:"82px"}}></input>
                            {errors.last_name && <span style={{fontSize: "10px"}}>{errors.last_name}</span>}
                        </div>
                    </div>
                    <div className='input'>
                        <img src={user_icon} alt='' />
                        <label htmlFor='username'></label>
                        <input type='string' placeholder='Nombre de Usuario' name='username'
                        onChange={handleInput} className='form-control rounded-0'></input>
                        {errors.username && <span className='text-danger' style={{fontSize: "10px"}}>{errors.username}</span>}
                    </div>
                    <div className='container4'>
                        <div className='input-mini'>
                            <img src={user_icon} alt='' />
                            <label htmlFor='profesion_level'></label>
                            <select type='string' placeholder='Área Ocupacional' name='profesion_level'
                            onChange={handleInput} className='form-select rounded-0' style={{
                                maxWidth: "110px", 
                                backgroundColor: "transparent", // Fondo transparente
                                borderColor: "rgba(0, 0, 0, 0.1)" // Si necesitas un borde visible
                            }}>
                            <option value="0">Área Ocupacional</option>
                            {options.map(option =>(
                                <option value={option.value}>{option.label}</option>
                            ))}
                            </select>
                        </div>
                        <div className='input-mini'>
                            <img src={user_icon} alt='' />
                            <label htmlFor='profesion_code'></label>
                            <select type='string' placeholder='Profesión' name='profesion_code'
                            onChange={handleInput} className='form-select rounded-0' style={{
                                maxWidth: "110px", 
                                backgroundColor: "transparent", // Fondo transparente
                                borderColor: "rgba(0, 0, 0, 0.1)" // Si necesitas un borde visible
                            }}>
                            <option value="0">Profesión</option>
                            {ISCOCodes[values.profesion_level].map(option =>(
                                <option value={option.value}>{option.label}</option>
                            ))}
                            </select>
                        </div>
                    </div>
                    <div className='input'>
                        <img src={email_icon} alt='' />
                        <label htmlFor='email'></label>
                        <input type='email' placeholder='Correo Electrónico' name='email'
                        onChange={handleInput} className='form-control rounded-0'/>
                        {errors.email && <span className='text-danger' style={{fontSize: "10px"}}>{errors.email}</span>}
                    </div>
                    <div className='input'>
                        <TooltipComponent content="La contraseña debe cumplir con el formato de por lo menos 8 dígitos, 1 minúscula, 1 mayúscula y 1 número"><img src={password_icon} alt='' /></TooltipComponent>
                        <input type={showPassword ? 'text' : 'password'}
                        placeholder='Contraseña' name='password'
                        onChange={handleInput} className='form-control rounded-0'/>
                        {errors.password && <span className='text-danger' style={{fontSize: "8px"}}>{errors.password}</span>}
                        <button 
                            type='button' 
                            onClick={togglePasswordVisibility}
                            title={showPassword ? 'Ocultar contraseña' : 'Mostrar contraseña'}
                            style={{ background: 'none', border: 'none', cursor: 'pointer' }}
                        >
                            {showPassword ? <IoEyeOff size={20} /> : <IoEye size={20} />}
                        </button>
                    </div>
                </div>
                <div className='container4'>
                    <button className="submit submit-center" type="submit" title='Pulse doble click para registrar su cuenta en EcoKinesis'>Registrarme</button>
                    <button className="submit gray" onClick={() => navigate("/")}>Volver</button>  
                </div>     
            </form>
                <div className='underline'></div>
                <div className='text-center mb-2'>
                    Al crear mi cuenta, confirmo que he leído y estoy de acuerdo con los <Link to="https://ecokinesis.cl/terms-and-conditions.html" className='text-blue' target="_blank" rel="noopener noreferrer">Términos y Condiciones de EcoKinesis</Link>.
                </div>
            </div>
        </div>
        <LoadingScreen loaderscreen={loadingscreen} isOpen={open} closeModal={() => setOpen(false)}/>
    </div>
  )
}

export default RegisterPage