//Imports
import React, { createContext, useContext, useState} from 'react';

//Create and initialize context
const StateContext = createContext();
const initialState ={
    chat: false,
    cart: false,
    userProfile: false,
    notification: false,
};

//ContextProvader is used to set items like menu, color, themeSettings, etc
export const ContextProvider = ({children}) => {
    const [activeMenu,setActiveMenu] = useState (true);
    const[isClicked, setIsClicked] = useState(initialState);
    const [screenSize, setScreenSize] = useState(undefined);
    const [currentColor, setCurrentColor] = useState('#425df9');
    const [currentMode, setCurrentMode] = useState('Light');
    const[themeSettings, setThemeSettings] = useState(false);
    const [readedNotifications, setReadedNotifications] = useState(false);

    //Change mode
    const setMode = (e) => {
        setCurrentMode(e.target.value);
        localStorage.setItem('themeMode', e.target.value);
    }

    //Change color mode
    const setColor = (color) => {
        setCurrentColor(color);
        localStorage.setItem('colorMode', color);
    }

    //Detect clicks
    const handleClick = (clicked) => setIsClicked({ ...initialState, [clicked]: true });
    
    //Return all contexts items
    return (
        <StateContext.Provider value={{
            activeMenu,
            setActiveMenu,
            isClicked,
            initialState,
            setIsClicked,
            handleClick,
            screenSize,
            setScreenSize,
            currentColor, setCurrentColor,
            currentMode, setCurrentMode,
            themeSettings, setThemeSettings,
            readedNotifications, setReadedNotifications,
            setMode, setColor,
            }}>
            {children}
        </StateContext.Provider>
    )
};

export const useStateContext = () => useContext (StateContext);