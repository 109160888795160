//Imports
import {React, useState, useEffect, useContext} from 'react'
import { Header, Button } from '../../../../components';
import { API_PORT } from '../../../../API_PORT';
import { useNavigate, Link } from 'react-router-dom';
import capitalize from '../../../../hook/capitalize';
import { UserContext } from '../../../../contexts/UserContext';
import LoadingScreen from '../../../../components/LoadingScreen';
import { useLocation } from 'react-router-dom';

//Main function
const SimulationOperationSelector1 = () => {
  const userContext = useContext(UserContext);
  const token = userContext.value.access_token;
  const user_type= userContext.value.user_type;
  const { value } = useContext(UserContext);
  const location = useLocation();
  const values_data = location.state?.values;
  const selected_country = values_data ? values_data.country : value?.country ?? "Sin asignar";
  const selected_pelp_year = values_data?.pelp_year ?? "Sin asignar";
  const selected_scenary = values_data?.scenary ?? "Sin asignar";
  const link= API_PORT + "/admin/database_information/get-avaible-MIPs?token="+token
  const link2= API_PORT + "/admin/database_information/get-avaible-PELP-scenarios?token="+token
  const coin_endpoint = API_PORT + "/admin/database_information/get-coin-name?token=" + token
  const [coins, setCoins] = useState([])
  const navigate = useNavigate();
  const [loadingscreen, setLoadingScreen] = useState({started: true});
  const [open, setOpen] = useState(true);
  const [mips, setMips] = useState([])
  const [pelps, setPelps] = useState([])
  const [values, setValues] = useState({
    "country": selected_country,
    "pelp_year": selected_pelp_year,
    "scenary": selected_scenary,
    "year": "",
    "year_simulation": ""
  });

  const handleInput = (event) =>{
    setValues(prev => ({...prev, [event.target.name]:event.target.value}))
  };

  //Obtain MIP data
  useEffect(() => {
    fetch(link, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
      })
      .then(res => res.json())
      .then(data => {
        setMips(data);
        setOpen(false);
        setLoadingScreen(prevState => {return {...prevState, started: false}});
      }).catch(error => {
        // Manejo del error de conexión u otros errores
        console.error('Error al realizar la solicitud:', error);
        alert("No se han podido obtener los datos. Recarga la página o inténtalo nuevamente más tarde.");
        setOpen(false);
        setLoadingScreen(prevState => ({ ...prevState, started: false }));
    })
      fetch(coin_endpoint, {
        method: 'GET',
        headers: {'Content-type': 'application/json'}
        }).then(res => res.json()).then(data => {setCoins(data);})
    fetch(link2, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
      })
      .then(res => res.json())
      .then(data => {
        setPelps(data);
      })
    }, [link, link2, coin_endpoint]);

  var mip_values = mips.MIPbase
  var pelp_year_values = pelps.years
  var scenary_values = pelps.scenarios
  var pelp_year_data= [{value:selected_pelp_year,label:selected_pelp_year}];
  var country_data= [{value:selected_country,label:capitalize(selected_country)}];
  var scenary_data  = [{value:selected_scenary,label:selected_scenary}];
  mip_values?.forEach((element) =>
    {country_data.push({value: element[1], label: capitalize(element[1])})}
  )
  pelp_year_values?.sort()
  pelp_year_values?.forEach((element)=>{
    pelp_year_data.push({value: element, label: element});
  })

  scenary_values?.forEach((element) =>
    {scenary_data.push({value: element, label: element})}
  )

  //Navigate to second selector function
  const SimulationOperationSelector = () => {
    if (values.pelp_year !== "" && values.country !== "Sin asignar" && values.scenary !== "Sin asignar" && coins[values.country]){
        navigate("/dashboard/simulations/main/simulation_operation_selector2", {state: {values, coins}})
    }
    else {
        alert("No has ingresado los datos requeridos.")
    }
  }

  //Page render function
  function ScenaryRender(user){
    if(user === "premium" || user === "admin"){
      return(<div>
        <div className='mt-6'><Header title="Selecciona 1 opción por cada elemento"/></div>
      <div className='grid grid-cols-4 gap-2'>
      <div className='columns-1'>
          <h1 className='mb-3'><strong>País</strong></h1>
          <label htmlFor='country'></label>
            <select type='string' name='country'
              onChange={handleInput} className='form-select rounded-0 min-w-44 max-w-56 h-10' style={{color: "white", backgroundColor: "#425df9", borderRadius: "8px"}}>
              {country_data.map(option =>(
                <option value={option.value} style={{border:"1px solid", color: "gray", backgroundColor: "white"}}>{option.label}</option>
                ))}
            </select>
        </div>
        <div className='columns-1'>
          <h1 className='mb-3'><strong>Año PELP</strong></h1>
            <label htmlFor='pelp_year'></label>
            <select type='string' name='pelp_year'
              onChange={handleInput} className='form-select rounded-0 min-w-44 max-w-56 h-10' style={{color: "white", backgroundColor: "#425df9", borderRadius: "8px"}}>
              {pelp_year_data.map(option =>(
                <option value={option.value} style={{border:"1px solid", color: "gray", backgroundColor: "white"}}>{option.label}</option>
                ))}
            </select>
        </div>
        <div className='columns-1'>
          <h1 className='mb-3'><strong>Escenario</strong></h1>
            <label htmlFor='scenary'></label>
            <select type='string' name='scenary'
              onChange={handleInput} className='form-select rounded-0 min-w-44 max-w-56 h-10' style={{color: "white", backgroundColor: "#425df9", borderRadius: "8px"}}>
              {scenary_data.map(option =>(
                <option value={option.value} style={{border:"1px solid", color: "gray", backgroundColor: "white"}}>{option.label}</option>
                ))}
            </select>
        </div>
      </div>
      <div className='mt-36 flex gap-3'>
        <button style={{color:'white', backgroundColor:"#425df9", borderRadius: "10px"}} className="text-lg p-3" onClick={SimulationOperationSelector}>Pasar a ingresar datos</button>
      </div>
      </div>)
    }
    else{
      return(
        <div className='mt-16'>
                <h1 className='flex justify-center mb-3'><strong>Contrata la suscripción	premium de EcoKinesis para desbloquear los escenarios de operación simulada</strong></h1>
                <div className='flex justify-center'>
                    <div className='mb-8' style={{backgroundColor:"#E0E2E2", borderRadius: "20px", maxWidth:"540px", maxHeight:"380px"}}>
                      <div className='justify-center align-items-center text-center'>
                        <div className="text-3xl" style={{color: "black"}}><strong>Suscripción Premium</strong></div>
                        <div className="text-sm mt-3" style={{color:"gray"}}><strong>Contrata la suscripción premium de EcoKinesis para poder usar esta sección</strong></div>
                        <button className="text-lg" style={{color:"white",backgroundColor:"#425df9",borderRadius:"20px", minWidth: "120px", maxWidth:"260px", height:"54px", marginTop:"10px", marginBottom: "10px"}} onClick={()=>{navigate("/dashboard/premium")}}>Contratar</button>
                      </div>
                    </div>
                </div>
            </div>
      )
    }
  }

  //Main render
  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
        <div className='flex justify-between items-center mb-6'>
            <Link to="/dashboard/simulations/main/simulation_operation_menu" >
              <Button
                color="white"
                bgColor="gray"
                text="Volver al menú de escenarios de operación simulada"
                borderRadius="10px"
                size="md"
              />
            </Link>
      </div>  
      <div className='flex gap-4'><Header title="Escenarios operación simulada"></Header></div>
      <p className='text-gray-500'>En esta sección podrás generar tus propias simulaciones de operación calculando niveles de generación por tecnología con MSO. Ingresa un país, un año PELP y un escenario para pasar a ingresar los datos posteriormente.</p>
      {ScenaryRender(user_type)}
      <LoadingScreen loaderscreen={loadingscreen} isOpen={open} closeModal={() => setOpen(false)}/>
    </div>
  )
}

export default SimulationOperationSelector1