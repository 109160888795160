//Imports
import {React, useState} from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import FullScreen from '../../../../components/FullScreen';
import capitalize from '../../../../hook/capitalize';
import filedownload from "../../../../assets/file.svg"
import download from "../../../../assets/download_black.svg"
import expand from "../../../../assets/expand.svg"
import edit from "../../../../assets/edit.svg"
import save from "../../../../assets/save_black.svg"
import { CSVLink } from 'react-csv';
import MUIDataTable, {TableToolbar} from "mui-datatables";
import { useStateContext } from '../../../../contexts/ContextProvider';
import '../../../../hook/Tables.css'
import { ThemeProvider } from '@mui/material/styles';
import getMuiTheme from '../../../../hook/getMuiTheme';

//Main function
const DirectFinalDemandResults = () => {
  const {activeMenu} = useStateContext();
  const maxWidth = activeMenu ? '1080px' : '100%';
  const location = useLocation();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const values = location.state.values
  const technologies = location.state.technologies
  const directfinaldemand = location.state.directfinaldemand
  const coins = location.state.coins
  const options={
    rowsPerPage: 10,
    rowsPerPageOptions: [1,2,3,4,5,6,7,8,9,10,15,20,25,30],
    selectableRows: "none",
    tableBodyMaxHeight: "345px",
    print: "false",
    download: "false",
    filter: "false",
    responsive: "standard",
    fixedHeader: true,
    fixedSelectColumn: true,
    rowHover: false,
    textLabels: {
      toolbar: {
        search: "Buscar",  // Cambiar el texto del tooltip de "Search" a "Buscar fila"
        viewColumns: "Mostrar/Ocultar columnas",
        showColumns: "A"
      },
      pagination: {
        rowsPerPage: "Filas por página:",  // Cambiar el texto de "Rows per page" a "Filas por página"
        next: "Siguiente página",
        previous: "Página anterior",
        displayRows: "de",
      },
      body: {
        noMatch: "No hay datos disponibles", // Cambia este texto a lo que necesites
      },
      viewColumns: {
        title: "Mostrar/Ocultar columnas",  // Cambiar el texto de "Show Columns"
        titleAria: "Mostrar/Ocultar columnas",  // Cambiar el texto accesible de "Show/Hide Columns"
      },
    }
  }

  //Indicators and graphic-table options
  const [appState, changeState] = useState({
    activeObject: null,
    activeGraphic: null,
    objects: [1,2,3],
  })

  //Activate buttons functions
  function toggleActive(index){
    if (index === 0 || index === 1 || index === 2){
      changeState({...appState, activeObject: appState.objects[index]})
    }
    
  }
  function toggleActiveStyles(index){
    if(appState.objects[index] === appState.activeObject){
      return "box-active"
    }
    else {
      return "box-inactive"
    }
  }

  //Colums and data
  var MIP_base_year_columns = [""]
  var MIP_base_year_data = []
  var MIP_scenario_columns = [""]
  var MIP_scenario_data = []
  var MIP_Growth_rates_columns = [""]
  var MIP_Growth_rates_data = []
  var array_data;

  //Function to fix columns design
 function columns_convert(columns) { 
  return columns.map((column, index) => ({
    name: column,
    label: column,
    options: {
      viewColumns: index === 0 ? false : true, // La primera columna no aparecerá en el menú 'ViewColumns'
      
      // Estilos del encabezado con bordes y sticky aplicado a la primera columna
      customHeadRender: (columnMeta) => {
        return (
          <th
            key={columnMeta.index}
            className="custom-header custom-cell-spacing"
            style={{
              background: "#b0b0b0",  // Color de fondo gris oscuro para el encabezado
              color: "white",  // Texto en blanco para el encabezado
              fontWeight: "bold",  // Texto en negrita
              textAlign: 'center',  // Centrar el contenido en el encabezado
              position: 'sticky',  // Sticky para todas las celdas del encabezado
              top: 0,  // Fijar la fila del encabezado en la parte superior
              left: index === 0 ? 0 : 'auto',  // Fijar la primera columna
              zIndex: index === 0 ? 102 : 101,  // Asegura que el encabezado esté por encima del contenido
              height: '50px',  // Ajustar el alto del encabezado
              verticalAlign: 'middle',  // Alinear verticalmente al centro
            }}
          >
            {columnMeta.label}
          </th>
        );
      },
    }
  }));
}

  //Set the data on arrays
  location.state.directfinaldemand.MIP_base_year.data?.forEach((element) => {
    var y = capitalize(element.y_index)
    if (!MIP_base_year_columns.includes(y)){
      MIP_base_year_columns.push(capitalize(y));
    }
  })
  location.state.directfinaldemand.MIP_base_year.data?.forEach((element) => {
    var x = element.x_index
    var new_array= [x].concat(Array(MIP_base_year_columns.length-1).fill(null))
    var flag = true
    MIP_base_year_data.forEach((element2) => {
      if (element2[0] === x){
        flag=false
      }
    })
    if (flag){
      MIP_base_year_data.push(new_array)
    }
  })
  location.state.directfinaldemand.MIP_base_year.data?.forEach((element) => {
    var x = element.x_index
    var v= element.value
    var y= capitalize(element.y_index)
    MIP_base_year_data.forEach((element2,index2) =>{
      if (x === element2[0] && v!== null){
        MIP_base_year_data[index2][MIP_base_year_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })


  location.state.directfinaldemand.MIP_scenario.data?.forEach((element) => {
    var y = capitalize(element.y_index)
    if (!MIP_scenario_columns.includes(y)){
      MIP_scenario_columns.push(capitalize(y));
    }
  })
  location.state.directfinaldemand.MIP_scenario.data?.forEach((element) => {
    var x = element.x_index
    var new_array= [x].concat(Array(MIP_scenario_columns.length-1).fill(null))
    var flag = true
    MIP_scenario_data.forEach((element2) => {
      if (element2[0] === x){
        flag=false
      }
    })
    if (flag){
      MIP_scenario_data.push(new_array)
    }
  })
  location.state.directfinaldemand.MIP_scenario.data?.forEach((element) => {
    var x = element.x_index
    var v= element.value
    var y= capitalize(element.y_index)
    MIP_scenario_data.forEach((element2,index2) =>{
      if (x === element2[0] && v!== null){
        MIP_scenario_data[index2][MIP_scenario_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })

  location.state.directfinaldemand.MIP_Growth_rates.data?.forEach((element) => {
    var y = capitalize(element.y_index)
    if (!MIP_Growth_rates_columns.includes(y)){
      MIP_Growth_rates_columns.push(capitalize(y));
    }
  })
  location.state.directfinaldemand.MIP_Growth_rates.data?.forEach((element) => {
    var x = element.x_index
    var new_array= [x].concat(Array(MIP_Growth_rates_columns.length-1).fill(null))
    var flag = true
    MIP_Growth_rates_data.forEach((element2) => {
      if (element2[0] === x){
        flag=false
      }
    })
    if (flag){
      MIP_Growth_rates_data.push(new_array)
    }
  })
  location.state.directfinaldemand.MIP_Growth_rates.data?.forEach((element) => {
    var x = element.x_index
    var v= element.value
    var y= capitalize(element.y_index)
    MIP_Growth_rates_data.forEach((element2,index2) =>{
      if (x === element2[0] && v!== null){
        MIP_Growth_rates_data[index2][MIP_Growth_rates_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })


 


  //Display graphics and tables function
  function GraphicDisplay(){
    if(appState.activeObject === 1){
      return(<div className='mt-3 flex justify-center items-center'>
        <div className='custom-table-container' style={{ maxWidth }}>
        <ThemeProvider theme={getMuiTheme()}><MUIDataTable
        components={{TableToolbar: (props) => (
          <div style={{display: 'flex',justifyContent: 'space-between' , alignItems: 'center', backgroundColor: '#b0b0b0',padding: '4px',}}>
            {/* Título personalizado */}
            <div style={{ color: 'black', fontWeight: 'bold', fontSize: '22px', paddingLeft: "16px" }}>
                {location.state.directfinaldemand.MIP_base_year.title}
            </div>
            {/* Mantener los íconos del toolbar predeterminado, sin duplicar el título */}
            <TableToolbar {...props} title={null} />
          </div>
        )}}
        data={MIP_base_year_data}
        columns={columns_convert(MIP_base_year_columns)}
        options={options}
        ></MUIDataTable></ThemeProvider>
        </div>
      </div>
      )
    }
    else if(appState.activeObject === 2){
      return(<div className='mt-3 flex justify-center items-center'>
        <div className='custom-table-container' style={{ maxWidth }}>
        <ThemeProvider theme={getMuiTheme()}><MUIDataTable
        components={{TableToolbar: (props) => (
          <div style={{display: 'flex',justifyContent: 'space-between' , alignItems: 'center', backgroundColor: '#b0b0b0',padding: '4px',}}>
            {/* Título personalizado */}
            <div style={{ color: 'black', fontWeight: 'bold', fontSize: '22px', paddingLeft: "16px" }}>
                {location.state.directfinaldemand.MIP_scenario.title}
            </div>
            {/* Mantener los íconos del toolbar predeterminado, sin duplicar el título */}
            <TableToolbar {...props} title={null} />
          </div>
        )}}
        data={MIP_scenario_data}
        columns={columns_convert(MIP_scenario_columns)}
        options={options}
        ></MUIDataTable></ThemeProvider>
        </div>
      </div>
      )
    }
    else if(appState.activeObject === 3){
      return(<div className='mt-3 flex justify-center items-center'>
        <div className='custom-table-container' style={{ maxWidth }}>
        <ThemeProvider theme={getMuiTheme()}><MUIDataTable
        components={{TableToolbar: (props) => (
          <div style={{display: 'flex',justifyContent: 'space-between' , alignItems: 'center', backgroundColor: '#b0b0b0',padding: '4px',}}>
            {/* Título personalizado */}
            <div style={{ color: 'black', fontWeight: 'bold', fontSize: '22px', paddingLeft: "16px" }}>
                {location.state.directfinaldemand.MIP_Growth_rates.title}
            </div>
            {/* Mantener los íconos del toolbar predeterminado, sin duplicar el título */}
            <TableToolbar {...props} title={null} />
          </div>
        )}}
        data={MIP_Growth_rates_data}
        columns={columns_convert(MIP_Growth_rates_columns)}
        options={options}
        ></MUIDataTable></ThemeProvider>
        </div>
      </div>
      )
    }
    else{
      return(<div></div>)
    }
  }

  //Generate CSV function
  function CSVData(){
    if(appState.activeObject === 1 ){
      array_data = []
      MIP_base_year_data.forEach((element) =>{
        MIP_base_year_columns.forEach((element2,index2)=>{
          if(index2 !== 0){
            array_data.push([element[0],element2,element[index2]])
          }
        })
      })
      return([['Actividad económica X','Actividad económica Y','['+location.state.technologies.Unities+']']].concat(array_data))
    }
    else if(appState.activeObject === 2){
      array_data = []
      MIP_scenario_data.forEach((element) =>{
        MIP_scenario_columns.forEach((element2,index2)=>{
          if(index2 !== 0){
            array_data.push([element[0],element2,element[index2]])
          }
        })
      })
      return([['Actividad económica X','Actividad económica Y','['+location.state.technologies.Unities+']']].concat(array_data))
    }
    else if(appState.activeObject === 3 ){
      array_data = []
      MIP_Growth_rates_data.forEach((element) =>{
        MIP_Growth_rates_columns.forEach((element2,index2)=>{
          if(index2 !== 0){
            array_data.push([element[0],element2,element[index2]])
          }
        })
      })
      return([['Actividad económica X','Actividad económica Y','Variación porcentual '+location.state.technologies.Unities]].concat(array_data))
    }
    else{
      return([[""]])
    }
  }


  //Generate CSV titles function
  function CSVTitle(){
    if(appState.activeObject === 1){
      return(location.state.directfinaldemand.MIP_base_year.title+".csv")
    }
    else if(appState.activeObject === 2 ){
      return(location.state.directfinaldemand.MIP_scenario.title+".csv")
    }
    else if(appState.activeObject === 3){
      return(location.state.directfinaldemand.MIP_Growth_rates.title+".csv")
    }
    else{
      return("")
    }
  }


  //Display descriptions function
  function TextDisplay(){
    if (appState.activeObject === 1){
      return("MIP Año Base: La tabla muestra la MIP o matriz insumo producto base del año y país ingresado. Esta se agrupa por los sectores de actividades económicas para los ejes y sus valores se miden en " + coins[values.country] + ".")
    }
    else if (appState.activeObject === 2){
      return("MIP Escenario: La tabla muestra la MIP o matriz insumo producto escenario del año y país ingresado. Esta se agrupa por los sectores de actividades económicas para los ejes y sus valores se miden en " + coins[values.country] + ".")
    }
    else if (appState.activeObject === 3){
      return("MIP Ratio Crecimiento: La tabla muestra el crecimiento porcentual de la MIP o matriz insumo producto respecto al año y país ingresado. Esta se agrupa por los sectores de actividades económicas para los ejes y sus valores se miden en variación porcentual de " + location.state.technologies.Unities + ".")
    }
    else {
      return("")
    }
  }

  //Main render
  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
      <div className='flex'> 
        <div className='w-3/5 h-14 grid grid-cols-3 gap-x-3 p-2' style={{backgroundColor: "white", border: "1px solid", borderRadius:"10px", borderColor: "gray"}}>
          <button onClick={() => {toggleActive(0)}} className={`max-h-10 max-w-56 rounded-lg ${toggleActiveStyles(0)}`}>MIP Año Base</button>
          <button onClick={() => {toggleActive(1)}} className={`max-h-10 max-w-56 rounded-lg ${toggleActiveStyles(1)}`}>MIP Escenario</button>
          <button onClick={() => {toggleActive(2)}} className={`max-h-10 max-w-56 rounded-lg ${toggleActiveStyles(2)}`}>MIP Ratio Crecimiento</button>
        </div>
        <div className='grid grid-cols-5 ml-auto w-1/4 gap-x-4'>
          <div className='justify-center items-center text-center' style={{fontSize: "10px"}}><button onClick={() => {setIsModalOpen(true)}} title='Pulsa para abrir la tabla o gráfico actual en pantalla completa'><img src={expand} className='w-10 h-10' alt=''/>Expandir pantalla</button></div>
          <div className='justify-center items-center text-center' style={{fontSize: "10px"}}><button onClick={() => {navigate("/dashboard/simulations/main/leontieff_scenary/direct_final_demand_reports", {state: {MIP_base_year_columns, MIP_base_year_data, MIP_scenario_columns, MIP_scenario_data, MIP_Growth_rates_columns
            , MIP_Growth_rates_data, values, technologies, directfinaldemand, coins}})}} title='Pulsa para ir al menú de guardado y descarga de reportes'><img src={save} className='w-10 h-10' alt=''/>Guardar reporte</button></div>
          <div className='justify-center items-center text-center' style={{fontSize: "10px"}}><button onClick={() => {navigate("/dashboard/simulations/main/leontieff_scenary/direct_final_demand_reports", {state: {MIP_base_year_columns, MIP_base_year_data, MIP_scenario_columns, MIP_scenario_data, MIP_Growth_rates_columns
            , MIP_Growth_rates_data, values, technologies, directfinaldemand, coins}})}} title='Pulsa para ir al menú de guardado y descarga de reportes'><img src={download} className='w-10 h-10' alt=''/>Descargar reporte</button></div>
          <div className='justify-center items-center text-center' style={{fontSize: "10px"}}><button title='Pulsa para descargar la tabla o gráfico actual en formato CSV'><CSVLink data={CSVData()} filename={CSVTitle()}><img src={filedownload} className='w-10 h-10' alt=''/>Convertir a CSV</CSVLink></button></div>
          <div className='justify-center items-center text-center' style={{fontSize: "10px"}}><button onClick={() => {navigate("/dashboard/simulations/main/leontieff_scenary/direct_final_demand_selector2", {state: {values, technologies, coins}})}} title='Pulsa para volver a la pantalla de selección de datos anterior'><img src={edit} className='w-10 h-10' alt=''/>Volver a selección</button></div>
        </div>
      </div>
      <div className='mt-1 mb-1'><p className='text-3xl font-extrabold tracking-tight text-slate-900'>Shock demanda final directa {capitalize(values.country)} {values.year}</p></div>
      <div className='flex gap-4'>
        <h4 className='font-bold text-gray-400 w-3/5 text-sm mt-1'>{TextDisplay()}</h4>
      </div>
      <div className='mt-3' style={{maxHeight: "490px"}}>
          {GraphicDisplay()}
      </div>
      <FullScreen isOpen={isModalOpen} closeModal={() => setIsModalOpen(false)} item={GraphicDisplay()}/>
    </div>
  )
}

export default DirectFinalDemandResults