//Imports
import {React} from 'react'
import { Header, Button} from '../../../../components';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import capitalize from '../../../../hook/capitalize';

//Main function
const SimulationOperationInformation = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const values = location.state.values;
    const configuration = location.state.configuration;
    const coins = location.state.coins;
    const date = location.state.date;
    const name = location.state.name;
    const time = location.state.time;

    function Encendido(costos){
        if(costos === true){return("Incluidos")}
        else{return("No incluidos")}
      }
    
      function Tiempo(tiempos){
        if(tiempos === true){return("Incluidos")}
        else{return("No incluidos")}
      }
    
      function Rampa(ramp){
        if(ramp === true){return("Incluidos")}
        else{return("No incluidos")}
      }
    
      //Page render
  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
    <div className='flex justify-between items-center mb-5'>
          <Link to="/dashboard/simulations/main/simulation_operation_list" >
            <Button
              color="white"
              bgColor="gray"
              text="Volver a la lista de escenarios de operación simulada"
              borderRadius="10px"
              size="md"
            />
          </Link>
    </div> 
    <Header title="Datos de escenario de operación simulada"/>
    <h1 className='flex justify-center items-center mt-4'><strong>Datos {name}</strong></h1>
                        <h1 className='flex justify-center items-center' style={{fontSize: "13px"}}>País Ingresado: {capitalize(values.country)}</h1>
                        <h1 className='flex justify-center items-center' style={{fontSize: "13px"}}>Año PELP Ingresado: {values.pelp_year}</h1>
                        <h1 className='flex justify-center items-center' style={{fontSize: "13px"}}>Escenario Ingresado: {values.scenary}</h1>
                        <h1 className='flex justify-center items-center' style={{fontSize: "13px"}}>Fecha del escenario: {date}</h1>
                        <h1 className='flex justify-center items-center' style={{fontSize: "13px"}}>Tiempo de ejecución del escenario: {time}</h1>
    <h1 className='flex justify-center items-center mt-6'><strong>Valores Ingresados</strong></h1>
    <div className='grid grid-cols-2'>
      <div>
      <h1 className='flex justify-center items-center mt-3'><strong>Configuración de Escenario</strong></h1>
                        <h1 className='flex justify-center items-center' style={{fontSize: "13px"}}>Solver: {configuration.Solver}</h1>
                        <h1 className='flex justify-center items-center' style={{fontSize: "13px"}}>Costos de encedido: {Encendido(configuration.CostosEncendido)}</h1>
                        <h1 className='flex justify-center items-center' style={{fontSize: "13px"}}>Restricciones de tiempo mínimo: {Tiempo(configuration.Mintime)}</h1>
                        <h1 className='flex justify-center items-center' style={{fontSize: "13px"}}>Restricciones de rampa: {Rampa(configuration.Ramp)}</h1>
      </div>
      <div>
      <h1 className='flex justify-center items-center'><strong>Porcentajes de factores de escenario</strong></h1>
                        <h1 className='flex justify-center items-center' style={{fontSize: "13px"}}>Incremento precio combustible: {configuration.Combustible}%</h1>
                        <h1 className='flex justify-center items-center' style={{fontSize: "13px"}}>Incremento de eficiencia: {configuration.Eficiencia}%</h1>
                        <h1 className='flex justify-center items-center' style={{fontSize: "13px"}}>Disponibilidad Hídrica: {configuration.Hidrica}%</h1>
                        <h1 className='flex justify-center items-center' style={{fontSize: "13px"}}>Factor de Demanda: {configuration.FactorDemanda}%</h1>
                        <h1 className='flex justify-center items-center' style={{fontSize: "13px"}}>Factor de Demanda de H2: {configuration.DemandaH2}%</h1>
      </div>
    </div>
                        
                        

    <div className='flex justify-center items-center mt-10'><TooltipComponent content="Al pulsar este botón se enviarán los datos actuales a la zona de selección de datos del escenario de operación simulada."><button style={{color:'white', backgroundColor:"#425df9", borderRadius: "10px"}} className="text-lg p-3" onClick={()=>{navigate("/dashboard/simulations/main/simulation_operation_selector2",{ state: { configuration, values, coins } })}}>Utilizar datos en escenario de operación simulada</button></TooltipComponent></div>
  </div>
  )
}

export default SimulationOperationInformation