//ISCOCodes for Login
const ISCOCodes = {
    "0": [{ value: "0", label: ""}],
    "1": [ 
      { value: "1", label: "Ingeniería"}, 
      { value: "2", label: "Sector Económico"},
      { value: "3", label: "Ciencias de la administración"},
      { value: "4", label: "Ciencias sociales"},
      { value: "5", label: "Sector medioambiental"} 
      ],
    "2": [ 
        { value: "1", label: "Ingeniería"}, 
        { value: "2", label: "Sector Económico"},
        { value: "3", label: "Ciencias de la administración"},
        { value: "4", label: "Ciencias sociales"},
        { value: "5", label: "Sector medioambiental"}
      ],
    "3": [ 
      { value: "1", label: "Ingeniería"}, 
      { value: "2", label: "Sector Económico"},
      { value: "3", label: "Ciencias de la administración"},
      { value: "4", label: "Ciencias sociales"},
      { value: "5", label: "Sector medioambiental"} 
      ],
    "4": [ 
      { value: "1", label: "Ingeniería"}, 
      { value: "2", label: "Sector Económico"},
      { value: "3", label: "Ciencias de la administración"},
      { value: "4", label: "Ciencias sociales"},
      { value: "5", label: "Sector medioambiental"}
      ],
  }

export default ISCOCodes