//Imports
import {React, useState, useEffect, useContext} from 'react'
import { Link } from 'react-router-dom';
import { Header, Button } from '../../../../components';
import capitalize from '../../../../hook/capitalize';
import { API_PORT } from '../../../../API_PORT';
import { UserContext } from '../../../../contexts/UserContext';
import LoadingScreen from '../../../../components/LoadingScreen';
import "../../../../hook/Tables.css"
import MUIDataTable, {TableToolbar} from 'mui-datatables';

//Main function
const AvailablePELP = () => {
  const [pelps, setPelps] = useState([])
  const userContext = useContext(UserContext);
  const token = userContext.value.access_token;
  const [loadingscreen, setLoadingScreen] = useState({started: true});
  const [open, setOpen] = useState(true);
  const link= API_PORT + "/admin/database_information/get-avaible-PELP-scenarios?token="+token
  const year_columns = ["Años"]
  const scenary_columns = ["Escenarios"]
  const technologies_columns = ["Tecnologías"]
  var year_data= [];
  var scenary_data  = [];
  var technologies_data  = []
  const options={
    rowsPerPage: 10,
    rowsPerPageOptions: [1,2,3,4,5,6,7,8,9,10,15,20,25,30],
    selectableRows: "none",
    tableBodyMaxHeight: "223px",
    print: "false",
    download: "false",
    filter: "false",
    viewColumns: "false",
    responsive: "vertical",
    textLabels: {
      toolbar: {
        search: "Buscar",  // Cambiar el texto del tooltip de "Search" a "Buscar fila"
      },
      pagination: {
        rowsPerPage: "Filas por página:",  // Cambiar el texto de "Rows per page" a "Filas por página"
        next: "Siguiente página",
        previous: "Página anterior",
        displayRows: "de",
      },
      body: {
        noMatch: "No hay PELP disponibles", // Cambia este texto a lo que necesites
      },
    },
  }

  //Get Pelp Data
  useEffect(() => {
    fetch(link, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
      })
      .then(res => res.json())
      .then(data => {
        setPelps(data);
        setOpen(false);
        setLoadingScreen(prevState => {return {...prevState, started: false}});
      }).catch(error => {
        console.error('Error al realizar la solicitud:', error);
        alert("No se han podido obtener los datos. Recarga la página o inténtalo nuevamente más tarde.");
        setOpen(false);
        setLoadingScreen(prevState => ({ ...prevState, started: false }));
    })
    }, [link]);

  var year_values = pelps.years
  var scenary_values = pelps.scenarios
  var technologies_values = pelps.technologies
  year_values?.sort()
  year_values?.forEach((element) =>
    {year_data.push([element])}
  )
  scenary_values?.forEach((element) =>
    {scenary_data.push([element])}
  )
  technologies_values?.forEach((element) =>
    {technologies_data.push([capitalize(element)])}
  )

  //Function to fix columns design
  function columns_convert(columns) { 
    return columns.map((column, index) => ({
      name: column,
      label: column,
      options: {
        // Estilos del encabezado con bordes y sticky aplicado a la primera columna
        customHeadRender: (columnMeta) => {
          return (
            <th
              key={columnMeta.index}
              className="custom-header custom-cell-spacing"
              style={{
                background: "#b0b0b0",  // Color de fondo gris oscuro para el encabezado
                color: "white",  // Texto en blanco para el encabezado
                fontWeight: "bold",  // Texto en negrita
                textAlign: 'center',  // Centrar el contenido en el encabezado
                position: 'sticky',  // Sticky para todas las celdas del encabezado
                top: 0,  // Fijar la fila del encabezado en la parte superior
                left: index === 0 ? 0 : 'auto',  // Fijar la primera columna
                zIndex: index === 0 ? 102 : 101,  // Asegura que el encabezado esté por encima del contenido
                height: '50px',  // Ajustar el alto del encabezado
                verticalAlign: 'middle',  // Alinear verticalmente al centro
              }}
            >
              {columnMeta.label}
            </th>
          );
        },
        // Estilos para las celdas del cuerpo de la tabla
        setCellProps: () => ({
          style: {
            textAlign: 'center',  // Centrar el contenido horizontalmente
            verticalAlign: 'middle',  // Centrar el contenido verticalmente
            borderRight: '1px solid #9c9c9c',  // Borde gris oscuro entre columnas
            borderBottom: '1px solid #9c9c9c',  // Borde gris oscuro debajo de las filas
            backgroundClip: 'padding-box', // Asegura que el fondo no se solape con el borde
            boxSizing: 'border-box',  // Asegura que los bordes y el padding se incluyan en el tamaño de la celda
          },
        }),
      }
    }));
  }

  //Main render
  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
        <div className='flex justify-between items-center mb-4'>
            <Link to="/dashboard/adminbd" >
              <Button
                color="white"
                bgColor="gray"
                text="Volver"
                borderRadius="10px"
                size="md"
              />
            </Link>
        </div>  
      <Header title="Escenarios PELPs disponibles"/>
      <div className='grid grid-cols-3 gap-5'>
        <div className="custom-table-container" style={{maxHeight:"1/4"}}>
              <MUIDataTable
              data={year_data}
              columns={columns_convert(year_columns)}
              options={options}
              components={{
                TableToolbar: (props) => (
                  <div
                    style={{
                      backgroundColor: '#b0b0b0',
                    }}
                  >
                    {/* Mantener los íconos del toolbar predeterminado, sin duplicar el título */}
                    <TableToolbar {...props} title={null} />
                  </div>
                ),
              }}
              ></MUIDataTable>
            </div>
            <div className="custom-table-container" style={{maxHeight:"1/4"}}>
              <MUIDataTable
              data={scenary_data}
              columns={columns_convert(scenary_columns)}
              options={options}
              components={{
                TableToolbar: (props) => (
                  <div
                    style={{
                      backgroundColor: '#b0b0b0',
                    }}
                  >
                    {/* Mantener los íconos del toolbar predeterminado, sin duplicar el título */}
                    <TableToolbar {...props} title={null} />
                  </div>
                ),
              }}
              ></MUIDataTable>
            </div>
            <div className="custom-table-container" style={{maxHeight:"1/4"}}>
              <MUIDataTable
              data={technologies_data}
              columns={columns_convert(technologies_columns)}
              options={options}
              components={{
                TableToolbar: (props) => (
                  <div
                    style={{
                      backgroundColor: '#b0b0b0',
                    }}
                  >
                    {/* Mantener los íconos del toolbar predeterminado, sin duplicar el título */}
                    <TableToolbar {...props} title={null} />
                  </div>
                ),
              }}
              ></MUIDataTable>
            </div>
      </div>
      <LoadingScreen loaderscreen={loadingscreen} isOpen={open} closeModal={() => setOpen(false)}/>
    </div>
  )
}

export default AvailablePELP