//Import
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css'
import { Navigate } from 'react-router-dom';
import { Navbar, Sidebar, ThemeSettings} from "./components";
import {useStateContext} from './contexts/ContextProvider'

//Import Routes
import { Account, ModifyPass, DeleteAccount, AccountNotifications, EditPhoto, EvaluatePlatform, AdminUser, CreateUser, DeleteUser, ModifyUser, ActivateUser, AdminReports, ChangeUserStatus, AdminBD, Calibration, Scenarios, AvailableMIP, AvailablePELP, ResetBD, MSO, AvailableCoins, Database, ResultsDatabase, GeneratePDFDatabase, ExpandedMIP, ExpandedMIPResults, GeneratePDFExpandedMIP, Home, Premium, Reports, Services, Simulations, BasicScenarySelector1, BasicScenarySelector2, BasicScenaryImport, BasicScenaryResults, BasicScenaryPDF, PredefinedScenarySelector, PredefinedScenaryResults, PredefinedScenaryPDF, LeontieffScenary, DirectFinalDemandSelector1, DirectFinalDemandSelector2, DirectFinalDemandResults, DirectFinalDemandPDF, PercentageDemandSetSelector1, PercentageDemandSetSelector2, PercentageDemandSetResults, PercentageDemandSetPDF, PriceModelSelector1, PriceModelSelector2, PriceModelResults, PriceModelPDF, DirectDemandSetSelector1, DirectDemandSetSelector2, DirectDemandSetResults, DirectDemandSetPDF, PriceDemandModelSelector1, PriceDemandModelSelector2, PriceDemandModelResults, PriceDemandModelPDF, ChangeProductPriceSelector1, ChangeProductPriceSelector2, ChangeProductPriceResults, ChangeProductPricePDF, SimulationOperationMenu, SimulationOperationList, SimulationOperationInformation, SimulationOperationSelector1, SimulationOperationSelector2, SimulationOperationResults, SimulationOperationsPDF, Selector} from './pages';

const Main = () => {
    //setThemeSettings, currentColor,
    const { activeMenu, themeSettings, currentMode }= useStateContext();
  
    //Return Main Page
    return (
      <div className={currentMode === 'Dark' ? 'dark': ''}>
          <div className='flex relative dark:bg-main-dark-bg'>
            {activeMenu ?(
              <div className='w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white'>
                <Sidebar />
              </div>
            ):(
              <div className='w-0 dark:bg-secondary-dark-bg'>
                <Sidebar />
              </div>
            )}
            <div 
            className={
              activeMenu
                ? 'dark:bg-main-dark-bg  bg-slate-100 min-h-screen md:ml-72 w-full  '
                : 'bg-slate-100 dark:bg-main-dark-bg  w-full min-h-screen flex-2 '
              }
            >
              <div className='fixed md:static bg-slate-100 dark:bg-main-dark-bg navbar w-full'> 
                <Navbar />
              </div> 
            <div>
              {themeSettings && <ThemeSettings />}
            </div>
            <Routes>
                  {/*Inicio*/}
                  <Route path="home" element={<Home/>} />

                  {/*Selector*/}
                  <Route path="/selector" element={<Selector/>} />
  
                  {/*Base de Datos*/}
                  <Route path="database">
                   <Route index element={<Navigate to="main"/>}/>
                   <Route path="main" element={<Database/>}/>
                   <Route path="results" element={<ResultsDatabase/>}/>
                   <Route path="database_reports" element={<GeneratePDFDatabase/>}/>
                  </Route>

                  {/*MIP Expandida*/}
                  <Route path="expanded_mip">
                    <Route index element={<Navigate to="main"/>}/>
                    <Route path="main" element={<ExpandedMIP/>}/>
                    <Route path="results" element={<ExpandedMIPResults/>}/>
                    <Route path="expanded_mip_reports" element={<GeneratePDFExpandedMIP/>}/>
                  </Route>

                  {/*Simulaciones*/}
                  <Route path="simulations">
                    <Route index element={<Navigate to="main"/>}/>
                    <Route path="main" element={<Simulations/>}/>
                    <Route path="main/basic_scenary_selector_1" element={<BasicScenarySelector1/>}/>
                    <Route path="main/basic_scenary_selector_2" element={<BasicScenarySelector2/>}/>
                    <Route path="main/basic_scenary_import" element={<BasicScenaryImport/>}/>
                    <Route path="main/basic_scenary_results" element={<BasicScenaryResults />}/>
                    <Route path="main/basic_scenary_reports" element={<BasicScenaryPDF/>}/>
                    <Route path="main/predefined_scenary_selector" element={<PredefinedScenarySelector />}/>
                    <Route path="main/predefined_scenary_results" element={<PredefinedScenaryResults />}/>
                    <Route path="main/predefined_scenary_reports" element={<PredefinedScenaryPDF />}/>
                    <Route path="main/leontieff_scenary" element={<LeontieffScenary/>}/>
                    <Route path="main/leontieff_scenary/direct_final_demand_selector1" element={<DirectFinalDemandSelector1/>}/>
                    <Route path="main/leontieff_scenary/direct_final_demand_selector2" element={<DirectFinalDemandSelector2/>}/>
                    <Route path="main/leontieff_scenary/direct_final_demand_results" element={<DirectFinalDemandResults/>}/>
                    <Route path="main/leontieff_scenary/direct_final_demand_reports" element={<DirectFinalDemandPDF/>}/>
                    <Route path="main/leontieff_scenary/percentage_demand_set_selector1" element={<PercentageDemandSetSelector1/>}/>
                    <Route path="main/leontieff_scenary/percentage_demand_set_selector2" element={<PercentageDemandSetSelector2/>}/>
                    <Route path="main/leontieff_scenary/percentage_demand_set_results" element={<PercentageDemandSetResults/>}/>
                    <Route path="main/leontieff_scenary/percentage_demand_set_reports" element={<PercentageDemandSetPDF/>}/>
                    <Route path="main/leontieff_scenary/price_model_selector1" element={<PriceModelSelector1/>}/>
                    <Route path="main/leontieff_scenary/price_model_selector2" element={<PriceModelSelector2/>}/>
                    <Route path="main/leontieff_scenary/price_model_results" element={<PriceModelResults/>}/>
                    <Route path="main/leontieff_scenary/price_model_reports" element={<PriceModelPDF/>}/>
                    <Route path="main/leontieff_scenary/direct_demand_set_selector1" element={<DirectDemandSetSelector1/>}/>
                    <Route path="main/leontieff_scenary/direct_demand_set_selector2" element={<DirectDemandSetSelector2/>}/>
                    <Route path="main/leontieff_scenary/direct_demand_set_results" element={<DirectDemandSetResults/>}/>
                    <Route path="main/leontieff_scenary/direct_demand_set_reports" element={<DirectDemandSetPDF/>}/>
                    <Route path="main/leontieff_scenary/price_demand_model_selector1" element={<PriceDemandModelSelector1/>}/>
                    <Route path="main/leontieff_scenary/price_demand_model_selector2" element={<PriceDemandModelSelector2/>}/>
                    <Route path="main/leontieff_scenary/price_demand_model_results" element={<PriceDemandModelResults/>}/>
                    <Route path="main/leontieff_scenary/price_demand_model_reports" element={<PriceDemandModelPDF/>}/>
                    <Route path="main/leontieff_scenary/change_product_price_selector1" element={<ChangeProductPriceSelector1/>}/>
                    <Route path="main/leontieff_scenary/change_product_price_selector2" element={<ChangeProductPriceSelector2/>}/>
                    <Route path="main/leontieff_scenary/change_product_price_results" element={<ChangeProductPriceResults/>}/>
                    <Route path="main/leontieff_scenary/change_product_price_reports" element={<ChangeProductPricePDF/>}/>
                    <Route path="main/simulation_operation_menu" element={<SimulationOperationMenu />}/>
                    <Route path="main/simulation_operation_list" element={<SimulationOperationList />}/>
                    <Route path="main/simulation_operation_information" element={<SimulationOperationInformation />}/>
                    <Route path="main/simulation_operation_selector1" element={<SimulationOperationSelector1 />}/>
                    <Route path="main/simulation_operation_selector2" element={<SimulationOperationSelector2 />}/>
                    <Route path="main/simulation_operation_results" element={<SimulationOperationResults />}/>
                    <Route path="main/simulation_operation_reports" element={<SimulationOperationsPDF />}/>
                  </Route>

                  {/*Reportes*/}
                  <Route path="/reports" element={<Reports/>} />
  
                  {/*Premium*/}
                  <Route path="/premium" element={<Premium/>} />

                  {/*Perfil*/}
                  <Route path="account">
                    <Route index element={<Navigate to="profile"/>}/>
                    <Route path="profile" element={<Account/>}/>
                    <Route path="modifypass" element={<ModifyPass/>} />
                    <Route path="deleteaccount" element={<DeleteAccount/>} />
                    <Route path="notifications" element={<AccountNotifications/>} />
                    <Route path="edit_photo" element={<EditPhoto/>} />
                    <Route path="evaluate_platform" element={<EvaluatePlatform/>} />
                  </Route>

                  <Route path="/services" element={<Services/>} />
  
                  {/*Admin Usuarios*/}
                  <Route path="adminuser">
                    <Route index element={<Navigate to="list"/>}/>
                    <Route path="list" element={<AdminUser/>}/>
                    <Route path="createuser" element={<CreateUser/>} />
                    <Route path="deleteuser" element={<DeleteUser/>} />
                    <Route path="modifyuser" element={<ModifyUser/>} />
                    <Route path="activateuser" element={<ActivateUser/>} />
                    <Route path="admin_reports" element={<AdminReports/>} />
                    <Route path="change_user_status" element={<ChangeUserStatus/>} />
                  </Route>

                  {/*Admin BD */}
                  <Route path="adminbd">
                    <Route index element={<Navigate to="main"/>}/>
                    <Route path="main" element={<AdminBD/>}/>
                    <Route path="calibration" element={<Calibration/>}/>
                    <Route path="scenarios" element={<Scenarios/>}/>
                    <Route path="availablemip" element={<AvailableMIP/>}/>
                    <Route path="availablepelp" element={<AvailablePELP/>}/>
                    <Route path="resetbd" element={<ResetBD />}/>
                    <Route path="mso" element={<MSO />}/>
                    <Route path="coin_list" element={<AvailableCoins />}/>
                  </Route>
                </Routes>
            </div>
          </div>
      </div>
    )
  }
  
  export default Main