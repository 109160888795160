//Imports
import {React, useState, useContext, useEffect} from 'react'
import { UserContext } from '../../../../contexts/UserContext';
import { Header} from '../../../../components';
import { API_PORT } from '../../../../API_PORT';
import { useNavigate, useLocation } from 'react-router-dom';
import capitalize from '../../../../hook/capitalize';
import Modal from '../../../../components/Modal';
import { styled } from '@mui/material/styles';
import Slider from '@mui/material/Slider';
import MuiInput from '@mui/material/Input';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

//Main function
const ChangeProductPriceSelector2 = () => {
  const userContext = useContext(UserContext);
  const token = userContext.value.access_token;
  const navigate = useNavigate();
  const location = useLocation()
  const Input = styled(MuiInput)`
  `;
  const values = location.state.values
  const coins = location.state.coins
  const [technologies, setTechnologies] = useState({
    "Product": "Sin asignar",
    "Percentage": 0
  });
  const [progress] = useState({started: false, pc: 0});
  const [msg, setMsg] = useState(null);
  const [loaderscreen, setLoaderScreen] = useState({started: false});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [changeproductprice, setchangeproductprice] = useState([])

  const handleInput = (event) =>{
    setTechnologies(prev => ({...prev, [event.target.name]:event.target.value}))
  };

  const hasNullValue = () => {
    return Object.values(technologies).some(value => value === "");
  };

  const removeLeadingZerosFromAll = () => {
    setTechnologies(prevTechnologies => {
      const updatedTechnologies = Object.keys(prevTechnologies).reduce((acc, key) => {
        const value = prevTechnologies[key].toString();
  
        // Mantener "0" como está
        if (value === "0") {
          acc[key] = "0";
        } 
        // Eliminar ceros iniciales, dejando solo un "0" si es necesario
        else if (value.startsWith('0') && value.includes('.')) {
          acc[key] = value.replace(/^0+/, '0'); // Eliminar todos los ceros pero dejar un "0" si hay decimal
        } 
        // Eliminar ceros solo si el número es mayor que 0 y tiene ceros iniciales
        else if (value.startsWith('0') && !value.startsWith('0.')) {
          acc[key] = value.replace(/^0+/, '');
        } 
        else {
          acc[key] = value; // Mantener el valor original si no se requiere cambio
        }
  
        return acc;
      }, {});
      
      return updatedTechnologies;
    });
  };

  const replaceCommasWithDots = () => {
    setTechnologies(prevTechnologies => {
      const updatedTechnologies = Object.keys(prevTechnologies).reduce((acc, key) => {
        acc[key] = prevTechnologies[key].toString().replace(/,/g, '.'); // Reemplazar todas las comas por puntos
        return acc;
      }, {});
  
      return updatedTechnologies;
    });
  };

  //Obtain results function
  const ChangeProductPriceResults = () => {
    if(technologies.Product === "Sin asignar"){
      alert("No has seleccionado todas las unidades de medida de la simulación.")
      return;
    }
    if(hasNullValue()){
      alert("Uno de los campos se encuentra vacío. Rellena todos los campos para poder realizar la simulación.")
      return;
    }
    removeLeadingZerosFromAll();
    replaceCommasWithDots();
    const change_product_price= API_PORT + "/scene/quantitative_and_price_models/change-product-price/" + values.country + "/" + values.year + "?token="+token
    const new_body = {
      "IntermediateProductPriceInputModel": technologies.Product,
      "percentage_increase": technologies.Percentage
    }

    setIsModalOpen(true)
    setMsg("Calculando la simulación, no cierre esta pestaña hasta completar el proceso.")
    setLoaderScreen(prevState => {return {...prevState, started: true}});

    fetch(change_product_price, {
    method: 'POST',
    headers: {'Content-type': 'application/json'},
    body: JSON.stringify(new_body)
    }).then(res => res.json()).then(data => {setchangeproductprice(data);})
  }

  //Auto update values
  useEffect(()=>{
    if(location.state?.technologies){
      setTechnologies({...technologies, Percentage: location.state.technologies.Percentage, Product: location.state.technologies.Product})  
    }
  },[location.state?.technologies])

  //Set all values to 0
  function make0(){
    setTechnologies({...technologies, Percentage: 0}) 
  };

  //View results when obtain all data
  const NavigateResults = () => {
    if(changeproductprice.MIP_base_year?.title){
      alert("Se han calculados los datos de la simulación. Pulsa para continuar.")
      navigate("/dashboard/simulations/main/leontieff_scenary/change_product_price_results", {state: {technologies,values,changeproductprice, coins}})
    }
  }

  function ValueConverter(value){
    if(value === "Precio electricidad"){
      return("Electricidad")
    }
    else if(value === "Precio del cobre"){
      return("Cobre")
    }
    else if(value === "Precio del petróleo"){
      return("Petróleo")
    }
    else if(value === "Precio H2"){
      return("H2")
    }
    else if(value === "Precio transporte"){
      return("Transporte")
    }
    else if(value === "Precio productos agrícolas"){
      return("Productos Agrícolas")
    }
    else{
      return(value)
    }
  }

  NavigateResults()

  //Main page
  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
        <div className='flex justify-between items-center mb-6'>
          <button className="w-72" style={{borderRadius: "10px", backgroundColor: "gray", color: "white", padding: "16px"}} onClick={() => {navigate("/dashboard/simulations/main/leontieff_scenary/change_product_price_selector1", {state: {values}})}}>Volver a la selección de país y año</button>
      </div>  
      <Header title={"Cambio precio de producto " + capitalize(values.country) + " " + values.year}/>
      <p className='text-gray-500'>En esta sección podrás generar tus propias simulaciones de escenarios Leontieff para cambios de precio de productos. Selecciona el producto y el porcentajes de cambio desde el -100% al 1000% usando los deslizadores o ingresándolos manualmente; luego pulsa el botón de la parte inferior para ver los resultados.</p>
      <div className='mt-10'><Header title="Producto con precio a modificar"/></div>
      <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5'>
        <div className='column-span-1'>
          <select type='string' placeholder='' name='Product' onChange={handleInput}  className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2'>
            <option value={location.state?.technologies?.Product ?? "Sin asignar"}>{ValueConverter(location.state?.technologies?.Product ?? "Sin asignar")}</option>
            <option value="Precio electricidad">Electricidad</option>
            <option value="Precio del cobre">Cobre</option>
            <option value="Precio del petróleo">Petróleo</option>
            <option value="Precio H2">H2</option>
            <option value="Precio transporte">Transporte</option>
            <option value="Precio productos agricolas">Productos Agrícolas</option>
          </select>
        </div>
      </div>
      <div className='mt-10'><Header title="Variación porcentual del producto"/></div>
      <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5'>
        <div className='column-span-1'>
          <h1 className='mb-9'>Variación: {technologies.Percentage}%</h1>
          <Input type='string' value={technologies.Percentage} name='Percentage' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2' inputProps={{
              step: 1,
              min: -100,
              max: 1000,
              type: 'number',
              'aria-labelledby': 'input-slider'
            }}/>
          <div className='form-control rounded-0 w-full h-10 px-3 py-2'><Slider name="Percentage" min={-100} max={1000} aria-label="input-slider" value={technologies.Percentage} step={1} valueLabelDisplay="auto" marks={[{value: -100, label: '-100%'},{value: 1000,label: '1000%'}]} onChange={handleInput}/></div>
        </div> 
      </div>
      <div className='mt-12 flex gap-5'>
        <button style={{color:'white', backgroundColor:"#425df9", borderRadius: "10px"}} className="text-lg p-3 w-60" onClick={ChangeProductPriceResults}>Ver Resultados</button>
        <TooltipComponent content="Al pulsar este botón, se borrarán todos los datos del escenario y pasarán a ser 0."><button style={{color:'white', backgroundColor:"#425df9", borderRadius: "10px"}} className="text-lg p-3 w-60" onClick={() => {make0()}}>Borrar datos</button></TooltipComponent>
      </div>
      <Modal msg={msg} progress={progress} loaderscreen={loaderscreen} isOpen={isModalOpen} closeModal={() => setIsModalOpen(false)}/>

    </div>
  )
}

export default ChangeProductPriceSelector2